import {CreateModalRef} from '@/containers/RequireSupport/CreateModal';
import {DetailModalRef} from '@/containers/RequireSupport/DetailModal';
import {RESPONSE_CODE} from '@/network/constants';
import {ticketsServices} from '@/network/services/tickets';
import {DEFAULT_FORMAT_DATE} from '@/utils/constants';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {ActionType} from '@ant-design/pro-components';
import {message} from 'antd';
import {UploadFile} from 'antd/lib';
import {debounce} from 'lodash';
import moment from 'moment';
import {useCallback, useEffect, useRef, useState} from 'react';

type State = {
  listTicket: ListRequiredInterface[];
  filters: {
    query?: string;
    status?: string;
    fromDate?: string;
    toDate?: string;
  };
  metadata: {
    page: number;
    size?: number;
    total?: number;
  };
  isEdit: boolean;
  images: {
    idStr: string;
    url: string;
  }[];
  reload: boolean;
  isInitialRender: boolean;
  loading: boolean;
};

export const useRequireSupport = (isAdmin: boolean, isReceived: boolean) => {
  const [state, setState] = useState<State>({
    isEdit: false,
    listTicket: [],
    filters: {},
    metadata: {
      size: 50,
      page: 0,
    },
    images: [],
    reload: false,
    isInitialRender: true,
    loading: false,
  });
  const actionRef = useRef<ActionType>();
  const createRef = useRef<CreateModalRef>(null);
  const detailsRef = useRef<DetailModalRef>(null);

  useEffect(() => {
    getListRequiredSupport();
  }, [state?.reload]);

  useEffect(() => {
    if (state?.isInitialRender) {
      setState(prevState => ({
        ...prevState,
        isInitialRender: false,
      }));
      return;
    }
    delayedSearch();
    return delayedSearch.cancel;
  }, [state?.filters, state?.metadata]);

  const delayedSearch = useCallback(
    debounce(() => onSearchData(), 300),
    [state?.filters, state?.metadata]
  );

  const onSearchData = debounce(() => {
    getListRequiredSupport();
  });

  const getListRequiredSupport = async () => {
    const params = {
      isReceived: isAdmin ? isReceived : undefined,
      query: state?.filters?.query,
      page: state?.metadata?.page,
      size: state?.metadata?.size,
      fromDate: state?.filters?.fromDate,
      toDate: state?.filters?.toDate,
      status: state?.filters?.status,
    };
    const res = await ticketsServices.getListTickets(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const {data} = res?.data;
      setState(prev => ({
        ...prev,
        listTicket: data,
      }));
    }
  };

  const onChangeStatus = (value: string) => {
    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        status: value,
      },
      metadata: {
        page: 0,
      },
    }));
  };

  const onChangeInput = (e: {
    target: {
      value: string;
    };
  }) => {
    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        query: e?.target?.value,
      },
      metadata: {
        page: 0,
      },
    }));
  };

  const onChangeDate = (_: any, value: string[]) => {
    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        fromDate: !isNullOrEmpty(value?.[0])
          ? moment(value?.[0]).startOf('day').format(DEFAULT_FORMAT_DATE)
          : undefined,
        toDate: !isNullOrEmpty(value?.[1])
          ? moment(value?.[1]).endOf('day').format(DEFAULT_FORMAT_DATE)
          : undefined,
      },
      metadata: {
        page: 0,
      },
    }));
  };

  const onCreateRequireSupport = () => {
    createRef?.current?.setOpenModal();
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState(prevState => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const openDetailModal = async (id?: number) => {
    setState(prev => ({
      ...prev,
      loading: true,
    }));
    await getTicketByID(id);
    setState(prev => ({
      ...prev,
      loading: false,
    }));
  };

  const getTicketByID = async (id?: number) => {
    const res = await ticketsServices.getTicketsByID(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      detailsRef?.current?.setOpenModal(res?.data);
    } else {
      message?.error(res?.data);
    }
  };

  const createRequest = async (values?: CreateTicketParams) => {
    const params = {
      ...values,
      fullName: values?.fullName?.trim(),
      email: values?.email?.trim(),
      phoneNumber: values?.phoneNumber?.trim(),
      title: values?.title?.trim(),
      content: values?.content?.trim(),
      images: state?.images.map(item => item.idStr),
    };
    const res = await ticketsServices.createTickets(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message?.success('Tạo yêu cầu hỗ trợ thành công !');
      createRef?.current?.setCloseModal();
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
    } else {
      message?.error(res?.data);
    }
  };

  const onFileListChange = (idStr: string, url: string) => {
    setState(prev => ({
      ...prev,
      images: [
        ...state?.images,
        {
          idStr: idStr,
          url: url,
        },
      ],
    }));
  };

  const onCloseModal = () => {
    setState(prev => ({
      ...prev,
      images: [],
    }));
  };

  const commentTicket = async (
    params?: {content?: string; images: string[]},
    id?: number
  ) => {
    const res = await ticketsServices.commentTickets(params, id);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      getTicketByID(id);
    } else {
      message.error(res?.data);
    }
  };

  const closeTicket = async (id: number) => {
    const res = await ticketsServices.closeTicket(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      detailsRef?.current?.closeModalDetail();
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
    } else {
      message.error(res?.data);
    }
  };

  const receiveTicket = async (id: number) => {
    const res = await ticketsServices.receiveTicket(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message?.success('Tiếp nhận yêu cầu hỗ trợ thành công !');
      detailsRef?.current?.closeModalDetail();
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
    } else {
      message.error(res?.data);
    }
  };

  const onRemoveFile = (file: UploadFile) => {
    const newArray = state?.images.filter(item => item.url !== file?.url);
    setState(prev => ({
      ...prev,
      images: newArray,
    }));
  };

  return {
    state,
    createRef,
    actionRef,
    detailsRef,
    onChangeStatus,
    onChangeInput,
    onChangeDate,
    onCreateRequireSupport,
    onPageChange,
    openDetailModal,
    createRequest,
    onFileListChange,
    onCloseModal,
    commentTicket,
    closeTicket,
    receiveTicket,
    onRemoveFile,
  };
};
