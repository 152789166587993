import axios, {type AxiosRequestConfig} from 'axios';
import {
  AccessTokenInterceptor,
  RequestResponseInterceptor,
} from './Interceptors';

const networkInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

networkInstance.interceptors.request.use(
  AccessTokenInterceptor.addAccessToken,
  AccessTokenInterceptor.onRejected
);
networkInstance.interceptors.response.use(
  RequestResponseInterceptor.request,
  RequestResponseInterceptor.requestErr
);

const post = async (
  urlApi: string,
  params: any = null,
  config?: AxiosRequestConfig
) => {
  return await networkInstance.post(urlApi, params, config);
};
const patch = async (
  urlApi: string,
  params: any = null,
  config?: AxiosRequestConfig
) => {
  return await networkInstance.patch(urlApi, params, config);
};

const put = async (
  urlApi: string,
  params: any = null,
  config?: AxiosRequestConfig
) => {
  return await networkInstance.put(urlApi, params, config);
};

const postFormData = async (urlApi: string, params: any, config?: any) => {
  return await networkInstance.post(urlApi, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
};
const deleteMethod = async (urlApi: string, data: any = null) => {
  return await networkInstance.delete(urlApi, {data});
};

const get = async (
  urlApi: string,
  data: any = null,
  config?: AxiosRequestConfig
) => {
  return await networkInstance.get(urlApi, {
    params: data,
    ...config,
  });
};

export const NetWork = {
  get,
  post,
  postFormData,
  patch,
  put,
  deleteMethod,
};
