import {EditOutlined, ReloadOutlined, EyeOutlined} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Popover, Space, Tag, Tooltip} from 'antd';
import moment from 'moment';
import {ModalDetailRef} from '../ModalDetailInfo';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {PERMISSIONS} from '@/utils/constants';

const getColumnsConfig: (
  reset: (record: number) => void,
  actionRef: any,
  CreateRef: any,
  modalDetailRef: React.RefObject<ModalDetailRef>
) => ProColumns<AccountType>[] = (
  reset,
  actionRef,
  createModalRef,
  modalDetailRef
) => {
  const convertStatus = (num: number): {name: string; color: string} => {
    switch (num) {
      case 0:
        return {name: 'Đang hoạt động', color: 'success'};
      case 1:
        return {name: 'Ngừng hoạt động', color: 'error'};
      case -1:
        return {name: 'Đã xoá', color: 'default'};
      default:
        return {name: 'Chờ xác thực', color: 'processing'};
    }
  };

  const columns: ProColumns<AccountType>[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      width: '5%',
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'userName',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullname',
      align: 'center',
      width: '20%',
    },
    // {
    //   title: 'Chức vụ',
    //   dataIndex: 'role',
    //   align: 'center',
    //   width: '10%',
    // },
    {
      title: 'CT khu vực',
      dataIndex: 'regionCompanyName',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'regionCompanyBranchName',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'accountType',
      align: 'center',
      width: '10%',
      render: (_, {accountType, role}) =>
        accountType === 'account_operator' ? (
          <Popover content={role}>
            <Tag color={'blue'}>{'Vận hành'}</Tag>
          </Popover>
        ) : (
          <Tag>{'Người dùng'}</Tag>
        ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      align: 'center',
      width: '12%',
      render: (_, record) =>
        record.createdDate
          ? moment(record.createdDate).format('DD/MM/YY HH:mm:ss')
          : '-',
    },
    {
      title: 'Trạng thái hoạt động',
      dataIndex: 'status',
      align: 'center',
      width: '12%',
      render: (_, record) => {
        const obj = convertStatus(record.status);
        return <Tag color={obj.color}>{obj.name}</Tag>;
      },
    },
    {
      title: 'Thao tác',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          <Space size={6} className="p-0">
            {isUserHaveOneOfPermissions(
              [ROLES.ADMIN],
              PERMISSIONS.updateUser
            ) && (
              <Tooltip title={'Sửa'}>
                <EditOutlined
                  onClick={() => {
                    createModalRef.current?.open({isEdit: true, record});
                  }}
                  className="text-[#1677ff] cursor-pointer text-[17px]"
                />
              </Tooltip>
            )}
            <Tooltip title={'Đặt lại mật khẩu'}>
              <ReloadOutlined
                onClick={() => {
                  reset(record.userId);
                }}
                className="text-[#1677ff] cursor-pointer ml-2 mr-2 text-[17px]"
              />
            </Tooltip>
            <Tooltip title={'Xem toàn bộ thông tin'}>
              <EyeOutlined
                onClick={() => {
                  modalDetailRef.current?.setOpenModal(true, record);
                }}
                className="text-[#1677ff] cursor-pointer text-[17px]"
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return columns;
};
export default getColumnsConfig;
