import { PROVIDED_TYPE } from '@/utils/enum';
import { formatPrice } from '@/utils/stringUtils';
import { DrawerForm } from '@ant-design/pro-form';
import { Col, Form, Row, Typography } from 'antd';
import React, { Ref, forwardRef, useImperativeHandle } from 'react';

export type DetailModalRef = {
  setOpenModal: (
    isOpen: boolean,
    data?: ProductItemInterface | undefined
  ) => void;
};

type Props = {
  listCourse: Course[];
  suppliers: SuppliersInterface[];
};

const ModalDetail = (
  {listCourse, suppliers}: Props,
  ref: Ref<DetailModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    ProductItemInterface | undefined
  >();

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    setOpenModal,
  }));

  const setOpenModal = (isOpen: boolean, data?: any) => {
    setOpen(isOpen);
    if (data) {
      setDataDetails(data);
    }
  };
  const INFO_PACK_DATA = [
    {
      id: 1,
      label: 'Nhà cung cấp:',
      content: suppliers.find(item => item.id === dataDetails?.supplierId)
        ?.name,
    },
    {
      id: 2,
      label: 'Tên gói:',
      content: dataDetails?.name,
    },
    {
      id: 3,
      label: 'Mã gói:',
      content: dataDetails?.code,
    },
    {
      id: 4,
      label: 'Doanh thu (VNĐ):',
      content: formatPrice(dataDetails?.price) + ' VNĐ',
    },
    {
      id: 5,
      label: 'Hoa hồng (VNĐ):',
      content: formatPrice(Number(dataDetails?.moneyReceive)) + ' VNĐ',
    },
    {
      id: 6,
      label: 'Chu kì:',
      content: dataDetails?.duration,
    },
    {
      id: 7,
      label: 'Khác:',
      content: dataDetails?.description,
    },
  ];

  const INFO_COURSE = [
    {
      id: 1,
      label: 'Nhà cung cấp:',
      content: suppliers.find(item => item.id === dataDetails?.supplierId)
        ?.name,
    },
    {
      id: 2,
      label: 'Packages:',
      content: listCourse?.find(
        item => item.id === dataDetails?.productOriginId
      )?.name,
    },
    {
      id: 3,
      label: 'Tên gói:',
      content: dataDetails?.name,
    },
    {
      id: 4,
      label: 'Giá bán (VNĐ):',
      content: formatPrice(dataDetails?.price) + ' VNĐ',
    },
    {
      id: 5,
      label: 'Hoa hồng (VNĐ):',
      content: formatPrice(Number(dataDetails?.moneyReceive)) + ' VNĐ',
    },
  ];

  const DATA =
    dataDetails?.type === PROVIDED_TYPE.PACK_DATA
      ? INFO_PACK_DATA
      : INFO_COURSE;

  return (
    <DrawerForm
      title={'Thông tin sản phẩm'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 450,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: false,
        onClose: onClose,
      }}
      submitter={false}>
      {DATA?.map((item, index) => (
        <Row key={index} gutter={24} className="mb-4">
          <Col span={8}>
            <Typography className="font-[600]">{item?.label}</Typography>
          </Col>
          <Col span={16}>
            <Typography>{item.content || 'Chưa có thông tin'}</Typography>
          </Col>
        </Row>
      ))}
    </DrawerForm>
  );
};

export const DetaillModal = forwardRef(ModalDetail);
