import {CreateBranchModalRef} from '@/containers/Organizations/ModalCreateBranch';
import {CreateRegionModalRef} from '@/containers/Organizations/ModalCreateRegion';
import {RESPONSE_CODE} from '@/network/constants';
import {organizationsServices} from '@/network/services/organizitions';
import {message} from 'antd';
import React, {useState} from 'react';

type StateProps = {
  lisOrganizations: OrganizitionsInterface[];
  topupCreateOrganization: boolean;
  loading: boolean;
  reload: boolean;
  isEdit: boolean;
  filter?: {
    status: number;
  };
};

export const useOrganization = () => {
  const [state, setState] = useState<StateProps>({
    lisOrganizations: [],
    topupCreateOrganization: false,
    loading: true,
    reload: false,
    isEdit: false,
  });
  const createBranchModal = React.useRef<CreateBranchModalRef>(null);
  const createRegionModal = React.useRef<CreateRegionModalRef>(null);

  React.useEffect(() => {
    getOrganizations();
  }, [state?.reload, state?.filter?.status]);

  const getOrganizations = async () => {
    let params = {
      // page: 0,
      // size: 100,
      status: state?.filter?.status,
    };
    const res = await organizationsServices.getStructure(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        lisOrganizations: res?.data,
      }));
    }
    setState(prev => ({
      ...prev,
      loading: false,
    }));
  };

  const handleOpenModalBranch = () => {
    createBranchModal?.current?.open();
    setState(prev => ({
      ...prev,
      topupCreateOrganization: false,
      isEdit: false,
    }));
  };
  const handleOpenModalRegion = () => {
    createRegionModal?.current?.open();
    setState(prev => ({
      ...prev,
      topupCreateOrganization: false,
      isEdit: false,
    }));
  };

  const openTopup = () => {
    setState(prev => ({
      ...prev,
      topupCreateOrganization: true,
    }));
  };

  const createRegionCompany = async (params: {name: string}) => {
    const res = await organizationsServices.createRegionCompany(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
      createRegionModal?.current?.close();
    } else {
      message?.error(res?.data);
    }
  };

  const createBranchCompany = async (params: {
    name: string;
    regionCompanyId: number;
  }) => {
    const res = await organizationsServices.createBranch(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
      createBranchModal?.current?.close();
    } else {
      message?.error(res?.data);
    }
  };

  const editBranch = async (
    params: {name: string; note: string},
    id: number
  ) => {
    const res = await organizationsServices.editRegion(params, id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
      createBranchModal?.current?.close();
    } else {
      message?.error(res?.data);
    }
  };

  const editCompany = async (
    params: {name: string; note: string},
    id: number
  ) => {
    const res = await organizationsServices.editRegionCompany(params, id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        reload: !state?.reload,
      }));
      createRegionModal?.current?.close();
    } else {
      message?.error(res?.data);
    }
  };

  const detailBranchCompany = async (id?: number) => {
    const res = await organizationsServices.getListRegionCompanyById(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      createBranchModal?.current?.open(res?.data);
      setState(prev => ({
        ...prev,
        isEdit: true,
      }));
    } else {
      message?.error(res?.data);
    }
  };

  const detailRegionCompany = async (id?: number) => {
    const res = await organizationsServices.getListCompanyById(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      createRegionModal?.current?.open(res?.data);
      setState(prev => ({
        ...prev,
        isEdit: true,
      }));
    } else {
      message?.error(res?.data);
    }
  };

  const handleFilterStatus = (value: string) => {
    console.log(value);
    setState(prev => ({
      ...prev,
      filter: {
        ...prev.filter,
        status: Number(value),
      },
    }));
  };

  return {
    state,
    handleOpenModalBranch,
    handleOpenModalRegion,
    openTopup,
    createBranchModal,
    createRegionModal,
    createRegionCompany,
    createBranchCompany,
    detailBranchCompany,
    detailRegionCompany,
    editBranch,
    editCompany,
    handleFilterStatus,
  };
};
