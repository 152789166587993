import {getBase64} from '@/components';
import {useWindowSize} from '@/hooks/useWindowSize';
import {Drawer} from 'antd';
import React, {Ref, useImperativeHandle, useState} from 'react';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import './styles.scss';

type Props = {};

export type CreateTicketModalRef = {
  open: (data: PostDetailProps) => void;
  close: () => void;
};

const PreviewPost = ({}: Props, ref: Ref<CreateTicketModalRef>) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<PostDetailProps>();
  const isMobile = useCheckMobileScreen();
  const windowSize = useWindowSize();
  const [image, setImage] = useState<string>('');

  console.log(data);

  const getUrl = async (value: PostDetailProps) => {
    const URL =
      typeof value?.thumbnail === 'string'
        ? value?.thumbnail
        : await getBase64(value?.thumbnail?.[0]?.originFileObj);
    setImage(URL ?? '');
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data: PostDetailProps) => {
          setData(data);
          setOpen(true);
          data?.thumbnail && getUrl(data);
        },
        close: onClose,
      };
    },
    []
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      onClose={onClose}
      open={open}
      width={isMobile ? windowSize.width : 900}
      closable={isMobile}
      height={'100%'}
      title={
        <div className="flex justify-center items-center">
          Xem trước bài viết
        </div>
      }>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <div className="contentContainer">
          {image && <img alt="example" src={image} className="postImg" />}
          <div className="postName">{data?.name}</div>

          <div
            className="customContent"
            dangerouslySetInnerHTML={{__html: data?.content ?? ''}}
          />
        </div>
      </div>
    </Drawer>
  );
};
export const PreviewPostModal = React.forwardRef(PreviewPost);
