import {useWindowSize} from '@/hooks/useWindowSize';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {ProTable} from '@ant-design/pro-components';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCMS} from '@/hooks/useCMS';
import {MOFilter} from './Filter';
import getColumnsConfig from './TableColumns';
import {CreatePost} from './CreatePost';
import {PAGE_SIZE_LIST} from '@/utils/constants';

export const Posts: React.FC = () => {
  const [modalPostStatus, setModalPostStatus] = useState<number>(0);
  const [focusPostId, setFocusPostId] = useState<number>(0);
  const {
    data,
    getListPosts,
    actionRef,
    onChangeCategory,
    onChangeDate,
    onChangeName,
    onPageChange,
  } = useCMS();

  useEffect(() => {
    getListPosts();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    isUserHaveOneOfPermissions([ROLES.AM_LEADER, ROLES.AM]) && navigate('/*');
  }, []);

  const onDetail = (id: number) => {
    setFocusPostId(id);
    setModalPostStatus(2);
  };

  const windowSize = useWindowSize();

  const columns = getColumnsConfig(actionRef, onDetail);

  const renderContent = useMemo(() => {
    return (
      <ProTable<any>
        loading={data?.loading}
        className={'custom-table'}
        scroll={{x: 1000, y: windowSize?.height - 350}}
        rowKey="id"
        actionRef={actionRef}
        dataSource={data?.post}
        columns={columns}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <MOFilter
            categories={data?.categories}
            onChangeCategory={onChangeCategory}
            onChangeDate={onChangeDate}
            onChangeName={onChangeName}
            onAddPost={setModalPostStatus?.bind(null, 1)}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: data?.filters.page + 1,
          total: data?.metadata?.total,
          defaultPageSize: data?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
    );
  }, [data?.post, columns]);

  return (
    <div className="flex flex-col">
      {modalPostStatus === 0 ? (
        renderContent
      ) : (
        <CreatePost
          goBack={setModalPostStatus?.bind(null, 0)}
          status={modalPostStatus}
          id={focusPostId}
          categories={data?.categories}
          hashtags={data?.hashtags}
          update={getListPosts}
        />
      )}
    </div>
  );
};
