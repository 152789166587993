import {Col, DatePicker, Input, Row, Select} from 'antd';
import {ProCard} from '@ant-design/pro-components';
import {STATUS_ORDER} from '@/utils/constants';

type Props = {
  onChange?: (value: string) => void;
  onChangeDate?: (values: any) => void;
  onChangeStatus?: (value: number) => void;
};

export const OrdersFilter = ({
  onChange,
  onChangeDate,
  onChangeStatus,
}: Props) => {
  const onChangeText = (e: {
    target: {
      value: string;
    };
  }) => {
    onChange?.(e.target.value);
  };

  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row className="w-full">
        <Col lg={10} span={24} className="mr-2">
          <Input.Search
            placeholder={'Nhập SĐT, mã đơn hàng, tên sản phẩm, người bán...'}
            allowClear
            onChange={onChangeText}
          />
        </Col>

        <Col lg={6} span={24} className="mr-2 max-md:mt-3">
          <Select
            allowClear
            className="w-[calc(100%)]"
            placeholder="Trạng thái"
            options={STATUS_ORDER.map((item: any) => ({
              value: item.id,
              label: item.value,
            }))}
            onChange={onChangeStatus}
          />
        </Col>
        <Col lg={6} span={24} className="max-md:mt-3">
          <DatePicker.RangePicker
            onChange={onChangeDate}
            format={'DD/MM/YYYY'}
            placeholder={['Ngày tạo', '']}
            style={{width: '100%'}}
          />
        </Col>
      </Row>
    </ProCard>
  );
};
