import {STATUS_GROUP_PERMISSION} from '@/utils/constants';
import {Button, Col, Form, Input, Row, Select, message} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {FC, useEffect, useRef, useState} from 'react';
import TableManagerPermissions from './TableManagerPermissions';
import {permissionServices} from '@/network/services/permissions';
import {RESPONSE_CODE} from '@/network/constants';
import {updateOrAddObjects} from '@/utils';
import {isNullOrEmpty} from '@/utils/stringUtils';

type Props = {
  id: number | undefined;
  onRefreshPage: () => void;
  onCancel: () => void;
};

interface FormInterface {
  name: string;
  status: 0 | 1;
  description: string;
}

const CreateGroupPermission: FC<Props> = ({id, onRefreshPage, onCancel}) => {
  const permissionSelected = useRef<ItemPermission[]>([]);
  const [data, setData] = useState<ModulePermission[]>([]);
  const [form] = Form.useForm();

  const onSelectPermission = (items: (ItemPermission | undefined)[]) => {
    permissionSelected.current = updateOrAddObjects(
      permissionSelected.current,
      items.filter(item => item !== undefined) as ItemPermission[]
    );
  };

  const onFinish = async (values: FormInterface) => {
    const permissions = permissionSelected.current
      .filter(item => item.active)
      .map(item => item.permission);
    const body = {...values, permissions: permissions};
    let response = null;

    if (id) {
      response = await permissionServices.edit(id, body);
    } else {
      response = await permissionServices.create(body);
    }
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      message.success(`${id ? 'Cập nhật' : 'Tạo'} nhóm quyền thành công`);
      onRefreshPage();
    } else {
      message.error(response?.data);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const fetchData = async () => {
    if (id) {
      // case edit
      const response = await permissionServices.detail(id);
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        const {permissions, status, name, description} = response?.data;
        setData(permissions);
        form.setFieldValue('status', status);
        form.setFieldValue('name', name);
        form.setFieldValue('description', description);
      }
    } else {
      const response = await permissionServices.getPermissionModules();
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        setData(response?.data?.data);
      }
    }
  };

  useEffect(() => {
    fetchData();
    if (isNullOrEmpty(id)) {
      form.setFieldValue('status', 0);
    }
  }, []);

  return (
    <Form
      name="basic"
      style={{height: '100%'}}
      form={form}
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <div>
        <div className="font-bold">
          {id ? 'Xem chi tiết' : 'Tạo'} nhóm quyền
        </div>
        <Row gutter={[24, 12]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label="Tên nhóm quyền"
              labelCol={{span: 24}}
              name="name"
              rules={[
                {required: true, message: 'Vui lòng nhập tên nhóm quyền!'},
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Trạng thái" name="status" labelCol={{span: 24}}>
              <Select
                style={{minWidth: 200}}
                placeholder="Chọn trạng thái"
                options={STATUS_GROUP_PERMISSION}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="description" label="Mô tả" labelCol={{span: 24}}>
          <TextArea
            placeholder="Nhập mô tả"
            autoSize={{minRows: 2, maxRows: 3}}
            maxLength={255}
          />
        </Form.Item>
      </div>

      <div>
        <p className="font-bold mb-2" style={{fontSize: 14}}>
          Chọn quyền
        </p>
        <TableManagerPermissions
          data={data}
          onSelectPermission={onSelectPermission}
        />
      </div>
      <div className="flex justify-center mt-2 space-x-1.5">
        <Button onClick={onCancel}>Hủy</Button>
        <Button type="primary" htmlType="submit">
          {id ? 'Sửa' : 'Lưu'}
        </Button>
      </div>
    </Form>
  );
};

export default CreateGroupPermission;
