import {isRoleAgent, isRoleCTV, isUserHaveOneOfPermissions} from '@/utils';
// import {CUSTOMER_CARE_STATUS} from '@/utils/constants';
import {LOCAL_STORAGE_KEY, ROLES} from '@/utils/enum';
import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {OrderedListOutlined} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Space, Tag} from 'antd';
import moment from 'moment';
import './styles.scss';

const getColumnsConfig: (
  openDetailModal: (id: number) => void,
  actionRef: any,
  isAgentSold: boolean
) => ProColumns<OrderItemInterface>[] = (
  openDetailModal,
  actionRef,
  isAgentSold
) => {
  const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);

  const getStatusColor = (
    num: number
  ): {name: string; color: string} | undefined => {
    switch (num) {
      case 0:
        return {
          name: 'Đã tạo đơn hàng',
          color: 'gold',
        };
      case 1:
        return {
          name: 'Đang vận chuyển',
          color: 'blue',
        };
      case 2:
        return {
          name: 'Hoàn thành',
          color: 'green',
        };
      case 3:
        return {
          name: 'Thất bại',
          color: 'red',
        };
      case 4:
        return {
          name: 'Chờ liên hệ',
          color: 'yellow',
        };
      default:
        break;
    }
  };

  // const hotLeadStatus = (
  //   num: CUSTOMER_CARE_STATUS
  // ): {name: string; color: string} | undefined => {
  //   switch (num) {
  //     case CUSTOMER_CARE_STATUS.NEW:
  //       return {name: 'Chưa tư vấn', color: 'gold'};
  //     case CUSTOMER_CARE_STATUS.CONTACTING:
  //       return {name: 'Đang tư vấn', color: 'blue'};
  //     case CUSTOMER_CARE_STATUS.CONFIRMED:
  //       return {name: 'KH đã đồng ý', color: 'green'};
  //     case CUSTOMER_CARE_STATUS.CANCELLED:
  //       return {name: 'KH không đồng ý', color: 'red'};
  //     default:
  //       break;
  //   }
  // };

  const columns: ProColumns<OrderItemInterface>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 70,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 190,
    },
    {
      title: 'Nguồn',
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.campaignName)
          ? record?.campaignName
          : '-------';
      },
    },
    {
      title: 'Người bán',
      dataIndex: ['sellerRole', 'sellerName'],
      key: 'seller',
      align: 'center',
      render: (_, record) => {
        return record?.agentCode;
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerInfo',
      key: 'customerInfo',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.customerInfo?.customerName)
          ? record?.customerInfo?.customerName
          : null;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customerInfo',
      key: 'customerInfo',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.customerInfo?.phone)
          ? record?.customerInfo?.phone
          : null;
      },
    },
    {
      title: (
        <div>
          <p>Giá niêm yết</p>
          <p>(Đã bao gồm VAT)</p>
        </div>
      ),
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.total)
          ? `${formatPrice(record?.total)} VNĐ`
          : `0 VNĐ`;
      },
    },
    role === ROLES.COLLABORATOR
      ? {
          title: 'Hoa hồng',
          dataIndex: 'collaboratorRevenue',
          key: 'collaboratorRevenue',
          align: 'center',
          render: (_, record) => {
            return !isNullOrEmpty(record?.collaboratorRevenue)
              ? `${formatPrice(Number(record?.collaboratorRevenue))} VNĐ`
              : `0 VNĐ`;
          },
        }
      : {
          title: 'Hoa hồng',
          dataIndex: 'agentRevenue',
          key: 'agentRevenue',
          align: 'center',
          render: (_, record) => {
            return !isNullOrEmpty(record?.agentRevenue)
              ? `${formatPrice(Number(record?.agentRevenue))} VNĐ`
              : `0 VNĐ`;
          },
        },
    {
      title: 'Hoa hồng nhận được',
      dataIndex: 'commissionFromSupplier',
      key: 'commissionFromSupplier',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.commissionFromSupplier)
          ? `${formatPrice(Number(record?.commissionFromSupplier))} VNĐ`
          : `0 VNĐ`;
      },
    },
    {
      title: 'Hoa hồng chia sẻ',
      dataIndex: 'commissionForPartner',
      key: 'commissionForPartner',
      align: 'center',
      render: (_, record) => {
        return !isNullOrEmpty(record?.commissionForPartner)
          ? `${formatPrice(Number(record?.commissionForPartner))} VNĐ`
          : `0 VNĐ`;
      },
    },
    // {
    //   title: 'Hoa hồng thực nhận',
    //   dataIndex: 'agentActualRevenue',
    //   key: 'agentActualRevenue',
    //   align: 'center',
    //   render: (_, record) => {
    //     return !isNullOrEmpty(record?.agentActualRevenue)
    //       ? `${formatPrice(Number(record?.agentActualRevenue))} VNĐ`
    //       : `0 VNĐ`;
    //   },
    // },
    {
      title: 'Ngày tạo đơn',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 150,
      render: (_, record) => {
        return moment(record.createdAt).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 170,
      render: (_, record) => {
        const obj = getStatusColor(record.status);
        return <Tag color={obj?.color}>{obj?.name}</Tag>;
      },
    },
    // {
    //   title: 'Trạng thái khách hàng',
    //   dataIndex: 'customerCareStatus',
    //   key: 'customerCareStatus',
    //   align: 'center',
    //   render: (_, record) => {
    //     const obj = hotLeadStatus(record.customerCareStatus);
    //     return <Tag color={obj?.color}>{obj?.name}</Tag>;
    //   },
    // },
    {
      title: 'Thao tác',
      dataIndex: 'option',
      align: 'center',
      render: (_, record) => (
        <Space size={10} onClick={openDetailModal.bind(null, record.id)}>
          <OrderedListOutlined className="text-[#1677ff] cursor-pointer text-[17px]" />
        </Space>
      ),
    },
  ];

  const commonFilterKeys: any = ['collaboratorRevenue', 'agentRevenue'];

  if (!isUserHaveOneOfPermissions([ROLES.ADMIN])) {
    return columns.filter(
      column =>
        !['commissionFromSupplier', 'commissionForPartner' as any].includes(
          column.key
        )
    );
  }

  if (!isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])) {
    return columns.filter(column => !commonFilterKeys.includes(column.key));
  }
  if (isRoleCTV()) {
    return columns.filter(column => column.key !== 'seller');
  }
  if (isRoleAgent() && isAgentSold) {
    return columns.filter(column => !['seller'].includes(column.key as any));
  }
  return columns;
};

export default getColumnsConfig;
