import { useReportRevenue } from '@/hooks/report/useReport';
import { isRoleOperatorPermission } from '@/utils';
import { formatPrice } from '@/utils/stringUtils';
import { PageContainer } from '@ant-design/pro-layout';
import { Row } from 'antd';
import React from 'react';
import { ReportRevenueFilter } from './ReportRevenueFilter';
import TableTotalOrder from './TableTotalOrder';
import TableTotalRevenue from './TableTotalRevenue';

export const Dashboard: React.FC = () => {
  const {
    params,
    totals,
    tableRevenue,
    metaDataRevenue,
    pageRevenue,
    tableOrder,
    metaDataOrder,
    pageOrder,
    txtTotalOrder,
    txtTotalOrderSuccess,
    txtTotalOrderError,
    onSizeChangeRevenue,
    onChangeRangeDate,
    onSelectWeek,
    onSelectMonth,
    onSizeChangeOrder,
  } = useReportRevenue();

  if (!isRoleOperatorPermission()) {
    return <PageContainer />;
  }

  return (
    <div className="flex flex-col">
      <ReportRevenueFilter
        rangeDate={params}
        onChangeRangeDate={onChangeRangeDate}
        onSelectWeek={onSelectWeek}
        onSelectMonth={onSelectMonth}
      />
      <div className="w-full mt-4 grid gap-4 md:grid-cols-4">
        <div className="justify-center bg-[#ffffff] p-3 md:col-span-1">
          <p className="font-medium">Doanh thu</p>
          <div className="bg-[#2196F3] p-3 mt-5 mb-5">
            <p className="text-white text-base">
              {formatPrice(totals?.totalRevenue)} VNĐ
            </p>
            <p className="text-white text-sm">Tổng doanh thu</p>
          </div>
          <TableTotalRevenue
            table={tableRevenue}
            metadata={metaDataRevenue}
            page={pageRevenue}
            onSizeChange={onSizeChangeRevenue}
          />
        </div>

        <div className="justify-center bg-[#ffffff] p-3 md:col-span-3">
          <p className="font-medium">Đơn hàng</p>
          <Row className="mt-5 mb-5 w-full justify-between">
            <div className="bg-[#FEC720] p-3 w-1/3">
              <p className="text-white text-base">
                {formatPrice(totals?.totalOrder)}
              </p>
              <p className="text-white text-sm">{txtTotalOrder}</p>
            </div>

            <div className="bg-[#D066E2] p-3 w-1/3">
              <p className="text-white text-base">
                {formatPrice(totals?.totalOrderSuccess)}
              </p>
              <p className="text-white text-sm">{txtTotalOrderSuccess}</p>
            </div>

            <div className="bg-[#FF6B68] p-3 w-1/3">
              <p className="text-white text-base">
                {formatPrice(totals?.totalOrderNotSuccess)}
              </p>
              <p className="text-white text-sm">{txtTotalOrderError}</p>
            </div>
          </Row>
          <TableTotalOrder
            table={tableOrder}
            metadata={metaDataOrder}
            page={pageOrder}
            onSizeChange={onSizeChangeOrder}
          />
        </div>
      </div>
    </div>
  );
};
