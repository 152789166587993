import {isNullOrEmpty} from '@/utils/stringUtils';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Form, Select} from 'antd';
import Link from 'antd/es/typography/Link';

type Props = {
  state: {
    linkProduct?: {
      id?: number;
      affiliateLink?: string;
    };
    listProduct: ProductItemInterface[];
    isShow: boolean;
    loading: boolean;
  };
  handleCreateRequest: () => void;
  onChangeSelectPackage: (value: number, options: any) => void;
  form: any;
};
export const Register = ({
  state,
  handleCreateRequest,
  onChangeSelectPackage,
  form,
}: Props) => {
  const linkProduct = state?.linkProduct;

  return (
    <div className="w-[300px] flex flex-col items-center">
      <Form form={form} onFinish={handleCreateRequest} className="flex w-full">
        <Form.Item className="mb-1 w-full items-center" name="packageCode">
          <Select
            placeholder="Chọn sản phẩm"
            allowClear
            showSearch
            options={state?.listProduct?.map(item => ({
              value: item?.id,
              label: item?.name,
              affiliateLink: item?.affiliateLink,
            }))}
            filterOption={(input, option) =>
              (option as {value: number; label: string}).label
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChangeSelectPackage}
          />
        </Form.Item>
      </Form>
      {state?.isShow && (
        <>
          {!isNullOrEmpty(linkProduct?.affiliateLink) ? (
            <Link
              href={linkProduct?.affiliateLink}
              target="_blank"
              className="text-center">
              {linkProduct?.affiliateLink}
            </Link>
          ) : (
            <Button
              type="primary"
              onClick={form.submit}
              className="mt-2 cursor-pointer"
              loading={state?.loading}
              icon={<PlusOutlined />}>
              Tạo link
            </Button>
          )}
        </>
      )}
    </div>
  );
};
