import {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {DrawerForm} from '@ant-design/pro-components';
import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  TimePicker,
} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import {TARGET_TYPES} from '@/utils/enum';
import {listAgencyInfo} from '@/network/services/accounts';
import {notificationServices} from '@/network/services/notifications';
import {RESPONSE_CODE} from '@/network/constants';

export type CreateModalRef = {
  open: (data?: any | undefined) => void;
};

export type Props = {
  isEdit?: boolean;
};

type CreateForm = {
  targetType: TargetType;
  title: string;
  content: string;
  agentIds: number[];
};

const ModalCreate = ({isEdit}: Props, ref: Ref<CreateModalRef>) => {
  const [timePicker, setTimePicker] = useState<string | undefined>(undefined);
  const [datePicker, setDatePicker] = useState<string | undefined>(undefined);
  const [agents, setAgents] = useState<AgentItem[]>([]);

  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          console.log('data', data);
          setOpen(true);
        },
      };
    },
    [isEdit]
  );

  const onclose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = async (values: CreateForm) => {
    const body: BodyCreateNotification = {
      sendTime: datePicker + ' ' + timePicker + ':00',
      targetType: values?.targetType,
      title: values?.title,
      content: values?.content,
      targetIdentifier: {
        agentIds: values?.agentIds,
      },
    };
    const response = await notificationServices.createNotification(body);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      message.success('Tạo thông báo thành công');
      onclose();
    } else {
      message.error('Tạo thông báo thất bại');
    }
  };

  const handleDateChange = (_date: any, dateString: string) => {
    setDatePicker(dateString);
  };

  const handleTimeChange = (_time: any, timeString: string) => {
    setTimePicker(timeString);
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current <= dayjs().startOf('day');
  };

  const fetchAgents = async () => {
    const response = await listAgencyInfo();
    setAgents(response);
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <DrawerForm
      title={isEdit ? 'Chỉnh sửa thông báo' : 'Thêm mới thông báo'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      onFinish={onSubmit}>
      <>
        <Form.Item
          label="Chọn đối tượng gửi"
          name="targetType"
          rules={[{required: true, message: 'Vui lòng nhập tiêu đề!'}]}
          className="mb-4">
          <Select
            options={TARGET_TYPES}
            placeholder="Vui lòng chọn đối tượng gửi"
          />
        </Form.Item>

        <Form.Item
          label="Chọn các đại lý"
          name="agentIds"
          rules={[{required: true, message: 'Vui lòng chọn đại lý!'}]}
          className="mb-4">
          <Select
            options={agents?.map((item: any) => ({
              value: item?.id,
              label: item?.code,
            }))}
            mode="multiple"
            placeholder="Vui lòng chọn đại lý"
          />
        </Form.Item>
        <Row gutter={[24, 12]}>
          <Col xs={24} sm={12} md={16}>
            <Form.Item
              label="Chọn ngày gửi"
              name="sendingDate"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày',
                },
              ]}>
              <DatePicker
                className="w-full"
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Chọn thời gian"
              name="sendingTime"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn thời gian gửi',
                },
              ]}>
              <TimePicker
                format={'HH:mm'}
                onChange={handleTimeChange}
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Tiêu đề:"
          name="title"
          rules={[{required: true, message: 'Vui lòng nhập tiêu đề!'}]}
          className="mb-4">
          <Input placeholder="Nhập tiêu đề..." className="w-[calc(100%)]" />
        </Form.Item>
        <Form.Item
          label="Nội dung:"
          name="content"
          rules={[{required: true, message: 'Vui lòng nhập nội dung!'}]}
          className="mb-4">
          <Input placeholder="Nhập nội dung..." className="w-[calc(100%)]" />
        </Form.Item>
      </>
    </DrawerForm>
  );
};

export const CreateModal = forwardRef(ModalCreate);
