import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {getRequestUrl} from '../utils';
import {API_URL} from '../apiUrl';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

const getPermissionConfigurations = (
  params: FilterPermissionConfiguration
): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL.permissions, {
    parentId: API_URL.role,
    partial: API_URL.list,
  });
  return NetWork.get(url, params);
};

const getPermissionModules = () => {
  const url = getRequestUrl('permissions/list/role/module');
  return NetWork.get(url);
};

const getGroupsPermission = () => {
  const url = getRequestUrl('permissions/role/list?page=0&size=100');
  return NetWork.get(url);
};
const create = (body: CreateRoleInterface) => {
  const url = getRequestUrl(API_URL.permissions, {
    parentId: API_URL.role,
    partial: API_URL.create,
  });

  return NetWork.post(url, body);
};

const edit = (id: number, body: CreateRoleInterface) => {
  const url = getRequestUrl(API_URL.permissions, {
    parentId: API_URL.role,
    partial: API_URL.update,
    action: id,
  });

  return NetWork.put(url, body);
};

const detail = (id: number) => {
  const url = getRequestUrl('permissions/role/detail', {
    parentId: id,
  });
  return NetWork.get(url);
};

export const permissionServices = {
  getPermissionConfigurations,
  getPermissionModules,
  getGroupsPermission,
  create,
  detail,
  edit,
};
