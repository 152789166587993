import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';

import {Popover} from 'antd';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {MenuItemProps} from './_defaultProps';
import './styles.scss';

type MenuProps = {
  menuProps: MenuItemProps[];
  collapsed: boolean;
  clickMenu?: () => void;
};

export const Menu = ({menuProps, collapsed, clickMenu}: MenuProps) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [focusTab, setFocusTab] = useState<string>(location.pathname);
  const [focusTabChildren, setFocusTabChildren] = useState<string>(
    location.pathname
  );
  const [tabHasChildren, setTabHasChildren] = useState<string[]>([]);

  const onClickMenuItem = (
    children: MenuItemProps['children'],
    path: string
  ) => {
    if (children) {
      if (tabHasChildren?.includes(path ?? '')) {
        const result = tabHasChildren?.filter(itemTab => {
          return itemTab !== path;
        });
        setTabHasChildren(result);
      } else setTabHasChildren(oldArray => [...oldArray, path ?? '']);
    } else {
      clickMenu?.();
      setFocusTab(path ?? '');
      navigate(`${path}`);
      setFocusTabChildren('');
    }
  };

  const renderChildren = (item: MenuItemProps) => {
    return (
      <div
        className="childrenContainer"
        style={{paddingLeft: collapsed ? 0 : 40}}>
        {item?.children?.map(
          (itemChildren: {path: string; name: string}, index: number) => {
            return (
              <button
                key={index + ''}
                onClick={() => {
                  navigate(`${itemChildren.path}`);
                  clickMenu?.();
                  setFocusTabChildren(itemChildren?.path ?? '');
                  setFocusTab('');
                }}
                className="btnChildren"
                style={{
                  background:
                    focusTabChildren === itemChildren?.path
                      ? '#0000000A'
                      : 'none',
                }}>
                <p
                  className="txtChildren"
                  style={{
                    color:
                      focusTabChildren === itemChildren?.path
                        ? 'black'
                        : '#000000A6',
                  }}>
                  {itemChildren?.name}
                </p>
              </button>
            );
          }
        )}
      </div>
    );
  };

  const checkoutFocusChildren = (children: MenuItemProps['children']) => {
    const existChildren: any = children?.filter(
      item => item?.path === focusTabChildren
    );
    if (existChildren?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="menuContainer">
      {menuProps?.map((item: MenuItemProps, index: number) => {
        return (
          <div key={index + ''}>
            <button
              onClick={onClickMenuItem?.bind(
                null,
                item?.children,
                item?.path ?? ''
              )}
              className="btnTab"
              style={{
                background:
                  focusTab === item?.path ||
                  checkoutFocusChildren(item?.children)
                    ? '#0000000A'
                    : 'none',
              }}>
              {collapsed && item?.children ? (
                <Popover
                  placement="left"
                  content={renderChildren(item)}
                  trigger="click">
                  <div className="tabIcon">{item?.icon}</div>
                </Popover>
              ) : (
                <div className="tabIcon">{item?.icon}</div>
              )}
              {!collapsed && (
                <div className="tabContentContainer">
                  <div className="tabName">
                    <p
                      className="txtNameTab"
                      style={{
                        color:
                          focusTab === item?.path ? '#000000F2' : '#000000A6',
                      }}>
                      {item?.name}
                    </p>
                  </div>
                  {item?.children && (
                    <div>
                      {tabHasChildren?.includes(item?.path ?? '') ? (
                        <CaretUpOutlined style={{marginLeft: 15}} />
                      ) : (
                        <CaretDownOutlined style={{marginLeft: 15}} />
                      )}
                    </div>
                  )}
                </div>
              )}
            </button>
            {tabHasChildren?.includes(item?.path ?? '') &&
              !collapsed &&
              renderChildren(item)}
          </div>
        );
      })}
    </div>
  );
};
