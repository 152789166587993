import { isNullOrEmpty } from '@/utils/stringUtils';
import { Col, Drawer, Image, Row, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import { forwardRef, useImperativeHandle, useState } from 'react';

export interface ModalDetailDocumentRef {
  openModal: (isOpen: boolean, data?: DocumentItemInterface) => void;
}

interface ModalDetailDocumentProps {}

// eslint-disable-next-line react/display-name
export const ModalDetailDocument = forwardRef<
  ModalDetailDocumentRef,
  ModalDetailDocumentProps
>((_, ref) => {
  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState<DocumentItemInterface | undefined>(
    undefined
  );

  const openModal = (isOpen: boolean, data?: DocumentItemInterface) => {
    setOpen(isOpen);
    if (data) {
      setDetail(data);
    }
  };

  const useInfor: any[] = [
    {
      id: 1,
      label: 'Mã sản phẩm:',
      content: detail?.code,
      hidden: true,
      isImage: false,
      isLink: false,
    },
    {
      id: 2,
      label: 'Nội dung:',
      content: detail?.content,
      hidden: true,
      isImage: false,
      isLink: false,
    },
    {
      id: 3,
      label: 'Mô tả:',
      content: detail?.description,
      hidden: true,
      isImage: false,
      isLink: false,
    },
    {
      id: 4,
      label: 'Link tài liệu:',
      content: detail?.referenceLink,
      hidden: true,
      isImage: false,
      isLink: true,
    },
    {
      id: 5,
      label: 'Ảnh sản phẩm:',
      content: detail?.image,
      hidden: true,
      isImage: true,
      isLink: false,
    },
    {
      id: 6,
      label: 'Video hướng dẫn:',
      content: detail?.video,
      hidden: true,
      isImage: false,
      isLink: true,
    },
  ];

  return (
    <Drawer
      open={open}
      width={500}
      title={detail?.name}
      onClose={openModal.bind(null, false, undefined)}
      closable={false}>
      {useInfor?.map((item, index) => (
        <>
          {item?.isImage ? (
            <div key={index}>
              <Typography className="font-bold mb-4">{item?.label}</Typography>
              {!isNullOrEmpty(item?.content) ? (
                <Image
                  width={300}
                  height={'auto'}
                  className="mb-4"
                  src={item?.content}
                />
              ) : (
                <div className="mb-4">Không có ảnh</div>
              )}
            </div>
          ) : (
            <>
              {item?.hidden && (
                <Row key={index} gutter={24} className="mb-4">
                  <Col span={8}>
                    <Typography className="font-[600]">
                      {item?.label}
                    </Typography>
                  </Col>
                  <Col span={16}>
                    {item?.isLink ? (
                      <Link href={item?.content} target="_blank">
                        {item?.content}
                      </Link>
                    ) : (
                      <Typography>
                        {item.content || 'Chưa có thông tin'}
                      </Typography>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ))}
    </Drawer>
  );
});
