import {RESPONSE_CODE} from '@/network/constants';
import {searchServices} from '@/network/services/search';
import {message} from 'antd';
import React from 'react';

const phoneNumberRegex = /^(03|05|07|08|09)+([0-9]{8})\b/;

type State = {
  searchKeyword: string;
  loading: boolean;
  activePackages: ActivePackage[];
  suggestedPackages?: {[key: string]: string[]};
  suppliers: SuppliersInterface[];
  supplierSelected: string | undefined;
};

export const useSearch = () => {
  const [state, setState] = React.useState<State>({
    searchKeyword: '',
    loading: false,
    activePackages: [],
    suppliers: [],
    supplierSelected: undefined,
  });

  const onChangeInput = (value: string) => {
    setState(prev => ({
      ...prev,
      searchKeyword: value,
    }));
  };

  const onSelectSupplier = (value: string) => {
    setState(prev => ({
      ...prev,
      supplierSelected: value,
    }));
  };

  const handleOnSearch = async () => {
    if (state?.searchKeyword && !phoneNumberRegex.test(state?.searchKeyword)) {
      return message.error('Sai định dạng số điện thoại!');
    } else {
      const params = {
        msisdn: state?.searchKeyword?.replace(/^0+/, ''),
        supplier: state.supplierSelected,
      };
      setState(prev => ({
        ...prev,
        loading: true,
      }));
      const res = await searchServices.getSearch(params);
      const {activePackages, suggestedPackages} = res.data;
      if (res.status === RESPONSE_CODE.SUCCESS) {
        setState(prev => ({
          ...prev,
          activePackages: activePackages?.data,
          suggestedPackages: suggestedPackages?.data,
          loading: false,
        }));
        message.success('Tra cứu thông tin thành công !');
      } else {
        setState(prev => ({
          ...prev,
          loading: false,
        }));
      }
    }
  };

  const fetchSuppliers = async () => {
    const response = await searchServices.suppliers();
    if (response.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        suppliers: response?.data?.data,
      }));
    }
  };

  React.useEffect(() => {
    if (state?.searchKeyword === '') {
      setState(prev => ({
        ...prev,
        activePackages: [],
        suggestedPackages: undefined,
      }));
    }
  }, [state?.searchKeyword]);

  React.useEffect(() => {
    fetchSuppliers();
  }, []);

  return {handleOnSearch, onChangeInput, onSelectSupplier, state};
};
