import { Tabs } from "antd";
import { ReportCampaignTab } from "./ReportCampaignTab";

export const ReportCampaign: React.FC = () => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Doanh thu từ Đại lý" key="item-1">
        <ReportCampaignTab isCollaborator={false} key={'tab-1'} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Doanh thu từ Cộng tác viên" key="item-2">
        <ReportCampaignTab isCollaborator={true} key={'tab-2'} />
      </Tabs.TabPane>
    </Tabs>
  );
};
