import {isUserHaveOneOfPermissions} from '@/utils';
import {PERMISSIONS} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, Input, Row} from 'antd';
import {FC} from 'react';

type Props = {
  onChangeText: (value: string) => void;
  onSearch: () => void;
  onCreateDocument: () => void;
};

export const DocumentFilter: FC<Props> = ({
  onChangeText,
  onSearch,
  onCreateDocument,
}) => {
  const onChangeInput = (event: any) => {
    onChangeText(event.target.value);
  };

  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]} className="flex">
        <Col lg={8} xs={24}>
          <Input.Search
            placeholder={'Tìm kiếm tài liệu...'}
            allowClear
            onChange={onChangeInput}
            onSearch={onSearch}
          />
        </Col>
        {isUserHaveOneOfPermissions(
          [ROLES.ADMIN],
          PERMISSIONS.createDocument
        ) && (
          <Col lg={16} className="flex justify-end">
            <Button
              key="button"
              icon={<PlusOutlined />}
              onClick={onCreateDocument}
              type="primary">
              Thêm tài liệu
            </Button>
          </Col>
        )}
      </Row>
    </ProCard>
  );
};
