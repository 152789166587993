import {isUserHaveOneOfPermissions} from '@/utils';
import {PERMISSIONS} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Popover, Row} from 'antd';

type Props = {
  topupCreateOrganization?: boolean;
  handleOpenModalRegion?: () => void;
  handleOpenModalBranch?: () => void;
  openTopup: (isOpen: boolean) => void;
  handleFilterStatus: (value: string) => void;
};

export const Filters = ({
  topupCreateOrganization,
  handleOpenModalRegion,
  handleOpenModalBranch,
  openTopup, // handleFilterStatus,
}: Props) => {
  if (!isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.createRC))
    return null;
  return (
    <ProCard className="mb-5" bordered>
      <Row gutter={[24, 12]}>
        <div className="flex flex-1 justify-end">
          <Popover
            open={topupCreateOrganization}
            onOpenChange={openTopup}
            placement="bottomRight"
            arrow={false}
            content={
              <div className="w-[200px]">
                <Button
                  icon={<PlusOutlined />}
                  className="w-full mb-3"
                  onClick={handleOpenModalRegion}>
                  Thêm mới Khu vực
                </Button>
                <Button
                  icon={<PlusOutlined />}
                  className="w-full"
                  onClick={handleOpenModalBranch}>
                  Thêm mới Chi nhánh
                </Button>
              </div>
            }
            trigger="click">
            <Button key="button" type="primary" icon={<PlusOutlined />}>
              Thêm mới tổ chức
            </Button>
          </Popover>
        </div>
      </Row>
    </ProCard>
  );
};
