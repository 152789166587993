import {Button, Col, DatePicker, Input, Row} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import {FC} from 'react';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {PERMISSIONS} from '@/utils/constants';

type Props = {
  handleSearch: (e: any) => void;
  handleFilterDate: (_: any, dateString: string[]) => void;
  createModalRef: any;
};

const AccountFilters: FC<Props> = ({
  handleSearch,
  handleFilterDate,
  createModalRef,
}) => {
  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={8}>
          <Input.Search
            placeholder="Nhập tên đăng nhập/sđt"
            allowClear
            onChange={handleSearch}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <DatePicker.RangePicker
            className="w-full"
            onChange={handleFilterDate}
            format={'YYYY-MM-DD'}
            placeholder={['Ngày tạo', '']}
          />
        </Col>
        {isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.createUser) && (
          <Col xs={24} sm={12} md={8} className="flex justify-end">
            <Button
              key="button"
              icon={<PlusOutlined />}
              onClick={() => {
                createModalRef.current?.open();
              }}
              type="primary">
              Thêm Tài khoản
            </Button>
          </Col>
        )}
      </Row>
    </ProCard>
  );
};

export default AccountFilters;
