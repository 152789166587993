import {formatPriceString} from '@/utils/stringUtils';
import {ProColumns} from '@ant-design/pro-components';
import moment from 'moment';

const getColumnsConfig = (actionRef?: any) => {
  // const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  const returnAmountChange = (amountChange: number) => {
    return formatPriceString(amountChange + '') + ' đ';
  };

  const getStatusColor = (num: number) => {
    if (num?.toString()?.includes('-')) {
      return {
        name: 'Đã huỷ',
        color: '#ff4d4f',
        background: '#fff2f0',
        borderColor: '#ffccc7',
      };
    } else
      return {
        name: 'Đã nhận tiền',
        color: '#389e0d',
        background: '#f6ffed',
        borderColor: '#b7eb8f',
      };
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 100,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (_, record) => {
        const obj = getStatusColor(record?.amountChange);
        return (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              style={{
                background: obj?.background,
                outlineColor: obj?.borderColor,
                alignContent: 'center',
              }}
              className={'statusContainer'}>
              <p
                className="txtStatus"
                style={{
                  color: obj?.color,
                }}>
                {record?.type}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 350,
    },
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Thời gian xử lý',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Số tiền biến động',
      dataIndex: 'amountChange',
      key: 'amountChange',
      align: 'center',
      render: (_, record) => {
        const obj = getStatusColor(record?.amountChange);
        return (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              style={{
                background: obj?.background,
                outlineColor: obj?.borderColor,
                alignContent: 'center',
              }}
              className={'statusContainer'}>
              <p
                className="txtStatus"
                style={{
                  color: obj?.color,
                }}>
                {returnAmountChange(record?.amountChange)}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Số dư sau biến động',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      render: (_, record) => {
        return formatPriceString(record?.balance) + ' đ';
      },
    },
  ];

  return columns;
};

export default getColumnsConfig;
