import revenueService from '@/network/services/report';
import {DEFAULT_FORMAT_DATE, DEFAULT_PAGE_SIZE} from '@/utils/constants';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ReportCampaignFilter} from '../ReportCampaign/ReportCampaignFilter';
import {Table} from 'antd';
import {RESPONSE_CODE} from '@/network/constants';
import columns from './TableReportCampaignAgentColumns';

export const ReportCampaignAgent: React.FC = () => {
  const defaultParams: ReportParams = {
    fromDate: moment()
      .startOf('month')
      .startOf('day')
      .format(DEFAULT_FORMAT_DATE),
    toDate: moment().endOf('day').format(DEFAULT_FORMAT_DATE),
  };
  const [table, setTable] = useState<ReportCampaignItem[]>([]);
  const [metaData, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [page, setPage] = useState<number>(0);
  const [params, setParams] = useState<ReportParams>(defaultParams);

  const onChangeRangeDate = (values: any) => {
    setParams({
      fromDate: values
        ? moment(values[0]).startOf('day').format(DEFAULT_FORMAT_DATE)
        : undefined,
      toDate: values
        ? moment(values[1]).endOf('day').format(DEFAULT_FORMAT_DATE)
        : undefined,
    });
    setPage(0);
  };

  const onSelectWeek = () => {
    setParams({
      fromDate: moment()
        .startOf('week')
        .startOf('day')
        .format(DEFAULT_FORMAT_DATE),
      toDate: moment().endOf('day').format(DEFAULT_FORMAT_DATE),
    });
    setPage(0);
  };

  const onSelectMonth = () => {
    setParams(defaultParams);
    setPage(0);
  };

  const onSizeChange = (page: number) => {
    setPage(Math.max(page - 1, 0));
  };

  const fetchData = async (params: ReportTableParams) => {
    const response = await revenueService.reportCampaignAgent(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data;
      setTable(data);
      setMetadata(pagination);
    }
  };

  useEffect(() => {
    fetchData({
      page: page,
      size: DEFAULT_PAGE_SIZE,
      ...params,
    });
  }, [params, page]);

  return (
    <div className="flex flex-col">
      <ReportCampaignFilter
        rangeDate={params}
        // search={search}
        onChangeRangeDate={onChangeRangeDate}
        // onChangeText={onChangeText}
        onSelectWeek={onSelectWeek}
        onSelectMonth={onSelectMonth}
      />
      <Table
        className="mt-4"
        dataSource={table}
        columns={columns(page)}
        scroll={{x: 800}}
        pagination={{
          current: page + 1,
          total: metaData?.total,
          defaultPageSize: 10,
          onChange: onSizeChange,
        }}
      />
    </div>
  );
};
