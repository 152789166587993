import {RESPONSE_CODE} from '@/network/constants';
import {resendOtp} from '@/network/services/auth';
import {LockOutlined} from '@ant-design/icons';
import {CaptFieldRef, ProFormCaptcha} from '@ant-design/pro-components';
import {message} from 'antd';
import React from 'react';
interface Props {
  phone?: string;
  email: string;
}

function RequestOTP({email}: Props) {
  const captchaRef = React.useRef<CaptFieldRef | null | undefined>();

  const handleResend = async () => {
    let params = {
      email: email,
    };
    const res = await resendOtp(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message.success('Đã gửi mã về email đăng ký!');
    } else {
      message.error(res?.data);
    }
  };

  React.useEffect(() => {
    captchaRef.current?.startTiming();
  }, [captchaRef.current]);

  return (
    <>
      <ProFormCaptcha
        fieldRef={captchaRef}
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        captchaProps={{
          size: 'large',
        }}
        placeholder={'Mã xác thực'}
        captchaTextRender={(timing, count) => {
          if (timing) {
            return `${count}s`;
          }
          return 'Gửi lại mã';
        }}
        name="otp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mã xác thực',
          },
        ]}
        onGetCaptcha={handleResend}
      />
    </>
  );
}

export default RequestOTP;
