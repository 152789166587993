import {uploadImg} from '@/network/services/upload';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {
  CameraOutlined,
  CloseCircleOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {DrawerForm} from '@ant-design/pro-components';
import {
  Avatar,
  Button,
  Col,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  Upload,
  message,
} from 'antd';
import {Ref, forwardRef, useImperativeHandle, useState} from 'react';

export type DetailModalRef = {
  setOpenModal: (data: ListRequiredInterface) => void;
  closeModalDetail: () => void;
};

type Props = {
  isAdmin: boolean;
  isReceived: boolean;
  commentTicket?: (
    params?: {content?: string; images: string[]},
    id?: number
  ) => void;
  closeTicket: (id: number) => void;
  receiveTicket: (id: number) => void;
};

const ModalDetails = (
  {isAdmin, isReceived, commentTicket, closeTicket, receiveTicket}: Props,
  ref: Ref<DetailModalRef>
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dataDetails, setDataDetails] = useState<
    ListRequiredInterface | undefined
  >();
  const [isShowTextBox, setIsShowText] = useState<boolean>(false);
  const [fileList, setFileList] = useState<{name: string; url: string}[]>([]);
  const [textInput, setTextInput] = useState<string>('');

  useImperativeHandle(ref, () => ({
    setOpenModal,
    closeModalDetail,
  }));

  const setOpenModal = (data: ListRequiredInterface) => {
    setDataDetails(data);
    setOpen(true);
    setIsShowText(false);
    setFileList([]);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeModalDetail = () => {
    onClose();
  };

  const DATA = [
    {
      id: 1,
      label: 'Mã yêu cầu:',
      content: dataDetails?.code,
    },
    {
      id: 2,
      label: 'Tiêu đề:',
      content: dataDetails?.title,
    },
    {
      id: 3,
      label: 'Họ và tên:',
      content: dataDetails?.fullName,
    },
    {
      id: 4,
      label: 'Số điện thoại:',
      content: dataDetails?.phoneNumber,
    },
    {
      id: 5,
      label: 'Địa chỉ email:',
      content: dataDetails?.email,
    },
  ];

  const handleUpload = async (options: any) => {
    if (fileList.length > 9) {
      message.error('Tải lên tối đa 10 ảnh');
    } else {
      const {file} = options;
      try {
        const res = await uploadImg({file, category: 'images'});
        if (res && (res.linkUrl || res.url)) {
          const {linkUrl, idStr} = res;
          setFileList([
            ...fileList,
            {
              url: linkUrl,
              name: idStr,
            },
          ]);
        } else {
          message?.error('Tải lên thất bại !');
        }
      } catch (err) {
        console.log('Eroor: ', err);
      }
    }
  };

  const handleSend = () => {
    if (!isNullOrEmpty(textInput) || fileList?.length > 0) {
      const params = {
        content: !isNullOrEmpty(textInput) ? textInput : undefined,
        images: fileList.map(item => item?.name),
      };
      commentTicket?.(params, Number(dataDetails?.id));
    }
  };

  const handleDeleteImage = (indexImg: number) => {
    const newArray = fileList.filter((_, index) => index !== indexImg);
    setFileList(newArray);
  };

  const handleAddRequire = () => {
    setIsShowText(true);
    setTextInput('');
  };

  const handleCloseTicket = () => {
    Modal.confirm({
      title: 'Kết thúc yêu cầu hỗ trợ',
      content: 'Bạn có muốn kết thúc yêu cầu hỗ trợ ?',
      okText: 'Đồng ý',
      width: 350,
      onOk: closeTicket?.bind(null, Number(dataDetails?.id)),
    });
  };
  const renderButtonCTV = () => {
    if ([0, 1].includes(Number(dataDetails?.status))) {
      return (
        <Button type="primary" onClick={handleAddRequire}>
          Bổ sung yêu cầu
        </Button>
      );
    }
    if (dataDetails?.status === 2) {
      return (
        <Space>
          <Button type="primary" onClick={handleAddRequire}>
            Bổ sung yêu cầu
          </Button>
          <Button onClick={handleCloseTicket}>Kết thúc</Button>
        </Space>
      );
    }
    if (dataDetails?.status === 3) {
      return null;
    }
  };
  const renderButtonAdmin = () => {
    if (isReceived && [0, 1, 2].includes(Number(dataDetails?.status))) {
      return (
        <Button type="primary" onClick={handleAddRequire}>
          Phản hồi
        </Button>
      );
    }
    if (isReceived && dataDetails?.status === 3) {
      return null;
    }
    if (!isReceived) {
      return (
        <Button
          type="primary"
          onClick={receiveTicket?.bind(null, Number(dataDetails?.id))}
          style={{backgroundColor: '#1E7D30EB'}}>
          Tiếp nhận
        </Button>
      );
    }
  };

  const renderTextBox = () => {
    return (
      <div className="flex flex-col w-full">
        <div className="flex items-end w-full">
          <Input.TextArea
            placeholder="Nhập thông tin yêu cầu..."
            style={{
              borderWidth: 0,
              borderColor: 'transparent',
              boxShadow: 'none',
            }}
            onChange={(e: {target: {value: string}}) =>
              setTextInput(e?.target?.value)
            }
            autoSize
          />
          <div className="flex mb-1">
            <Upload
              showUploadList={false}
              accept="image/*"
              customRequest={handleUpload}>
              <CameraOutlined className="text-[18px] text-[#0054D2] cursor-pointer" />
            </Upload>
            <SendOutlined
              className="text-[18px] text-[#0054D2] ml-3 cursor-pointer"
              onClick={handleSend}
            />
          </div>
        </div>
        <div className="ml-2 mr-2 flex flex-wrap ">
          {fileList?.map((item, index) => (
            <div
              key={index}
              className="mr-2 mb-2 bg-[#fff] rounded-md relative">
              <div
                style={{
                  position: 'absolute',
                  top: -6,
                  right: -6,
                  zIndex: 1,
                }}
                onClick={handleDeleteImage?.bind(null, index)}>
                <CloseCircleOutlined />
              </div>
              <Image
                width={65}
                height={65}
                src={item?.url}
                className="rounded-md"
                style={{objectFit: 'cover'}}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    return isAdmin
      ? isShowTextBox
        ? 'Phản hồi yêu cầu hỗ trợ'
        : 'Xem thông tin yêu cầu hỗ trợ'
      : isShowTextBox
        ? 'Bổ sung yêu cầu hỗ trợ'
        : 'Xem thông tin yêu cầu hỗ trợ';
  };

  return (
    <DrawerForm
      title={renderTitle()}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      submitter={{
        render: isShowTextBox
          ? renderTextBox
          : isAdmin
            ? renderButtonAdmin
            : renderButtonCTV,
      }}
      open={open}
      drawerProps={{
        destroyOnClose: false,
        onClose: onClose,
      }}>
      <div className="mb-3 font-bold text-[17px]">Thông tin chung</div>
      {DATA?.map((item, index) => (
        <Row key={index} gutter={24} className="mb-4">
          <Col span={8}>
            <Typography className="font-[600]">{item?.label}</Typography>
          </Col>
          <Col span={16}>
            <Typography>{item.content || 'Chưa có thông tin'}</Typography>
          </Col>
        </Row>
      ))}
      <div className="mb-3 font-bold text-[17px]">Thông tin yêu cầu</div>
      <div>
        {dataDetails?.ticketResponses?.map((item, index) => {
          return (
            <div
              key={index}
              style={{backgroundColor: item?.partner ? '#DFEAFE' : '#FEF9E6'}}
              className={`mb-5 flex flex-col justify-center rounded-md p-2`}>
              <div className="flex items-center mb-2">
                <Avatar
                  size="small"
                  icon={<UserOutlined />}
                  style={{
                    backgroundColor: item?.partner ? '#9D4C32' : '#0F481A',
                  }}
                />
                <Typography
                  style={{color: item?.partner ? '#9D4C32' : '#0F481A'}}
                  className={`italic ml-2`}>
                  {item.fullName}
                </Typography>
                <Typography
                  style={{color: item?.partner ? '#9D4C32' : '#0F481A'}}
                  className={`italic ml-2`}>
                  {item.createdDate}
                </Typography>
              </div>
              <div className="ml-7 mr-7">
                <Typography className="text-justify text whitespace-pre-line">
                  {item?.content}
                </Typography>
                <div className="mt-3 flex flex-wrap">
                  {item?.images?.map((img, indexImg) => (
                    <div
                      key={indexImg}
                      className="mr-2 mb-2 bg-[#fff] rounded-md">
                      <Image
                        width={65}
                        height={65}
                        src={img}
                        className="rounded-md"
                        style={{objectFit: 'cover'}}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DrawerForm>
  );
};
export const DetailsModal = forwardRef(ModalDetails);
