import {Button, DatePicker, Row} from 'antd';
import React from 'react';
import moment from 'moment';
import {DATE_FORMAT} from '@/utils/constants';

type Props = {
  rangeDate: ReportParams;
  onChangeRangeDate: (values: any) => void;
  onSelectWeek: () => void;
  onSelectMonth: () => void;
};

export const ReportCampaignFilter: React.FC<Props> = ({
  rangeDate,
  onChangeRangeDate,
  onSelectWeek,
  onSelectMonth,
}) => {
  const {RangePicker} = DatePicker;

  const isActive = (time: 'week' | 'month') =>
    rangeDate.fromDate && rangeDate.toDate
      ? moment(rangeDate.fromDate.valueOf()).format(DATE_FORMAT) ==
          moment().startOf(time).format(DATE_FORMAT) &&
        moment(rangeDate.toDate.valueOf()).format(DATE_FORMAT) ==
          moment().format(DATE_FORMAT)
      : false;

  return (
    <div className="flex">
      <Row className="w-full">
        <RangePicker
          format={DATE_FORMAT}
          onChange={onChangeRangeDate}
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          value={[rangeDate.fromDate, rangeDate.toDate]}
          className="w-72 max-md:mb-3 max-md:w-[90vw]"
        />

        <Button
          type="default"
          className={`mr-2 w-32 md:ml-2 ${
            isActive('week') ? 'active' : 'default'
          }`}
          onClick={onSelectWeek}>
          Tuần này
        </Button>
        <Button
          type="default"
          className={`mr-2 w-32 ${isActive('month') ? 'active' : 'default'}`}
          onClick={onSelectMonth}>
          Tháng này
        </Button>
      </Row>
    </div>
  );
};
