import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {Checkbox, Col, Row} from 'antd';
import {FC, useEffect, useState} from 'react';
import DropdownPermission from './DropdownPermission';

type ItemProps = {
  isSubModule?: boolean;
  isHaveExpand?: boolean;
  item: ModulePermission;
  onSelectPermission: (items: (ItemPermission | undefined)[]) => void;
  onExpand?: () => void;
  expand?: boolean;
};

const ItemModule: FC<ItemProps> = ({
  isHaveExpand,
  isSubModule,
  item,
  expand,
  onSelectPermission,
  onExpand,
}) => {
  const [view, setView] = useState<ItemPermission | undefined>(
    item?.permissions?.find((item: ItemPermission) => item.scope === 'view')
  );

  const [update, setUpdate] = useState<ItemPermission | undefined>(
    item?.permissions?.find((item: ItemPermission) => item.scope === 'update')
  );

  const [create, setCreate] = useState<ItemPermission | undefined>(
    item?.permissions?.find((item: ItemPermission) => item.scope === 'create')
  );

  const onSelectedAll = () => {
    let isSelectedAll = !!view?.active && !!create?.active && !!update?.active;
    setView((prev: any) => ({
      ...prev,
      active: !isSelectedAll,
    }));
    setCreate((prev: any) => ({
      ...prev,
      active: !isSelectedAll,
    }));
    setUpdate((prev: any) => ({
      ...prev,
      active: !isSelectedAll,
    }));
  };

  const onSelectView = () => {
    setView((prev: any) => ({
      ...prev,
      active: !prev.active,
    }));
  };

  const onSelectCreate = () => {
    setCreate((prev: any) => ({
      ...prev,
      active: !prev.active,
    }));
  };

  const onSelectUpdate = () => {
    setUpdate((prev: any) => ({
      ...prev,
      active: !prev.active,
    }));
  };

  useEffect(() => {
    onSelectPermission([view, update, create]);
  }, [view, update, create]);

  const anotherPermission = item?.permissions.filter(
    item => !['view', 'update', 'create'].includes(item.scope)
  );
  return (
    <div>
      <Row gutter={[24, 12]} className="bg-white h-8" align="middle">
        <Col xs={24} sm={1}>
          <Checkbox
            onChange={onSelectedAll}
            checked={!!view?.active && !!create?.active && !!update?.active}
          />
        </Col>
        <Col xs={24} sm={7}>
          <div className="flex" onClick={onExpand}>
            <p className={isSubModule ? 'pl-6 pr-2' : 'pr-2'} style={{fontSize: 14}}>{item.name}</p>
            {isHaveExpand &&
              (expand ? <CaretDownOutlined /> : <CaretUpOutlined />)}
          </div>
        </Col>
        <Col xs={24} sm={4}>
          {view?.id && (
            <div className="flex justify-center">
              <Checkbox checked={view.active} onChange={onSelectView} />
            </div>
          )}
        </Col>
        <Col xs={24} sm={4}>
          {create?.id && (
            <div className="flex justify-center">
              <Checkbox checked={create.active} onChange={onSelectCreate} />
            </div>
          )}
        </Col>
        <Col xs={24} sm={4}>
          {update?.id && (
            <div className="flex justify-center">
              <Checkbox checked={update.active} onChange={onSelectUpdate} />
            </div>
          )}
        </Col>
        <Col xs={24} sm={4}>
          {anotherPermission.length > 0 && (
            <DropdownPermission
              data={anotherPermission}
              onSelectPermission={onSelectPermission}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ItemModule;
