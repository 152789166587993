import {CampaignFilter} from './CampaignFilter';
import {useCampaign} from '@/hooks/useCampaign';
import {CreateModal} from './ModalCreate';
import {ProTable} from '@ant-design/pro-components';
import getColumnsConfig from './TableColumns';
import {useProducts} from '@/hooks/useProduct';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {CampaignDetailModal} from './CampaignDetail';
import {ConfigModal} from './ModalConfig';
import {useWindowSize} from '@/hooks/useWindowSize';

export const Campaigns = () => {
  const {
    stateCampaign,
    createCampaign,
    updateCampaign,
    handleSearch,
    handleSearchStartDate,
    handleSearchEndDate,
    actionRef,
    createRef,
    campaignDetailModalRef,
    configModalRef,
    handleAddCampaign,
    handleEditCampaign,
    hiddenCampaign,
    contextHolder,
    onPageChange,
    onFilterAgent,
    handleDetailCampaign,
    handleConfig,
    createConfigShareValue,
  } = useCampaign();
  const windowSize = useWindowSize();
  const {stateProducts, onFilterProvided} = useProducts();
  const columns = getColumnsConfig(
    handleEditCampaign,
    actionRef,
    hiddenCampaign,
    handleDetailCampaign,
    handleConfig
  );

  return (
    <div className="flex flex-col">
      <ProTable<any>
        scroll={{x: 1500, y: windowSize?.height - 350}}
        className={'custom-table'}
        rowKey="id"
        dataSource={stateCampaign?.data}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        loading={stateCampaign?.loading}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <CampaignFilter
            onChange={e => handleSearch(e.target.value)}
            onChangeStartDate={(_: any, value: string[]) =>
              handleSearchStartDate(value)
            }
            onChangeEndDate={(_: any, value: string[]) =>
              handleSearchEndDate(value)
            }
            onAddCampaign={handleAddCampaign}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: stateCampaign?.filters?.page + 1,
          total: stateCampaign?.metadata?.total,
          defaultPageSize: stateCampaign?.filters?.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <CreateModal
        ref={createRef}
        isEdit={stateCampaign?.isEdit}
        product={stateProducts?.products?.filter(item => item.status === 0)}
        agents={stateCampaign?.listAgent}
        suppliers={stateProducts.suppliers}
        updateCampaign={updateCampaign}
        createCampaign={createCampaign}
        onChangeSelectProvided={(_values: string, options: any) => {
          onFilterProvided(options?.code);
          onFilterAgent(options?.type);
        }}
        onFilterProvided={onFilterProvided}
        onFilterAgent={onFilterAgent}
      />
      <CampaignDetailModal ref={campaignDetailModalRef} />
      <ConfigModal
        ref={configModalRef}
        collaborators={stateCampaign?.listCollaborators}
        createConfig={createConfigShareValue}
      />
      {contextHolder}
    </div>
  );
};
