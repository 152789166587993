import {formatPriceString} from '@/utils/stringUtils';
import {Button, Input} from 'antd';
import React, {useState} from 'react';
import './styles.scss';

export type CreateModalRef = {
  onOpen: () => void;
};

export type Props = {
  userInfo?: AppStorage['userInfor'];
  onRequest: (amount: number) => void;
};

export const RequestPayment = ({userInfo, onRequest}: Props) => {
  const [amount, setAmount] = useState<string>('');
  const [error, setError] = useState<string>('');

  const inputRef = React.useRef<any>(null);

  const validateAmountPayment = (value: string) => {
    if (/\D/.test(value)) {
      return 'Chỉ được nhập chữ số!';
    } else if (value?.length === 0) {
      return 'Vui lòng nhập số tiền!';
    } else if (
      userInfo?.balanceAvailable &&
      Number(value) > userInfo?.balanceAvailable &&
      Number(value) > 0
    ) {
      return 'Số tiền phải nhỏ hơn hoặc bằng số dư khả dụng';
    } else if (Number(value) < 100000) {
      return 'Số tiền phải lớn hơn 100.000 đ';
    } else if (Number(value) % 10000 > 0) {
      return 'Số tiền phải là bội số của 10.000 đ';
    } else setError('');
    setAmount(value);
  };

  const handleUpdate = async () => {
    const err = validateAmountPayment(amount);
    if (err) {
      setError(err);
      return;
    }
    onRequest?.(Number(amount));
    setAmount('');
  };

  const blurAmount = () => {
    if (/\D/.test(amount?.replaceAll('.', ''))) {
      return '';
    }
    return formatPriceString(amount?.replaceAll('.', ''));
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <div className="w-[300px]">
      <Input
        ref={inputRef}
        autoFocus
        placeholder="Nhập số tiền..."
        className="w-[calc(100%)]"
        onChange={event => {
          setError('');
          setAmount(event?.target?.value?.replaceAll('.', ''));
        }}
        value={blurAmount()}
        maxLength={20}
      />
      {error?.length > 0 && (
        <p style={{color: 'red', fontSize: 14, marginTop: 5}}>{error}</p>
      )}
      <div className="mt-4 flex justify-center">
        <Button type="primary" onClick={handleUpdate}>
          Đồng ý
        </Button>
      </div>
    </div>
  );
};
