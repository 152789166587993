import {DrawerForm} from '@ant-design/pro-components';
import {Form, Input} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle} from 'react';

export type CreateRegionModalRef = {
  open: (data?: OrderItemInterface) => void;
  close: () => void;
};

type Props = {
  createRegionCompany: (params: {name: string}) => void;
  isEdit?: boolean;
  editCompany: (values: any, id: number) => void;
};

const ModalCreateRegion = (
  {createRegionCompany, isEdit, editCompany}: Props,
  ref: Ref<CreateRegionModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    OrganizitionsInterface | undefined
  >();

  const handleFillData = (data: OrganizitionsInterface | undefined) => {
    if (isEdit) {
      form.setFieldsValue({
        name: data?.name,
        note: data?.note,
      });
    } else {
      form.resetFields();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
        close: onclose,
      };
    },
    [isEdit]
  );

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  const onclose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    if (isEdit) {
      editCompany(values, Number(dataDetails?.id));
    } else {
      createRegionCompany(values);
    }
  };

  return (
    <DrawerForm
      title={isEdit ? 'Chỉnh sửa Khu vực' : 'Thêm mới Khu vực'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 400,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      onFinish={onSubmit}>
      <Form.Item
        label={'Khu vực'}
        name="name"
        rules={[{required: true, message: 'Vui lòng nhập tên khu vực'}]}>
        <Input placeholder="Nhập tên khu vực" />
      </Form.Item>
      <Form.Item label={'Ghi chú'} name={'note'}>
        <Input.TextArea placeholder="Nhập ghi chú" />
      </Form.Item>
    </DrawerForm>
  );
};

export const CreateRegionModal = forwardRef(ModalCreateRegion);
