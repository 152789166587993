import UploadImage from '@/components/UploadImage';
import {RESPONSE_CODE} from '@/network/constants';
import {updateCTV} from '@/network/services/accounts';
import {CATEGORY_TYPE_IMAGE, ROLES} from '@/utils/enum';
import {getListBank} from '@/utils/helper';
import {
  DrawerForm,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import {Form, Space, message} from 'antd';
import {Ref, forwardRef, useImperativeHandle, useState} from 'react';

export type Props = {
  onClose?(refresh?: boolean): void;
};

export type UpdateModalRef = {
  open: (options?: {isEdit?: boolean; record?: AccountType}) => void;
};

const optionActive = [
  {label: 'Đang hoạt động', value: 0},
  {label: 'Ngừng hoạt động', value: 1},
  {label: 'Đã xoá', value: -1},
  {label: 'Chờ xác thực', value: 2},
];

function Update(props: Props, ref: Ref<UpdateModalRef>) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [img, setImg] = useState<{
    imageIdentityFront?: string;
    imageIdentityBack?: string;
    imageBankAccount?: string;
  }>({});
  const [role, setRole] = useState<string | undefined>('');
  const [idUser, setIdUser] = useState<number>(0);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: opts => {
          setRole(opts?.record?.role);
          setImg({
            imageBankAccount: opts?.record?.imageBankAccount,
            imageIdentityBack: opts?.record?.imageIdentityBack,
            imageIdentityFront: opts?.record?.imageIdentityFront,
          });
          setIdUser(opts?.record?.userId!);
          form.setFieldsValue(opts?.record);
          form.setFieldValue(
            'userName',
            opts?.record?.userName || opts?.record?.phoneNumber
          );
          setOpen(true);
        },
      };
    },
    []
  );

  const handleUpdate = async (values: any) => {
    const data = {
      ...values,
      // imageBankAccount: values.imageBankAccount?.[0]?.url,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
    };
    const res = await updateCTV(data, idUser);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onCancel(true);
      message.success('Cập nhật thành công');
    } else {
      if (res.status === 500) {
        message.error('Cập nhật không thành công');
      } else {
        message.error(res.data);
      }
    }
  };

  const onCancel = (reload: boolean = false) => {
    setOpen(false);
    props.onClose && props.onClose(reload);
  };

  const renderInput = () => {
    if (role === ROLES.AM_LEADER || role === ROLES.AM) {
      return null;
    }
    return (
      <>
        <ProFormText
          name="phoneNumber"
          width="md"
          placeholder={'Nhập số điện thoại'}
          rules={[
            {required: true},
            {
              pattern:
                /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
              message: 'Số điện thoại không hợp lệ',
            },
          ]}
          fieldProps={{maxLength: 10}}
          required
          disabled
          label="Số điện thoại"
        />
        <ProFormText
          width="md"
          name="identityCode"
          label="Số CCCD"
          fieldProps={{
            maxLength: 12,
          }}
          required
          rules={[
            {required: true},
            {
              pattern: /^\d+$/,
              message: 'CCCD không hợp lệ',
            },
          ]}
          placeholder={'Nhập số căn cước công dân'}
        />
        <Space>
          <UploadImage
            label="Ảnh mặt trước"
            required
            url={img.imageIdentityFront}
            name="imageIdentityFront"
            category={CATEGORY_TYPE_IMAGE.IDENTITY}
          />
          <UploadImage
            label="Ảnh mặt sau"
            required
            name="imageIdentityBack"
            url={img.imageIdentityBack}
            category={CATEGORY_TYPE_IMAGE.IDENTITY}
          />
        </Space>
        <ProFormText
          width="md"
          name="bankAccount"
          label="Số TK ngân hàng"
          rules={[
            {required: true},
            {
              pattern: /^\d+$/,
              message: 'Chỉ nhập số tài khoản',
            },
          ]}
          placeholder={'Nhập số tài khoản ngân hàng'}
          required
        />
        <ProFormSelect
          width="md"
          request={getListBank}
          name="bankName"
          required
          showSearch
          rules={[{required: true}]}
          label="Tên ngân hàng"
        />
        {/* <UploadImage
          label="Ảnh QR ngân hàng"
          name="imageBankAccount"
          url={img.imageBankAccount}
          category={CATEGORY_TYPE_IMAGE.BANK}
        /> */}
      </>
    );
  };

  return (
    <DrawerForm
      title={'Sửa tài khoản'}
      size="middle"
      form={form}
      open={open}
      onOpenChange={visible => {
        if (!visible) {
          onCancel();
        }
      }}
      drawerProps={{
        destroyOnClose: true,
        onClose: () => {
          setOpen(false);
        },
      }}
      grid
      width={window.innerWidth > 900 ? 500 : window.innerWidth - 100}
      rowProps={{gutter: 10}}
      submitTimeout={2000}
      submitter={{
        searchConfig: {
          submitText: 'Sửa',
        },
      }}
      onFinish={handleUpdate}>
      <ProFormText
        width="md"
        placeholder={'Nhập tên đăng nhập'}
        name="userName"
        disabled={true}
        required
        rules={[{required: true}]}
        label="Tên đăng nhập"
      />
      <ProFormText
        width="md"
        placeholder={'Nhập họ và tên'}
        name="fullname"
        rules={[
          {required: true},
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              if (!String(value).length || !String(value).trim().length)
                return Promise.reject();
              return Promise.resolve();
            },
            required: true,
          },
        ]}
        label="Họ và tên"
        required
      />
      <ProFormSelect
        width="md"
        options={optionActive}
        name="status"
        initialValue={0}
        allowClear={false}
        required
        disabled
        label="Trạng thái hoạt động"
      />
      {renderInput()}
    </DrawerForm>
  );
}

export const UpdateModal = forwardRef(Update);
