import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL_ACCOUNT, API_URL_AUTH} from '../apiUrl';
import {RESPONSE_CODE} from '../constants';
import {getRequestUrl} from '../utils';

export const login = async (body: any) => {
  const url = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.LOGIN,
  });
  return await NetWork.post(url, body);
};

export const logout = async (body: any) => {
  const url = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.LOGOUT,
  });
  return await NetWork.post(url, body);
};

export const register = async (body: SingUpType) => {
  const regisUrl = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.COLLABORATORS,
    subId: API_URL_AUTH.REGISTER,
  });
  const regisOTPsUrl = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.COLLABORATORS,
    partial: API_URL_AUTH.REGISTER,
    subId: API_URL_AUTH.REGISTER_OTP,
  });
  const res = await NetWork.post(regisUrl, body);
  if (res?.status == RESPONSE_CODE.SUCCESS) {
    const resOtp = await NetWork.post(regisOTPsUrl, {
      email: body.email,
    });
    if (resOtp?.status == RESPONSE_CODE.NO_CONTENT) {
      return res;
    } else {
      return res;
    }
  }
  return res;
};

export const verifyOtp = async (body: SendOtpType) => {
  const url = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.COLLABORATORS,
    partial: API_URL_AUTH.REGISTER,
    subId: API_URL_AUTH.VERIFY_OTP,
  });
  return await NetWork.post(url, body);
};

export const resendOtp = (params: {
  phoneNumber?: string;
  email: string;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(API_URL_AUTH.AUTH, {
    parentId: API_URL_AUTH.COLLABORATORS,
    partial: API_URL_AUTH.REGISTER,
    subId: API_URL_AUTH.RESEND_OTP,
  });
  return NetWork.post(url, params);
};

export const getUser = async () => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER);
  return await NetWork.get(url);
};

export const authServices = {login, logout, getUser};
