import moment, {Moment} from 'moment';
/**
 * Check value is null or empty
 * @param data
 * @returns {boolean}
 */
export function isNullOrEmpty(data: any | any[]): boolean {
  if (!data) {
    return true;
  }
  if (data instanceof Array) {
    return data.length === 0;
  }
  if (typeof data === 'number') {
    return data === 0;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }
  let output = data;
  if (typeof output !== 'string') {
    output = output.toString();
  }
  output = output.trim();

  return output.length <= 0;
}

export function deleteParamsNotUsing(params: any) {
  for (const key in params) {
    if (params[key] !== 0) {
      if (isNullOrEmpty(params[key])) {
        delete params[key];
      }
    }
  }
}

export function isLink(str: string) {
  var pattern = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
  return pattern.test(str);
}

/**
 * Format numbers using single letter notation (1K, 1M, 1B, 1T...)
 * @param input
 * @returns {string}
 */
export function formatNumberInSingleLetterNotation(input?: number): string {
  if (input === 0 || input === null || !input) {
    return '0';
  }
  const suffix = ['', 'K', 'M', 'B', 'T', 'Q'];
  const index = Math.floor(Math.log(input) / Math.log(1000));

  return (
    (Math.floor((input / Math.pow(1000, index)) * 10) / 10).toString() +
    suffix[index]
  );
}

/**
 * Convert type Moment to TimeStamp
 * @param value
 * @returns {Number}
 */
export const convertTimeStamp = (value: Moment): Number => {
  return Number(moment(value).format('X')) * 1000;
};

/**
 * This function convert date to timestamp
 * @param date date input
 * @param isStartOfDay is value check get start or end day
 * @returns {number}
 */
export const convertDateToTimeStamp = (
  date: any,
  isStartOfDay: boolean
): number => {
  if (isStartOfDay) {
    return Number(moment(date).startOf('day').format('X')) * 1000;
  } else {
    return Number(moment(date).endOf('day').format('X')) * 1000;
  }
};

/**
 * This function convert price type number to string like 3,123,123
 * @param price input type number
 * @returns {string || undefined}
 */
export const formatPrice = (price: number | undefined): string | undefined => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPriceString = (
  price: string | undefined
): string | undefined => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatValue = (inputValue: string) => {
  return inputValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertSuggestedPackages = (suggestedPackages: {
  [key: string]: string[];
}) => {
  return Object?.keys(suggestedPackages || {})?.map(name => {
    return {
      name,
      package: suggestedPackages[name]?.join(', '),
    };
  });
};

const viettelMobile: Set<string> = new Set([
  '162',
  '163',
  '164',
  '165',
  '166',
  '167',
  '168',
  '169',
  '86',
  '97',
  '98',
  '96',
  '88',
  '220',
  '221',
  '222',
  '223',
  '224',
  '246',
  '247',
  '248',
  '249',
  '625',
  '626',
  '627',
  '628',
  '629',
  '633',
  '664',
  '665',
  '666',
  '667',
  '668',
  '669',
]);

const newViettelPrefix: Set<string> = new Set([
  '086',
  '096',
  '097',
  '098',
  '032',
  '033',
  '034',
  '035',
  '036',
  '037',
  '038',
  '039',
]);

const newVinaphonePrefix: Set<string> = new Set([
  '088',
  '091',
  '094',
  '083',
  '084',
  '085',
  '081',
  '082',
]);

const newMobilePhonePrefix: Set<string> = new Set([
  '089',
  '090',
  '093',
  '070',
  '079',
  '077',
  '076',
  '078',
]);

export const getCarrier = (
  phoneNumber: string
): {name: string; code: string} => {
  const prefix: string = phoneNumber.substring(0, 3);
  if (viettelMobile.has(prefix) || newViettelPrefix.has(prefix)) {
    return {name: 'Viettel', code: 'VIETTEL'};
  } else if (newVinaphonePrefix.has(prefix)) {
    return {name: 'VinaPhone', code: 'VNP'};
  } else if (newMobilePhonePrefix.has(prefix)) {
    return {name: 'MobiFone', code: 'MBF'};
  }
  return {name: '', code: ''};
};

export const paymentStatus = [
  {label: 'Cho phép', value: 0},
  {label: 'Không cho phép', value: 1},
];
