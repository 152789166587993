import {RESPONSE_CODE} from '@/network/constants';
import {moFileServices} from '@/network/services/moFile';
import {ActionType} from '@ant-design/pro-components';
import {message} from 'antd';
import React, {useEffect} from 'react';

type FilterProps = {
  size: number;
  page: number;
  date?: string;
  fromDate?: string;
  toDate?: string;
  status?: number;
};

type statePaymentType = {
  files: any;
  metadata: MetadataInterface | undefined;
  filters: FilterProps;
  loading: boolean;
  isRefresh: boolean;
};

const defaultFilters = {
  size: 10,
  page: 0,
  fromDate: undefined,
  toDate: undefined,
  type: undefined,
  action: undefined,
  query: undefined,
};

export const useMOFile = () => {
  const [data, setData] = React.useState<statePaymentType>({
    files: [],
    metadata: undefined,
    filters: defaultFilters,
    loading: false,
    isRefresh: false,
  });

  const actionRef = React.useRef<ActionType>();

  useEffect(() => {
    if (data?.isRefresh) {
      getListMo();
    }
  }, [data?.isRefresh]);

  const onPageChange = (mPage: number, mSize: number) => {
    setData(prevState => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const getListMo = async () => {
    setData(prevState => ({
      ...prevState,
      loading: true,
    }));
    const res = await moFileServices.getListMO(data?.filters);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const {data, page, size, total, totalPages} = res?.data;
      setData(prevState => ({
        ...prevState,
        files: data,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
        loading: false,
        isRefresh: false,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error((res?.data as any) ?? 'Có lỗi xảy ra');
    }
  };

  const onFilterStatus = (value: number) => {
    setData((prevState: statePaymentType) => ({
      ...prevState,
      loading: true,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        status: value,
        page: 0,
      },
    }));
  };

  const onFilterDates = (values: any) => {
    setData((prevState: statePaymentType) => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0].format('YYYY-MM-DD'),
        toDate: values?.[1].format('YYYY-MM-DD'),
        page: 0,
      },
    }));
  };

  const onChangeAction = (value: string) => {
    setData((prevState: statePaymentType) => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        action: value,
        page: 0,
      },
    }));
  };

  return {
    data,
    onPageChange,
    actionRef,
    getListMo,
    onFilterStatus,
    onFilterDates,
    onChangeAction,
  };
};
