import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL, API_URL_AGENT, API_URL_AUTH, API_URL_ORDER} from '../apiUrl';
import {getRequestUrl} from '../utils';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

export const campaigns = (
  params: FilterListInterface
): Promise<AxiosResponse<CampaignResponseInterface>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN);
  return NetWork.get(url, params);
};

export const listAgent = (type: string) => {
  const url = getRequestUrl(API_URL_AGENT.AGENT, {
    parentId: `${API_URL_ORDER.LIST}?type=${type}`,
  });
  return NetWork.get(url);
};

export const createCampaign = (
  body: CampaignParamInterface
): Promise<AxiosResponse<CampaignResponseInterface>> => {
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN);
  return NetWork.post(url, body);
};

export const updateCampaign = (
  body: CampaignParamInterface,
  id: number
): Promise<AxiosResponse<CampaignResponseInterface>> => {
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN, {
    parentId: id,
  });
  return NetWork.patch(url, body);
};

const hiddenCampaign = (
  id: number,
  status: 0 | 1
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN, {
    parentId: id,
    partial: API_URL.status,
  });
  return NetWork.patch(url, {status});
};

const getConfigShareValue = (campaignId: number) => {
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN, {
    parentId: campaignId,
    partial: API_URL.commissionFee,
  });
  return NetWork.get(url);
};

const createConfigShareValue = (body: ConfigShareValue) => {
  const url = getRequestUrl(API_URL_AUTH.CAMPAIGN, {
    parentId: API_URL.commissionFee,
  });
  return NetWork.post(url, body);
};

export const campaignServices = {
  campaigns,
  listAgent,
  createCampaign,
  updateCampaign,
  hiddenCampaign,
  getConfigShareValue,
  createConfigShareValue,
};
