import {useWindowSize} from '@/hooks/useWindowSize';
import {isUserHaveOneOfPermissions} from '@/utils';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {ProTable} from '@ant-design/pro-components';
import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {useMOFile} from '@/hooks/useMOFile';
import getColumnsConfig from './TableColumns';
import {MOFilter} from './Filter';

export const ImportedMOFile: React.FC = () => {
  const {
    data,
    getListMo,
    actionRef,
    onPageChange,
    onFilterDates,
    onFilterStatus,
    onChangeAction,
  } = useMOFile();

  useEffect(() => {
    getListMo();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    isUserHaveOneOfPermissions([ROLES.AM_LEADER, ROLES.AM]) && navigate('/*');
  }, []);

  console.log('data', data?.files);

  const windowSize = useWindowSize();

  const columns = getColumnsConfig(actionRef);

  const renderContent = useMemo(() => {
    return (
      <ProTable<any>
        loading={data?.loading}
        className={'custom-table'}
        scroll={{x: 1000, y: windowSize?.height - 350}}
        rowKey="id"
        actionRef={actionRef}
        dataSource={data?.files}
        columns={columns}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <MOFilter
            onChangeStatus={onFilterStatus}
            onChangeDates={onFilterDates}
            onChangeAction={onChangeAction}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: data?.filters.page + 1,
          total: data?.metadata?.total,
          defaultPageSize: data?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
    );
  }, [data?.filters, columns]);

  return <div className="flex flex-col"> {renderContent} </div>;
};
