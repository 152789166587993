import {useWindowSize} from '@/hooks/useWindowSize';
import {ProTable} from '@ant-design/pro-components';
import {FC, useEffect, useRef, useState} from 'react';
import getColumnsConfig from './Column';
import PermissionFilters from './PermissionFilters';
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
} from '@/utils/constants';
import {permissionServices} from '@/network/services/permissions';
import {RESPONSE_CODE} from '@/network/constants';
import moment from 'moment';
import CreateGroupPermission from './CreateGroupPermission';

const FILTER_DEFAULT = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  name: '',
  status: undefined,
  createdFrom: undefined,
  createdTo: undefined,
};

const PermissionConfiguration: FC = () => {
  const windowSize = useWindowSize();
  const [data, setData] = useState<GroupPermissionItem[]>();
  const [metaData, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [filters, setFilters] = useState<FilterPermissionConfiguration>(FILTER_DEFAULT);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number | undefined>(undefined);

  const actionRef = useRef<any>();

  const onEditPermission = (id: number) => {
    setIsOpenCreateModal(true);
    setDetailId(id);
  };

  const columns = getColumnsConfig(actionRef, onEditPermission);

  const onCreate = () => {
    setIsOpenCreateModal(true);
    setDetailId(undefined);
  };

  const onCancel = () => {
    setIsOpenCreateModal(false);
    setDetailId(undefined);
  };

  const onChangeText = (value: string) => {
    setFilters(prev => ({
      ...prev,
      name: value,
    }));
  };

  const onChangeStatus = (value: number) => {
    setFilters(prev => ({
      ...prev,
      status: value,
    }));
  };

  const onChangeRangeDate = (_: any, dateString: string[]) => {
    setFilters(prev => ({
      ...prev,
      createdFrom: dateString?.[0]
        ? moment(dateString?.[0], 'YYYY-MM-DD').format(DEFAULT_FORMAT_DATE)
        : null,
      createdTo: dateString?.[1]
        ? moment(dateString?.[0], 'YYYY-MM-DD').format(DEFAULT_FORMAT_DATE)
        : null,
    }));
  };

  const fetchData = async (params: any) => {
    const response =
      await permissionServices.getPermissionConfigurations(params);

    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data ?? {};
      setData(data);
      setMetadata(pagination);
    }
  };

  const onRefreshPage = () => {
    setIsOpenCreateModal(false);
    setDetailId(undefined);
  };

  const onSizeChange = (page: number, size: number) => {
    setFilters(prev => ({
      ...prev,
      page: Math.max(page - 1, 0),
      size,
    }));
  };

  useEffect(() => {
    if (!isOpenCreateModal) {
      fetchData(filters);
    }
  }, [filters, isOpenCreateModal]);

  if (isOpenCreateModal) {
    return (
      <CreateGroupPermission onRefreshPage={onRefreshPage} onCancel={onCancel} id={detailId} />
    );
  }
  return (
    <div className="flex flex-col">
      <ProTable<any>
        className="custom-table"
        columns={columns}
        dataSource={data}
        scroll={{x: 1500, y: windowSize?.height - 300}}
        actionRef={actionRef}
        cardBordered
        rowKey="id"
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <PermissionFilters
            onChangeText={onChangeText}
            onChangeRangeDate={onChangeRangeDate}
            onChangeStatus={onChangeStatus}
            onCreate={onCreate}
          />
        )}
        options={false}
        pagination={{
          current: filters.page + 1,
          total: metaData?.total,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          pageSizeOptions: PAGE_SIZE_LIST,
          showSizeChanger: true,
          size: 'small',
          onChange: onSizeChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
      />
    </div>
  );
};

export default PermissionConfiguration;
