import useCheckMobileScreen from '@/hooks/useCheckMobileScreen';
import {useMyNotifications} from '@/hooks/useMyNotification';
import {useWindowSize} from '@/hooks/useWindowSize';
import {Divider, Drawer, List, Modal, Skeleton} from 'antd';
import React, {Ref, useImperativeHandle} from 'react';
import ItemNotification from './ItemNotification';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {};

export type MyNotificationModalRef = {
  open: () => void;
};

const MyNotification = ({}: Props, ref: Ref<MyNotificationModalRef>) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const {data, loading, isLoadMore, onLoadMore} = useMyNotifications();
  const windowSize = useWindowSize();
  const isMobile = useCheckMobileScreen();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [detail, setDetail] = React.useState<NotificationItem | undefined>(
    undefined
  );

  const showModal = (item: any) => {
    setDetail(item);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setDetail(undefined);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDetail(undefined);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: () => {
          setOpen(true);
        },
      };
    },
    []
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      onClose={onClose}
      open={open}
      width={isMobile ? windowSize.width : 450}
      title={'Thông báo'}>
      <div>
        <InfiniteScroll
          dataLength={data.length}
          next={onLoadMore}
          hasMore={data.length < 50}
          loader={
            isLoadMore ? (
              <Skeleton avatar paragraph={{rows: 1}} active />
            ) : undefined
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv">
          <List
            dataSource={data}
            className="mt-3"
            loading={loading}
            renderItem={(item: NotificationItem) => (
              <ItemNotification
                item={item}
                onClick={showModal.bind(null, item)}
              />
            )}
          />
        </InfiniteScroll>

        <Modal
          title={detail?.title}
          open={isModalOpen}
          cancelButtonProps={{style: {display: 'none'}}}
          onOk={handleOk}
          onCancel={handleCancel}>
          <p>{detail?.content}</p>
        </Modal>
      </div>
    </Drawer>
  );
};

export const MyNotificationModal = React.forwardRef(MyNotification);
