import {isUserHaveOneOfPermissions} from '@/utils';
import {PERMISSIONS, STATUS_GROUP_PERMISSION} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, DatePicker, Input, Row, Select} from 'antd';
import {FC} from 'react';

type Props = {
  onChangeText: (value: string) => void;
  onChangeRangeDate: (_: any, dateString: string[]) => void;
  onChangeStatus: (value: number) => void;
  onCreate: () => void;
};

const PermissionFilters: FC<Props> = ({
  onChangeText,
  onChangeRangeDate,
  onChangeStatus,
  onCreate,
}) => {
  const onChange = (e: any) => {
    onChangeText?.(e.target.value);
  };

  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={6}>
          <Input.Search
            placeholder="Nhập tên nhóm quyền"
            allowClear
            onChange={onChange}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <DatePicker.RangePicker
            className="w-full"
            onChange={onChangeRangeDate}
            format={'YYYY-MM-DD'}
            placeholder={['Ngày tạo', '']}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Select
            allowClear
            style={{minWidth: 200}}
            placeholder="Trạng thái"
            onChange={onChangeStatus}
            options={STATUS_GROUP_PERMISSION}
          />
        </Col>
        {isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.createRole) && (
          <Col xs={24} sm={12} md={6} className="flex justify-end">
            <Button
              key="button"
              icon={<PlusOutlined />}
              type="primary"
              onClick={onCreate}>
              Tạo nhóm quyền
            </Button>
          </Col>
        )}
      </Row>
    </ProCard>
  );
};

export default PermissionFilters;
