import {uploadImg} from '@/network/services/upload';
import {UploadOutlined} from '@ant-design/icons';
import {Modal, Upload} from 'antd';
import {UploadFile} from 'antd/lib';
import {Ref, forwardRef, useImperativeHandle, useState} from 'react';

type Props = {
  onFileListChange: (idStr: string, url: string) => void;
  onRemoveFile: (file: UploadFile) => void;
};

const MultiImage = ({onFileListChange, onRemoveFile}: Props, ref: Ref<any>) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useImperativeHandle(ref, () => ({
    handleModalClose,
  }));

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview || '');
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({fileList}: {fileList: UploadFile[]}) =>
    setFileList(fileList);

  const handlePostImage = async (options: any) => {
    const {onSuccess, onError, file} = options;
    try {
      const res = await uploadImg({file, category: 'images'});
      if (res && (res.linkUrl || res.url)) {
        file.url = res.linkUrl ?? res.url;
      } else {
        file.status = 'error';
      }
      onSuccess('Ok');
      onFileListChange?.(res?.idStr, res?.linkUrl);
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({err: error});
    }
  };

  const onRemove = (file: UploadFile) => {
    onRemoveFile?.(file);
  };

  const handleModalClose = () => {
    setFileList([]);
  };

  const uploadButton = (
    <div>
      <UploadOutlined className="text-[17px]" />
      <div className="ant-upload-text">Tải lên</div>
    </div>
  );

  return (
    <div className="clearfix">
      <Upload
        ref={ref}
        accept=".jpg, .jpeg, .png, .webp"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={handlePostImage}
        onRemove={onRemove}>
        {fileList.length >= 10 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </div>
  );
};

export const UploadMultiImage = forwardRef(MultiImage);
