import {DrawerForm} from '@ant-design/pro-components';
import {Form, Input, Select} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle} from 'react';

export type CreateBranchModalRef = {
  open: (data?: OrganizitionsInterface) => void;
  close: () => void;
};

type Props = {
  createBranchCompany: (params: {
    name: string;
    regionCompanyId: number;
  }) => void;
  listRegion: OrganizitionsInterface[];
  isEdit: boolean;
  editBranch: (values: any, id: number) => void;
};

const ModalCreateBranch = (
  {createBranchCompany, listRegion, isEdit, editBranch}: Props,
  ref: Ref<CreateBranchModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    OrganizitionsInterface | undefined
  >();

  const handleFillData = (data: OrganizitionsInterface | undefined) => {
    if (isEdit) {
      form.setFieldsValue({
        regionCompanyId: data?.regionCompanyId,
        name: data?.name,
        note: data?.note,
      });
    } else {
      form.resetFields();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
        close: onclose,
      };
    },
    []
  );

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  const onclose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    if (isEdit) {
      editBranch(values, Number(dataDetails?.id));
    } else {
      createBranchCompany(values);
    }
  };

  return (
    <DrawerForm
      title={isEdit ? 'Chỉnh sửa Chi nhánh' : 'Thêm mới Chi nhánh'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 400,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      onFinish={onSubmit}>
      <Form.Item
        label={'Khu vực'}
        name={'regionCompanyId'}
        rules={[{required: true, message: 'Vui lòng chọn tên khu vực'}]}>
        <Select
          showSearch
          placeholder={'Chọn khu vực'}
          options={listRegion.map(item => ({
            value: item?.id,
            label: item.name,
          }))}
          disabled={isEdit}
          filterOption={(input, option) =>
            (option as {value: number; label: string}).label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
      <Form.Item
        label={'Chi nhánh'}
        name={'name'}
        rules={[{required: true, message: 'Vui lòng nhập tên chi nhánh'}]}>
        <Input placeholder="Nhập tên chi nhánh" />
      </Form.Item>
      <Form.Item label={'Ghi chú'} name="note">
        <Input.TextArea placeholder="Nhập ghi chú" />
      </Form.Item>
    </DrawerForm>
  );
};

export const CreateBranchModal = forwardRef(ModalCreateBranch);
