export const ROUTERS = {
  Dashboard: {
    key: 'dashboard',
    path: '/',
  },
  Accounts: {
    key: 'accounts',
    path: '/accounts',
  },
  PermissionConfiguration: {
    key: 'permission-configuration',
    path: '/permission-configuration',
  },
  Register: {
    key: 'register',
    path: '/register',
  },
  ChangePassword: {
    key: 'change-password',
    path: '/change-password',
  },
  Collaborators: {
    key: 'collaborators',
    path: '/collaborators',
  },
  Profile: {
    key: 'profile',
    path: '/profile',
  },
  Products: {
    key: 'products',
    path: '/products',
  },
  Campaigns: {
    key: 'campaigns',
    path: '/campaigns',
  },
  ReportCampaign: {
    key: 'report-campaign',
    path: '/report-campaign',
  },
  ReportCampaignAgent: {
    key: 'report-campaign-agent',
    path: '/report-campaign-agent',
  },
  Orders: {
    key: 'orders',
    path: '/orders',
  },
  Documents: {
    key: 'documents',
    path: '/documents',
  },
  Search: {
    key: 'search',
    path: '/search',
  },
  Payments: {
    key: 'payments',
    path: '/payments',
  },
  BalanceHistory: {
    key: 'balance-history',
    path: '/balance-history',
  },
  ImportedMOFile: {
    key: 'imported-mo-file',
    path: '/imported-mo-file',
  },
  Posts: {
    key: 'posts',
    path: '/posts',
  },
  Notification: {
    key: 'notification',
    path: '/notification',
  },
  Organizations: {
    key: 'organizations',
    path: '/organizations',
  },
  RequireSupport: {
    key: 'require-support',
    path: '/require-support',
  },
};

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_REPORT_PAGE_SIZE = 10;

export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE = 768;

export const PAGE_SIZE_LIST = [10, 20, 50, 100, 200];

export const GLOBAL_HEIGHT = 64 + 56 + 48;

export const DEFAULT_PASSWORD = '1234567a@';

export const dateFormat = 'DD/MM/YYYY';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const STATUS_ORDER = [
  // {id: 0, value: 'Đã tạo đơn hàng'},
  // {id: 1, value: 'Đang vận chuyển'},
  {id: 2, value: 'Hoàn thành'},
  {id: 3, value: 'Thất bại'},
];

export const STATUS_PAYMENT = [
  {id: 0, value: 'Chờ xử lý'},
  {id: 1, value: 'Đang xử lý'},
  {id: 2, value: 'Đã chuyển khoản'},
  {id: 3, value: 'Đã nhận tiền'},
  {id: 4, value: 'Đã huỷ'},
];

export const MO_STATUS = [
  {id: 0, value: 'Chờ xử lý'},
  {id: 1, value: 'Đang xử lý'},
  {id: 2, value: 'Thành công'},
  {id: 3, value: 'Thất bại'},
];

export const MO_ACTION = [
  {id: 'import', value: 'import'},
  {id: 'export', value: 'export'},
];

export const STATUS_GROUP_PERMISSION = [
  {value: 0, label: 'Hoạt động'},
  {value: 1, label: 'Ngừng hoạt động'},
];
export const TICKET_STATUS = [
  {
    value: 0,
    label: 'Chờ xử lý',
    color: '#E37E07',
  },
  {
    value: 1,
    label: 'Đang xử lý',
    color: '#D0D31F',
  },
  {
    value: 2,
    label: 'Đã xử lý',
    color: '#2C67DA',
  },
  {
    value: 3,
    label: 'Kết thúc',
    color: '#7A7B7C',
  },
];

export enum CUSTOMER_CARE_STATUS {
  NEW = 0,
  CONTACTING = 1,
  CONFIRMED = 2,
  CANCELLED = 3,
}

export const ruleValidatePassword = {
  pattern: new RegExp(/(?=.*[0-9])(?=.*[!@#$%^&*])^.*$/),
  required: true,
  message:
    'Mật khẩu cần ít nhất 8 ký tự, trong đó có ít nhất 1 chữ số và 1 ký tự đặc biệt',
};

export const LIST_ROLES = [
  {
    name: 'AM Leader',
    code: 'AM_LEADER',
  },
  {
    name: 'AM',
    code: 'AM',
  },
  {
    name: 'Accountant',
    code: 'ACCOUNTANT',
  },
];

export const optionActive = [
  {label: 'Đang hoạt động', value: 0},
  {label: 'Ngừng hoạt động', value: 1},
];

export const LIST_POSITION = [
  {
    name: 'Công ty khu vực',
    code: 'REGION_COMPANY',
  },
  {
    name: 'Chi nhánh công ty',
    code: 'REGION_COMPANY_BRANCH',
  },
];

export const ACCOUNT_TYPE = [
  {
    value: 'account_operator',
    label: 'Tài khoản vận hành',
  },
  {
    value: 'account_user',
    label: 'Tài khoản người dùng',
  },
];

export const PERMISSIONS = {
  // Quản lý tài khoản
  viewAccount: 'view-account',

  // Danh sách tài khoản
  viewUser: 'view-user',
  createUser: 'create-user',
  updateUser: 'update-user',

  // Quản lý phân quyền
  viewPermissions: 'view-permissions',
  createRole: 'create-role',
  updateRole: 'update-role',

  // Danh sách tổ chức
  viewRC: 'view-rc',
  createRC: 'create-rc',
  updateRC: 'update-rc',

  // Quản lý đại lý
  viewAgent: 'view-agent',
  createAgent: 'create-agent',
  createCollaborator: 'create-collaborator',
  updateAgent: 'update-agent',

  // Quản lý CTV
  viewCollaborator: 'view-collaborator',
  updateCollaborator: 'update-collaborator',

  // Quản lý thông báo
  viewNotification: 'view-notification',

  // Quản lý sản phẩm
  viewProduct: 'view-product',
  createProduct: 'create-product',
  updateProduct: 'update-product',

  // Quản lý chiến dịch
  viewCampaign: 'view-campaign',
  createCampaign: 'create-campaign',
  updateCampaign: 'update-campaign',

  // CMS
  viewCMS: 'view-cms',
  createCMS: 'create-cms',
  updateCMS: 'update-cms',

  // Tài liệu bán hàng
  viewDocument: 'view-document',
  createDocument: 'create-document',
  updateDocument: 'update-document',

  // Quản lý đơn hàng
  viewOrder: 'view-order',
  createOrder: 'create-order',

  // Quản lý thanh toán
  viewPayment: 'view-payment',

  // Yêu cầu thanh toán
  updatePayment: 'update-payment',

  // Quản lý yêu cầu HT
  viewTicket: 'view-ticket',
  updateTicket: 'update-ticket',

  // Thống kê - Báo cáo
  viewReport: 'view-report',
};
