import {isUserHaveOneOfPermissions} from '@/utils';
import {TICKET_STATUS} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, DatePicker, Input, Row, Select} from 'antd';

type Props = {
  isReceived?: boolean;
  onChangeStatus?: (value: string) => void;
  onChangeInput?: (e: {
    target: {
      value: string;
    };
  }) => void;
  onChangeDate?: (_: any, value: string[]) => void;
  onCreateRequireSupport?: () => void;
};
export const Filter = ({
  isReceived,
  onChangeStatus,
  onChangeInput,
  onChangeDate,
  onCreateRequireSupport,
}: Props) => {
  return (
    <ProCard className="mb-2 px-2.5" bordered>
      <Row className="w-full">
        <Col lg={6} span={24} className="mr-2">
          <Input
            placeholder={'Nhập mã yêu cầu, tiêu đề'}
            className="w-full"
            onChange={onChangeInput}
            allowClear
          />
        </Col>
        <Col lg={6} span={24} className="mr-2 max-md:mt-3">
          <DatePicker.RangePicker
            allowClear
            placeholder={['Từ ngày', 'đến ngày']}
            className="w-full"
            format={'YYYY-MM-DD'}
            onChange={onChangeDate}
          />
        </Col>
        <Col lg={6} span={24} className="mr-2 max-md:mt-3">
          {isUserHaveOneOfPermissions([ROLES?.AGENT, ROLES.COLLABORATOR]) ||
          isReceived ? (
            <Select
              placeholder={'Trạng thái'}
              className="w-full"
              allowClear
              options={TICKET_STATUS}
              onChange={onChangeStatus}
            />
          ) : null}
        </Col>
        {isUserHaveOneOfPermissions([ROLES?.AGENT, ROLES.COLLABORATOR]) && (
          <div className="flex flex-1 justify-end max-md:mt-3">
            <Button
              key="button"
              icon={<PlusOutlined />}
              type="primary"
              onClick={onCreateRequireSupport}>
              Tạo yêu cầu hỗ trợ
            </Button>
          </div>
        )}
      </Row>
    </ProCard>
  );
};
