import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {DrawerForm} from '@ant-design/pro-components';
import {Col, Row} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle} from 'react';

export type OrderDetailModalRef = {
  open: (data?: OrderItemInterface) => void;
};

const OrderDetails = (_: any, ref: Ref<OrderDetailModalRef>) => {
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState<OrderItemInterface>();

  const infoOrders: any[] = [
    {
      id: 1,
      label: 'Mã đơn hàng:',
      content: !isNullOrEmpty(details?.code) ? details?.code : null,
      isBold: true,
    },
    {
      id: 2,
      label: 'Danh sách sản phẩm:',
      content: details?.orderItems,
      isBold: false,
    },
    {
      id: 2,
      label: 'Giá bán:',
      content: !isNullOrEmpty(details?.total)
        ? `${formatPrice(details?.total)} VNĐ`
        : null,
      isBold: false,
    },
    {
      id: 2,
      label: 'Tên khách hàng:',
      content: !isNullOrEmpty(details?.customerInfo?.customerName)
        ? details?.customerInfo?.customerName
        : null,
      isBold: false,
    },
    {
      id: 2,
      label: 'Số điện thoại:',
      content: !isNullOrEmpty(details?.customerInfo?.phone)
        ? details?.customerInfo?.phone
        : null,
      isBold: false,
    },
  ];

  const onclose = () => {
    setOpen(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          setDetails(data);
          setOpen(true);
        },
      };
    },
    []
  );

  return (
    <DrawerForm
      title={'Chi tiết đơn hàng'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      initialValues={{
        agentCommissionFee: 30,
        collaboratorCommissionFee: 70,
      }}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      submitter={false}>
      {infoOrders?.map((item, index) => (
        <Row key={item.id} gutter={24} className="mb-10">
          <Col span={8}>
            <p style={{fontWeight: 600}}>{item?.label}</p>
          </Col>
          {index === 1 ? (
            <>
              {item?.content?.map((it: any) => (
                <Col key={it?.id + 'id'} span={16}>
                  <p style={{fontWeight: item?.isBold ? 'bold' : undefined}}>
                    {it?.itemName}
                  </p>
                </Col>
              ))}
            </>
          ) : (
            <Col span={16}>
              <p style={{fontWeight: item?.isBold ? 'bold' : undefined}}>
                {item?.content}
              </p>
            </Col>
          )}
        </Row>
      ))}
    </DrawerForm>
  );
};
export const OderDetailModal = forwardRef(OrderDetails);
