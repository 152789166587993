import {UploadMultiImage} from '@/components/UploadMultiImage';
import AppContext from '@/context/appContext';
import {validateWhitespace} from '@/utils/helper';
import {
  DrawerForm,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import {Form} from 'antd';
import {UploadFile} from 'antd/lib';
import {
  Ref,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export type CreateModalRef = {
  setOpenModal: () => void;
  setCloseModal: () => void;
};

type Props = {
  createRequest: (params: CreateTicketParams) => void;
  onFileListChange: (idStr: string, url: string) => void;
  onCloseModal: () => void;
  onRemoveFile: (file: UploadFile) => void;
};

const ModalCreate = (
  {createRequest, onFileListChange, onCloseModal, onRemoveFile}: Props,
  ref: Ref<CreateModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const uploadRef = useRef<any>(null);
  const {appState} = useContext(AppContext);

  useImperativeHandle(ref, () => ({
    setOpenModal,
    setCloseModal,
  }));

  const setOpenModal = () => {
    setOpen(true);
  };

  const setCloseModal = () => {
    onClose();
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    onCloseModal?.();
    uploadRef?.current?.handleModalClose();
  };

  const onSubmit = async (values: any) => {
    createRequest?.(values);
  };

  return (
    <DrawerForm
      title={'Tạo yêu cầu hỗ trợ'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 450,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Gửi',
        },
      }}
      onFinish={onSubmit}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: false,
        onClose: onClose,
      }}
      initialValues={{
        fullName: appState?.userInfor?.fullname,
        phoneNumber: appState?.userInfor?.phoneNumber,
        email: appState?.userInfor?.email,
      }}>
      <div className="mb-3 font-bold text-[17px]">Thông tin chung</div>
      <ProFormText
        name="fullName"
        label="Họ và tên"
        placeholder={'Nhập họ và tên'}
        fieldProps={{
          maxLength: 25,
        }}
        rules={[
          {
            validator: validateWhitespace,
          },
        ]}
      />
      <ProFormText
        name="phoneNumber"
        label="Số điện thoại"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập số điện thoại !',
          },
          {
            validator: validateWhitespace,
          },
        ]}
        fieldProps={{
          maxLength: 10,
          placeholder: 'Nhập số điện thoại',
        }}
      />
      <ProFormText
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập email !',
          },
          {
            validator: validateWhitespace,
          },
        ]}
        fieldProps={{
          maxLength: 25,
          placeholder: 'Nhập email',
        }}
      />
      <div className="mb-3 font-bold text-[17px]">Yêu cầu hỗ trợ</div>
      <ProFormText
        name="title"
        label="Tiêu đề"
        placeholder={'Nhập tiêu đề'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tiêu đề !',
          },
          {
            validator: validateWhitespace,
          },
        ]}
        fieldProps={{
          maxLength: 255,
          placeholder: 'Nhập tiêu đề',
        }}
      />
      <ProFormTextArea
        name="content"
        label="Yêu cầu"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập yêu cầu !',
          },
          {
            validator: validateWhitespace,
          },
        ]}
        fieldProps={{
          placeholder: 'Ghi chi tiết yêu cầu hỗ trợ và lý do',
        }}
      />
      <UploadMultiImage
        ref={uploadRef}
        onFileListChange={onFileListChange}
        onRemoveFile={onRemoveFile}
      />
    </DrawerForm>
  );
};
export const CreateModal = forwardRef(ModalCreate);
