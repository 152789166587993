import {DrawerForm} from '@ant-design/pro-components';
import {Form, Input, Select, message} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle, useState} from 'react';
import {formatPrice} from '@/utils/stringUtils';
import {LayoutDetails} from '../LayoutDetails';
import {LayoutGeneral} from '../LayoutGeneralInfor';
import {PROVIDED_TYPE} from '@/utils/enum';
import {formatted, validateWhitespace} from '@/utils/helper';

export type CreateModalRef = {
  open: (data?: ProductItemInterface | undefined) => void;
};

export type Props = {
  isEdit?: boolean;
  suppliers: SuppliersInterface[];
  updateProduct: (
    params: ProductParamsInterface,
    id: number | undefined,
    onReload: () => void
  ) => void;
  createProduct: (params: ProductParamsInterface, onReload: () => void) => void;
  listCourse: Course[];
  itemWithCodeMBF: any;
};

const ModalCreate = (
  {
    isEdit,
    updateProduct,
    createProduct,
    suppliers,
    listCourse,
    itemWithCodeMBF,
  }: Props,
  ref: Ref<CreateModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [supplier, setSupplier] = useState<{
    supplierId?: number;
    supplierCode?: string;
    productType?: string;
  }>();
  const [dataDetails, setDataDetails] = useState<
    ProductItemInterface | undefined
  >();
  const [selected, setSelected] = useState<any>();

  const handleFillData = (data: ProductItemInterface | undefined) => {
    if (isEdit) {
      setSupplier(prev => ({
        ...prev,
        productType: data?.type,
      }));
      if (data?.type === PROVIDED_TYPE.PACK_DATA) {
        form.setFieldsValue({
          type: data?.supplierId,
          code: data?.code,
          name: data?.name,
          price: formatPrice(data?.price),
          maxCommissionShare: formatPrice(Number(data?.maxCommissionShare)),
          cycle: data?.cycle,
          calling: data?.calling,
          sms: data?.sms,
          dataTraffic: data?.dataTraffic,
          description: data?.description,
        });
      }
      if (data?.type === PROVIDED_TYPE.COURSE) {
        form.setFieldsValue({
          type: data?.supplierId,
          productOriginId: data?.productOriginId,
          name: data?.name,
          price: formatPrice(data?.price),
          maxCommissionShare: formatPrice(Number(data?.maxCommissionShare)),
        });
      }
    } else {
      form.resetFields();
      form?.setFieldsValue({
        type: itemWithCodeMBF?.id,
      });
      setSupplier(prev => ({
        ...prev,
        supplierId: itemWithCodeMBF?.id,
        supplierCode: itemWithCodeMBF?.code,
        productType: itemWithCodeMBF?.type,
      }));
    }
  };

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit, itemWithCodeMBF]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          handleFillData(data);
          setDataDetails(data);
          setOpen(true);
        },
      };
    },
    [isEdit, itemWithCodeMBF]
  );

  const onSubmit = async (values: ProductParamsInterface) => {
    const course_params = {
      ...values,
      code: selected?.code,
      duration: selected?.duration,
      price: values?.price?.replace(/\D/g, ''),
      maxCommissionShare: (values?.maxCommissionShare as string)?.replace(
        /\D/g,
        ''
      ),
      supplierId: isEdit ? dataDetails?.supplierId : supplier?.supplierId,
      supplierCode: isEdit ? dataDetails?.supplierCode : supplier?.supplierCode,
      type: isEdit ? dataDetails?.type : supplier?.productType,
    };

    const pack_params = {
      ...values,
      price: (values?.price as string)?.replace(/\D/g, ''),
      maxCommissionShare: (values?.maxCommissionShare as string)?.replace(
        /\D/g,
        ''
      ),
      supplierId: isEdit ? dataDetails?.supplierId : supplier?.supplierId,
      supplierCode: isEdit ? dataDetails?.supplierCode : supplier?.supplierCode,
      type: isEdit ? dataDetails?.type : supplier?.productType,
    };

    const paramsToUse =
      supplier?.productType === PROVIDED_TYPE.PACK_DATA
        ? pack_params
        : course_params;

    const maxCommissionShareValue = values?.maxCommissionShare
      ?.toString()
      .replace(/,/g, '');
    const priceValue = values?.price?.toString().replace(/,/g, '');

    if (Number(maxCommissionShareValue) > Number(priceValue)) {
      message.error('Hoa hồng phải nhỏ hơn doanh thu');
    } else {
      if (isEdit) {
        updateProduct(paramsToUse, dataDetails?.id, onReload);
      } else {
        createProduct(paramsToUse, onReload);
      }
    }
  };

  const onChangeSelectProvided = (_values: string, options: any) => {
    setSupplier(prev => ({
      ...prev,
      supplierId: options.supplierId,
      supplierCode: options?.supplierCode,
      productType: options?.type,
    }));
    const fieldsToReset = [
      'name',
      'code',
      'price',
      'maxCommissionShare',
      'cycle',
      'calling',
      'sms',
      'dataTraffic',
      'description',
      'productOriginId',
    ];
    form.resetFields(fieldsToReset);
  };

  const onChangeSelectPackage = (_values: string, options: any) => {
    form.setFieldsValue({
      name: options.course.name,
      price: formatPrice(options.price),
    });
    setSelected(options);
  };

  const onReload = () => {
    setOpen(false);
  };

  const onclose = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <DrawerForm
      title={isEdit ? 'Chỉnh sửa sản phẩm' : 'Thêm mới sản phẩm'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      onFinish={onSubmit}>
      <Form.Item
        label="Nhà cung cấp"
        name="type"
        className="mb-3"
        rules={[{required: true, message: 'Vui lòng chọn nhà cung cấp !'}]}>
        <Select
          className="w-[calc(100%)]"
          placeholder={'Chọn nhà cung cấp'}
          disabled={isEdit ? true : false}
          options={suppliers.map(item => ({
            value: item.id,
            label: item.name,
            supplierId: item.id,
            supplierCode: item.code,
            type: item.type,
          }))}
          onChange={onChangeSelectProvided}
        />
      </Form.Item>
      {supplier?.productType === PROVIDED_TYPE.PACK_DATA ? (
        <>
          <LayoutGeneral form={form} isEdit={isEdit} />
          <LayoutDetails />
        </>
      ) : (
        <>
          <Form.Item
            label="Danh sách packages MomEdu:"
            name="productOriginId"
            className="mb-4"
            rules={[
              {required: true, message: 'Vui lòng chọn packages MomEdu !'},
            ]}>
            <Select
              allowClear
              className="w-[calc(100%)]"
              placeholder="Chọn khoá học"
              options={listCourse.map(item => ({
                value: item.id,
                label: item.name,
                code: item.code,
                duration: item.duration,
                course: item.course,
                price: item.actualPrice,
              }))}
              disabled={isEdit}
              onChange={onChangeSelectPackage}
            />
          </Form.Item>
          <Form.Item
            label="Tên gói:"
            name="name"
            rules={[
              {required: true, message: 'Vui lòng nhập tên gói !'},
              {
                validator: validateWhitespace,
              },
            ]}
            className="mb-4">
            <Input placeholder="Nhập tên gói..." className="w-full" />
          </Form.Item>
          <Form.Item
            label="Giá bán (VNĐ):"
            name="price"
            rules={[{required: true, message: 'Vui lòng nhập giá bán !'}]}
            className="mb-4">
            <Input
              placeholder="Nhập giá bán..."
              className="w-[calc(100%)]"
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Hoa hồng (VNĐ):"
            name="maxCommissionShare"
            rules={[{required: true, message: 'Vui lòng nhập hoa hồng !'}]}
            className="mb-4">
            <Input
              placeholder="Hoa hồng..."
              className="w-[calc(100%)]"
              onChange={(e: {target: {value: string}}) => {
                form.setFieldsValue({
                  maxCommissionShare: formatted(e.target?.value),
                });
              }}
            />
          </Form.Item>
        </>
      )}
    </DrawerForm>
  );
};

export const CreateModal = forwardRef(ModalCreate);
