import {Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {ReportCampaignFilter} from './ReportCampaignFilter';
import moment from 'moment';
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE,
  PAGE_SIZE_LIST,
} from '@/utils/constants';
import revenueService from '@/network/services/report';
import {RESPONSE_CODE} from '@/network/constants';
import columns from './TableReportCampaignColumns';
import dayjs from 'dayjs';
import {convertDate} from '@/utils';
import {useWindowSize} from '@/hooks/useWindowSize';
import {ReportCampaignDetailModal} from './ReportCampaignDetails';

type Props = {
  isCollaborator: boolean;
};

export const ReportCampaignTab: React.FC<Props> = ({isCollaborator}) => {
  const windowSize = useWindowSize();
  const reportCampaignDetailModalRef = React.useRef<any>();
  const [table, setTable] = useState<ReportCampaignItem[]>([]);
  const [metaData, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [filter, setFilter] = useState<DefaultFiltersInterface>({
    size: 10,
    page: 0,
  });
  const [params, setParams] = useState<ReportParams>({
    fromDate: dayjs(
      moment().startOf('month').startOf('day').format(DEFAULT_FORMAT_DATE),
      DEFAULT_FORMAT_DATE
    ),
    toDate: dayjs(
      moment().endOf('day').format(DEFAULT_FORMAT_DATE),
      DEFAULT_FORMAT_DATE
    ),
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isMobile = windowSize?.width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;

  const onChangeRangeDate = (values: any) => {
    if (values) {
      setParams({
        fromDate: values[0].startOf('day'),
        toDate: values[1].endOf('day'),
      });
    } else {
      setParams({
        fromDate: undefined,
        toDate: undefined,
      });
    }
    setFilter(prevState => ({
      ...prevState,
      page: 0,
    }));
  };

  const onChangeDays = (time: 'week' | 'month') => {
    setParams({
      fromDate: dayjs(
        moment().startOf(time).startOf('day').format(DEFAULT_FORMAT_DATE),
        DEFAULT_FORMAT_DATE
      ),
      toDate: dayjs(
        moment().endOf('day').format(DEFAULT_FORMAT_DATE),
        DEFAULT_FORMAT_DATE
      ),
    });
    setFilter(prevState => ({
      ...prevState,
      page: 0,
    }));
  };

  const onSelectWeek = () => {
    onChangeDays('week');
    setFilter(prevState => ({
      ...prevState,
      page: 0,
    }));
  };

  const onSelectMonth = () => {
    onChangeDays('month');
    setFilter(prevState => ({
      ...prevState,
      page: 0,
    }));
  };

  const onSizeChange = (page: number, size: number) => {
    setFilter(prevState => ({
      ...prevState,
      page: Math.max(page - 1, 0),
      size: size,
    }));
  };

  const fetchReportCampaign = async (params: ReportTableParams) => {
    const response = await revenueService.reportCampaign(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data;
      setTable(data);
      setMetadata(pagination);
    }
  };

  useEffect(() => {
    fetchReportCampaign({
      ...filter,
      fromDate: convertDate(params.fromDate),
      toDate: convertDate(params.toDate),
      isCollaborator: isCollaborator,
    });
  }, [params, filter?.page, isCollaborator]);

  const handleReportCampaignDetail = async (data: ReportCampaignItem) => {
    setLoading(true);
    const response = await revenueService.reportCampaignDetails(
      data?.referCollaboratorId
    );
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      reportCampaignDetailModalRef?.current?.open(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <ReportCampaignFilter
        rangeDate={params}
        onChangeRangeDate={onChangeRangeDate}
        onSelectWeek={onSelectWeek}
        onSelectMonth={onSelectMonth}
      />
      <Table
        className="mt-4"
        dataSource={table}
        loading={loading}
        columns={columns(
          filter?.page,
          isCollaborator,
          isMobile,
          handleReportCampaignDetail
        )}
        scroll={{x: 800}}
        pagination={{
          current: filter?.page + 1,
          total: metaData?.total,
          defaultPageSize: filter?.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onSizeChange,
        }}
      />
      <ReportCampaignDetailModal ref={reportCampaignDetailModalRef} />
    </div>
  );
};
