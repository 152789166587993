import {ProColumns} from '@ant-design/pro-components';
import moment from 'moment';
import './styles.scss';

const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY | HH:mm:ss';

const getColumnsConfig = (actionRef?: any, onDetail?: (id: number) => void) => {
  const getStatusColor = (enable: boolean) => {
    if (enable) {
      return <div className="showStatus">Đang hiển thị</div>;
    } else {
      return <div className="hideStatus">Không hiển thị</div>;
    }
  };

  const columns: ProColumns<any>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tiêu đề bài viết',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '20%',
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'categoryName',
      key: 'categoryName',
      align: 'center',
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      align: 'center',
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.modifiedDate).format(DATE_FORMAT_DEFAULT);
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'enable',
      key: 'enable',
      align: 'center',
      render: (_, record) => {
        return (
          <div className="flex w-full justify-center">
            {getStatusColor(record.enable)}
          </div>
        );
      },
    },
    {
      title: 'Lượt xem',
      dataIndex: 'views',
      key: 'views',
      align: 'center',
      width: 120,
    },
    {
      title: 'Thao tác',
      align: 'center',
      render: (_, record) => {
        return (
          <div onClick={onDetail?.bind(null, record?.id)} className="txtDetail">
            Xem chi tiết
          </div>
        );
      },
    },
  ];

  return columns;
};

export default getColumnsConfig;
