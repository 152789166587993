import {ruleValidatePassword} from '@/utils/constants';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import {ProFormText} from '@ant-design/pro-components';

function SingUp() {
  return (
    <>
      <ProFormText
        fieldProps={{
          size: 'large',
          prefix: <MailOutlined className={'prefixIcon'} />,
          maxLength: 255,
        }}
        name="email"
        placeholder={'Email'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập Email',
          },
          // {
          //   pattern:
          //     /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
          //   message: 'Số điện thoại không hợp lệ',
          // },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Email không hợp lệ',
          },
        ]}
        hasFeedback
      />
      <ProFormText.Password
        name="password"
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        placeholder="Mật khẩu"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mật khẩu',
          },
          ruleValidatePassword,
        ]}
        hasFeedback
      />
      <ProFormText.Password
        name="confirmPassword"
        dependencies={['password']}
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        placeholder="Xác nhận mật khẩu"
        rules={[
          {
            required: true,
            message: 'Vui lòng xác nhận lại mật khẩu',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Mật khẩu không trùng khớp.'));
            },
          }),
        ]}
        hasFeedback
      />
    </>
  );
}

export default SingUp;
