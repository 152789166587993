import {APPLICATION_ACTION_TYPE} from '@/context/action';
import AppContext from '@/context/appContext';
import {usePayment} from '@/hooks/usePayments';
import {useWindowSize} from '@/hooks/useWindowSize';
import {RESPONSE_CODE} from '@/network/constants';
import {getInfoUser} from '@/network/services/accounts';
import {isUserHaveOneOfPermissions} from '@/utils';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {ProTable} from '@ant-design/pro-components';
import {message} from 'antd';
import {saveAs} from 'file-saver';
import moment from 'moment';
import React, {useContext, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {UpdateInfo} from './ModalUpdateInfo';
import {PaymentsFilter} from './PaymentsFilter';
import getColumnsConfig from './TableColumns';

export const Payments: React.FC = () => {
  const requestWithdrawSuccess = () => {
    closeTopupRequestPayment?.();
    getInfo();
  };

  let navigate = useNavigate();

  useEffect(() => {
    isUserHaveOneOfPermissions([ROLES.AM_LEADER, ROLES.AM]) && navigate('/*');
  }, []);

  const {dispatch, appState} = useContext(AppContext);

  const getInfo = async () => {
    const info = await getInfoUser();
    if (info) {
      dispatch({
        type: APPLICATION_ACTION_TYPE.UPDATE_USER_INFOR,
        payload: info,
      });
    }
  };

  const callBackUpdate = () => {
    getInfo();
  };

  const isUser = isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]);

  const {
    statePayment,
    requestWithdraw,
    getListPayment,
    onPageChange,
    onFilterStatus,
    onFilterDates,
    exportPayments,
    cancelPayment,
    actionRef,
    openTopup,
    openTopupRequestPayment,
    closeTopupRequestPayment,
    updateInfo,
    handleOpenModalUpdateInfor,
  } = usePayment(requestWithdrawSuccess, isUser, callBackUpdate);

  useEffect(() => {
    !isUserHaveOneOfPermissions([ROLES.AM_LEADER, ROLES.AM]) &&
      getListPayment(isUser);
  }, []);
  const windowSize = useWindowSize();

  const columns = getColumnsConfig(
    actionRef,
    cancelPayment,
    statePayment?.loadingCancel
  );

  const onExport = async () => {
    const res = await exportPayments();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const fileName = `Payments_Data_Export_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(res?.data, fileName);
      getListPayment(isUser);
    } else {
      const reader = new FileReader();
      reader.onload = function (event: any) {
        message.error(event?.target?.result ?? 'Có lỗi xảy ra!!');
      };
      reader.readAsText(res?.data);
    }
  };

  const renderContent = useMemo(() => {
    return (
      <ProTable<any>
        loading={statePayment?.loading}
        className={'custom-table'}
        scroll={{x: 1000, y: windowSize?.height - 350}}
        rowKey="id"
        actionRef={actionRef}
        dataSource={statePayment?.payments}
        columns={columns}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <PaymentsFilter
            onChangeStatus={onFilterStatus}
            isUser={isUser}
            onChangeDates={onFilterDates}
            onExport={onExport}
            callBackUpdate={getListPayment?.bind(null, isUser)}
            isEnablePayment={appState?.userInfor?.isEnablePayment}
            onRequest={requestWithdraw}
            topupRequestPayment={statePayment?.topupRequestPayment}
            openTopupRequestPayment={openTopup}
            topupRequestInfor={statePayment?.topupRequestInfor}
            handleOpenModalUpdateInfor={handleOpenModalUpdateInfor}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: statePayment?.filters.page + 1,
          total: statePayment?.metadata?.total,
          defaultPageSize: statePayment?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
    );
  }, [statePayment?.payments, columns]);

  return (
    <div className="flex flex-col">
      <UpdateInfo
        ref={updateInfo}
        userInfo={appState?.userInfor}
        callbackFullInfo={openTopupRequestPayment}
      />
      {renderContent}
    </div>
  );
};
