import {AxiosResponse} from 'axios';
import {getRequestUrl} from '../utils';
import {ORGANIZATIONS} from '../apiUrl';
import {NetWork} from '..';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

// Tạo mới chi nhánh
const createBranch = (params: {
  name: string;
  regionCompanyId: number;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.branch,
  });
  return NetWork.post(url, params);
};

// Tạo mới công ty khu vực
const createRegionCompany = (params: {
  name: string;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company);
  return NetWork.post(url, params);
};

// List công ty khu vực
const getListRegion = (): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company);
  return NetWork.get(url);
};

// Get công ty khu vực theo ID
const getListCompanyById = (id?: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: id,
  });
  return NetWork.get(url);
};

// Lấy chi nhánh công ty
const getListRegionCompany = (): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.branch,
  });
  return NetWork.get(url);
};

// Lấy chi nhánh công ty theo ID
const getListRegionCompanyById = (id?: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.branch,
    partial: id,
  });
  return NetWork.get(url);
};

// Lấy cấu trúc
const getStructure = (params?: {
  page?: number;
  size?: number;
  status?: number;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.structure,
  });
  deleteParamsNotUsing(params);
  return NetWork.get(url, params);
};

const getStructureForSearch = (): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.structure_for_search,
  });
  return NetWork.get(url);
};

// Sửa 1 chi nhánh
const editRegion = (
  params: {name: string; note: string},
  id?: number
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: ORGANIZATIONS.branch,
    partial: id,
  });
  return NetWork.patch(url, params);
};

// Sửa 1 công ty khu vực
const editRegionCompany = (
  params: {name: string; note: string},
  id?: number
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(ORGANIZATIONS.region_company, {
    parentId: id,
  });
  return NetWork.patch(url, params);
};

export const organizationsServices = {
  createBranch,
  createRegionCompany,
  getListRegion,
  getListCompanyById,
  getListRegionCompany,
  getListRegionCompanyById,
  getStructure,
  editRegion,
  editRegionCompany,
  getStructureForSearch,
};
