import {useSearch} from '@/hooks/useSearch';
import {convertSuggestedPackages, isNullOrEmpty} from '@/utils/stringUtils';
import {Table, Typography} from 'antd';
import React from 'react';
import getColumnsConfig from './Columns';
import {Header} from './Header';

export const Search: React.FC = () => {
  const {handleOnSearch, onChangeInput, onSelectSupplier, state} = useSearch();
  const {columnsActive, columnsSuggest} = getColumnsConfig();
  return (
    <div className="flex flex-col">
      <Header
        handleOnSearch={handleOnSearch}
        onChangeText={onChangeInput}
        onSelectSupplier={onSelectSupplier}
        suppliers={state.suppliers}
        search={state?.searchKeyword}
        loading={state?.loading}
      />
      <Typography className="mb-3 mt-5 text-lg font-semibold">
        Danh sách gói cước đang sử dụng
      </Typography>
      <Table
        columns={columnsActive}
        pagination={false}
        rowKey="id"
        dataSource={state?.activePackages}
        locale={{
          emptyText: 'Thuê bao không có thông tin gói đang sử dụng !',
        }}
      />
      <Typography className="mb-3 mt-5 text-lg font-semibold">
        Danh sách gói cước có thể đăng kí
      </Typography>

      <Table
        columns={columnsSuggest}
        pagination={false}
        rowKey="id"
        dataSource={convertSuggestedPackages(
          state?.suggestedPackages || {}
        )?.reverse()}
        locale={{
          emptyText:
            isNullOrEmpty(state?.suggestedPackages) &&
            'Không tìm thấy gói cước có thể đăng ký !',
        }}
      />
    </div>
  );
};
