import {AxiosResponse} from 'axios';
import {getRequestUrl} from '../utils';
import {NetWork} from '..';
import {REQUIRE_SUPPORT} from '../apiUrl';

const getListTickets = (params: {
  query?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
  size?: number;
  total?: number;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets);
  return NetWork.get(url, params);
};

const createTickets = (
  params: TicketResponseView
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets);
  return NetWork.post(url, params);
};

const getTicketsByID = (id?: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets, {
    parentId: id,
  });
  return NetWork.get(url);
};

const commentTickets = (
  params?: {
    content?: string;
    images: string[];
  },
  id?: number
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets, {
    parentId: id,
    partial: REQUIRE_SUPPORT.comment,
  });
  return NetWork.patch(url, params);
};

const closeTicket = (id?: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets, {
    parentId: id,
    partial: REQUIRE_SUPPORT.close,
  });
  return NetWork.patch(url);
};

const receiveTicket = (id?: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(REQUIRE_SUPPORT.tickets, {
    parentId: id,
    partial: REQUIRE_SUPPORT.receive,
  });
  return NetWork.patch(url);
};

export const ticketsServices = {
  getListTickets,
  createTickets,
  getTicketsByID,
  commentTickets,
  closeTicket,
  receiveTicket,
};
