import {FC, useState} from 'react';
import ItemModule from './ItemModule';

type Props = {
  module: ModulePermission;
  subModules: ModulePermission[];
  onSelectPermission: (items: (ItemPermission | undefined)[]) => void;
};

const ModulePermission: FC<Props> = ({
  module,
  subModules,
  onSelectPermission,
}) => {
  const [expand, setExpand] = useState<boolean>(true);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <div>
      <ItemModule
        item={module}
        onSelectPermission={onSelectPermission}
        isHaveExpand={subModules.length > 0}
        onExpand={onExpand}
        expand={expand}
      />
      <div className={expand ? '' : 'hidden'}>
        {subModules?.map(item => (
          <ItemModule
            isSubModule
            item={item}
            onSelectPermission={onSelectPermission}
            key={item.id + 'item-module'}
          />
        ))}
      </div>
    </div>
  );
};

export default ModulePermission;
