import {useBalanceHistory} from '@/hooks/useBalanceHistory';
import {useWindowSize} from '@/hooks/useWindowSize';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {ProTable} from '@ant-design/pro-components';
import React, {useEffect, useMemo} from 'react';
import getColumnsConfig from './TableColumns';
import {BalanceHistoryFilter} from './BalanceHistoryFilter';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {useNavigate} from 'react-router-dom';

export const BalanceHistory: React.FC = () => {
  const {
    stateBalanceHistory,
    getListBalanceHistory,
    onPageChange,
    onFilterDates,
    actionRef,
  } = useBalanceHistory();

  const dontHavePermission = isUserHaveOneOfPermissions([
    ROLES.AM_LEADER,
    ROLES.AM,
    ROLES.ADMIN,
    ROLES.ACT,
  ]);

  useEffect(() => {
    !dontHavePermission && getListBalanceHistory();
  }, []);
  let navigate = useNavigate();

  useEffect(() => {
    dontHavePermission && navigate('/*');
  }, []);

  const windowSize = useWindowSize();

  const columns = getColumnsConfig(actionRef);

  const renderContent = useMemo(() => {
    return (
      <ProTable<any>
        loading={stateBalanceHistory?.loading}
        className={'custom-table'}
        scroll={{x: 1200, y: windowSize?.height - 350}}
        rowKey="id"
        actionRef={actionRef}
        dataSource={stateBalanceHistory?.balanceHistory}
        columns={columns}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <BalanceHistoryFilter onChangeDates={onFilterDates} />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: stateBalanceHistory?.filters.page + 1,
          total: stateBalanceHistory?.metadata?.total,
          defaultPageSize: stateBalanceHistory?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
    );
  }, [stateBalanceHistory?.balanceHistory, columns]);

  return <div className="flex flex-col">{renderContent}</div>;
};
