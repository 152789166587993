import React from 'react';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {ProductsFilter} from './ProductsFilter';
import {CreateModal} from './ModalCreate';
import {ProTable} from '@ant-design/pro-components';
import {useProducts} from '@/hooks/useProduct';
import getColumnsConfig from './TableColumns';
import {useWindowSize} from '@/hooks/useWindowSize';
import {DetaillModal} from './ModalDetail';

export const Products: React.FC = () => {
  const {
    stateProducts,
    actionRef,
    createRef,
    detailRef,
    handleEditProduct,
    handleDetailProduct,
    handleAddProduct,
    createProduct,
    updateProduct,
    handleSearch,
    handleStatus,
    onFilterProvided,
    hideProduct,
    contextHolder,
    onPageChange,
    handleCreateLink,
  } = useProducts();
  const windowSize = useWindowSize();

  const columns = getColumnsConfig(
    handleEditProduct,
    handleDetailProduct,
    actionRef,
    hideProduct,
    handleCreateLink
  );

  const itemWithCodeMBF = stateProducts?.suppliers.find(
    item => item.code === 'MBE'
  );

  return (
    <div className="flex flex-col">
      <ProTable<any>
        className={'custom-table'}
        scroll={{x: 1500, y: windowSize?.height - 350}}
        rowKey="id"
        dataSource={stateProducts?.products}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <ProductsFilter
            onChange={e => handleSearch(e.target.value)}
            onChangeStatus={(value: number) => handleStatus(value)}
            onAddProduct={handleAddProduct}
            suppliers={stateProducts?.suppliers}
            onChangeSelectProvided={(_values: string, options: any) =>
              onFilterProvided(options?.value)
            }
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: stateProducts?.filters.page + 1,
          total: stateProducts?.metadata?.total,
          defaultPageSize: stateProducts?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <CreateModal
        ref={createRef}
        suppliers={stateProducts?.suppliers}
        isEdit={stateProducts?.isEdit}
        createProduct={createProduct}
        updateProduct={updateProduct}
        listCourse={stateProducts?.listCourse}
        itemWithCodeMBF={itemWithCodeMBF}
      />
      <DetaillModal
        ref={detailRef}
        listCourse={stateProducts?.listCourse}
        suppliers={stateProducts?.suppliers}
      />
      {contextHolder}
    </div>
  );
};
