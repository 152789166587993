import {Button, Col, DatePicker, Input, Row} from 'antd';
import {ProCard} from '@ant-design/pro-components';
import {ROLES} from '@/utils/enum';
import {isUserHaveOneOfPermissions} from '@/utils';
import {PlusOutlined} from '@ant-design/icons';
import './styles.scss';
import { PERMISSIONS } from '@/utils/constants';

type Props = {
  onChange: (e: {
    target: {
      value: string;
    };
  }) => void;
  onChangeStartDate: (_: any, value: string[]) => void;
  onChangeEndDate: (_: any, value: string[]) => void;
  onAddCampaign: () => void;
};

export const CampaignFilter = ({
  onChange,
  onChangeStartDate,
  onChangeEndDate,
  onAddCampaign,
}: Props) => {
  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row className="w-full">
        <Col lg={6} span={24} className="mr-2">
          <Input.Search
            placeholder="Nhập tên chiến dịch..."
            allowClear
            onChange={onChange}
          />
        </Col>

        {/* {isUserHaveOneOfPermissions([
          ROLES.ADMIN,
          ROLES.AM_LEADER,
          ROLES.AM,
          ROLES.COLLABORATOR,
        ]) && (
          <> */}
        <Col lg={6} span={24} className="mr-2 max-md:mt-3">
          <DatePicker.RangePicker
            onChange={onChangeStartDate}
            className="w-full"
            format={'YYYY-MM-DD'}
            placeholder={['Bắt đầu từ ngày', 'đến ngày']}
          />
        </Col>
        <Col lg={6} span={24} className="mr-2 max-md:mt-3">
          <DatePicker.RangePicker
            onChange={onChangeEndDate}
            className="w-full"
            format={'YYYY-MM-DD'}
            placeholder={['Kết thúc từ ngày', 'đến ngày']}
          />
        </Col>
        {/* </>
        )} */}
        <div className="flex flex-1 justify-end max-md:mt-3">
          {isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER], PERMISSIONS.createCampaign) && (
            <Button
              key="button"
              icon={<PlusOutlined />}
              onClick={onAddCampaign}
              type="primary">
              Thêm mới chiến dịch
            </Button>
          )}
        </div>
      </Row>
    </ProCard>
  );
};
