import { CreateDocumentModalRef } from '@/containers/Documents/CreateDocumentModal';
import { ModalDetailDocumentRef } from '@/containers/Documents/ModalDetail';
import {RESPONSE_CODE} from '@/network/constants';
import {documentServices} from '@/network/services/documents';
import {DEFAULT_PAGE_SIZE} from '@/utils/constants';
import {ActionType} from '@ant-design/pro-components';
import React, {RefObject, useEffect, useRef, useState} from 'react';

interface UseDocumentInterface {
  documents: DocumentItemInterface[];
  metadata: MetadataInterface | undefined;
  filters: DocumentParamsInterface;
  loading: boolean;
  actionRef: React.Ref<ActionType | undefined>;
  createModalRef: RefObject<CreateDocumentModalRef>;
  modalDetailRef: RefObject<ModalDetailDocumentRef>;
  onCreateDocument: () => void;
  onPageChange: (page: number, size: number) => void;
  onChangeText: (value: string) => void;
  onReload: () => void;
  onSearch: () => void;
}

const defaultFilters = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  query: '',
};

export const useDocuments = (): UseDocumentInterface => {
  const [documents, setDocuments] = useState<DocumentItemInterface[]>([]);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [filters, setFilters] =
    useState<DocumentParamsInterface>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(true);
  const actionRef = useRef<ActionType | undefined>(undefined);
  const createModalRef = useRef<CreateDocumentModalRef>(null);
  const modalDetailRef = useRef<ModalDetailDocumentRef>(null);

  const fetchDocuments = async (params: DocumentParamsInterface) => {
    const response = await documentServices.get(params);
    if (response.status === RESPONSE_CODE.SUCCESS) {
      const {data, ...metadata} = response?.data;
      setDocuments(data);
      setMetadata(metadata);
    }
    setLoading(false);
  };

  const onCreateDocument = () => {
    createModalRef.current?.open();
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setLoading(true);
    setFilters((prev: DocumentParamsInterface) => ({
      ...prev,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const onChangeText = (value: string) => {
    setFilters((prev: DocumentParamsInterface) => ({
      ...prev,
      query: value,
      page: 0,
    }));
  };

  const onReload = () => {
    setLoading(true);
  };

  const onSearch = () => {
    setLoading(true);
    setFilters((prev: DocumentParamsInterface) => ({
      ...prev,
      page: 0,
    }));
  };

  useEffect(() => {
    if (loading) {
      fetchDocuments(filters);
    }
  }, [filters, loading]);

  return {
    documents,
    metadata,
    filters,
    loading,
    actionRef,
    createModalRef,
    modalDetailRef,
    onCreateDocument,
    onPageChange,
    onChangeText,
    onReload,
    onSearch,
  };
};
