import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {IMPORT_EXPORT} from '../apiUrl';
import {getRequestUrl} from '../utils';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

const getListMO = (params: {
  size: number;
  page: number;
  fromDate?: string;
  toDate?: string;
  type?: string;
  action?: string;
  query?: string;
}): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(
    IMPORT_EXPORT.BASE,
    {
      parentId: IMPORT_EXPORT.LIST,
    },
    params
  );
  return NetWork.get(url);
};

export const moFileServices = {
  getListMO,
};
