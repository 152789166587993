import {CreateModalRef} from '@/containers/Notifications/ModalCreate';
import {RESPONSE_CODE} from '@/network/constants';
import {notificationServices} from '@/network/services/notifications';
import {ActionType} from '@ant-design/pro-components';
import {useEffect, useRef, useState} from 'react';

type UseNotificationType = {
  data: any;
  loading: boolean;
  filters: SchedulesFilter;
  metadata: MetadataInterface | undefined;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  modalCreateRef: any;
  onPageChange: (page: number, size: number) => void;
  onOpenModal: () => void;
  onChangeDates: (_: any, value?: string[]) => void;
  onSelectObject: (value: TargetType) => void;
  onSelectStatus: (value: 0 | 1 | undefined) => void;
};

export const useNotifications = (): UseNotificationType => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [filters, setFilters] = useState<SchedulesFilter>({
    page: 0,
    size: 20,
  });
  const modalCreateRef = useRef<CreateModalRef | undefined>(undefined);
  const actionRef = useRef<ActionType | undefined>(undefined);

  const fetchSchedules = async (params: SchedulesFilter) => {
    if (!loading) {
      setLoading(true);
    }
    const response = await notificationServices.getSchedules(params);
    if (response.status === RESPONSE_CODE.SUCCESS) {
      const {page, size, total, totalPages, data} = response?.data;
      setMetadata({page, size, total, totalPages});
      setData(data);
    }
    setLoading(false);
  };

  const onOpenModal = () => {
    modalCreateRef?.current?.open();
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setFilters(prev => ({...prev, page: mPage, size: mSize}));
  };

  const onChangeDates = (values: any) => {
    setFilters(prev => ({
      ...prev,
      fromDate: values?.[0].format('YYYY-MM-DD'),
      toDate: values?.[1].format('YYYY-MM-DD'),
    }));
  };

  const onSelectObject = (value: TargetType) => {
    setFilters(prev => ({...prev, targetType: value}));
  };

  const onSelectStatus = (value: 0 | 1 | undefined) => {
    setFilters(prev => ({...prev, status: value}));
  };

  useEffect(() => {
    fetchSchedules(filters);
  }, [filters]);

  return {
    data,
    filters,
    loading,
    metadata,
    actionRef,
    modalCreateRef,
    onPageChange,
    onOpenModal,
    onChangeDates,
    onSelectObject,
    onSelectStatus,
  };
};
