import {DEFAULT_REPORT_PAGE_SIZE} from '@/utils/constants';
import {formatPrice} from '@/utils/stringUtils';

export default (page: number) => {
  return [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (_data: any, _report: any, index: number) =>
        page * DEFAULT_REPORT_PAGE_SIZE + index + 1,
    },
    {
      title: 'Đại lý',
      dataIndex: 'agentName',
      key: 'agentName',
    },
    {
      title: 'Tổng số đơn hàng',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      align: 'center',
      render: (value: number) => formatPrice(value),
    },
    {
      title: 'Đơn hàng thành công',
      dataIndex: 'totalOrderSuccess',
      key: 'totalOrderSuccess',
      align: 'center',
      render: (value: number) => formatPrice(value),
    },
    {
      title: 'Đơn hàng chưa thành công',
      dataIndex: 'totalOrderNotSuccess',
      key: 'totalOrderNotSuccess',
      align: 'center',
      render: (value: number) => formatPrice(value),
    },
  ];
};
