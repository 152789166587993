import { ProCard } from '@ant-design/pro-components';
import { Col, DatePicker, Row } from 'antd';
import './styles.scss';

type Props = {
  onChangeDates: (_: any, value: string[]) => void;
};

export const BalanceHistoryFilter = ({
  onChangeDates,
}: Props) => {
  return (
    <ProCard
      className="ant-pro-table-search ant-pro-table-search-query-filter"
      bordered>
      <Row
        gutter={[10, 12]}
        className="flex"
        style={{width: '100%', paddingLeft: 10}}>
        <Col style={{minWidth: 250}}>
          <DatePicker.RangePicker
            style={{width: '100%', maxWidth: 500}}
            onChange={onChangeDates}
            format={'YYYY-MM-DD'}
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          />
        </Col>
      </Row>
    </ProCard>
  );
};
