import {SettingOutlined} from '@ant-design/icons';
import {Row} from 'antd';

type Props = {
  isBold?: boolean;
  region?: string;
  branch?: string;
  accountManager?: string;
  totalAgent?: string;
  totalCollaborators?: string;
  update?: string;
  option?: string;
  isShowOption?: boolean;
  className?: string;
  onClick?: () => void;
};

export const Columns = ({
  isBold,
  region,
  branch,
  // accountManager,
  totalAgent,
  totalCollaborators,
  update,
  option,
  isShowOption = false,
  className,
  onClick,
}: Props) => {
  return (
    <Row className={className}>
      <div
        className={`w-[calc(25%)] items-center justify-center text-center text-[12px] font-bold`}>
        {region}
      </div>
      <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] font-bold`}>
        {branch}
      </div>
      {/* <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] ${
          isBold && 'font-bold'
        }`}>
        {accountManager}
      </div> */}
      <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] ${
          isBold && 'font-bold'
        }`}>
        {totalAgent}
      </div>
      <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] ${
          isBold && 'font-bold'
        }`}>
        {totalCollaborators}
      </div>
      <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] ${
          isBold && 'font-bold'
        }`}>
        {update}
      </div>
      <div
        className={`flex-1 flex items-center justify-center text-center text-[12px] ${
          isBold && 'font-bold'
        }`}>
        {isShowOption ? (
          <div onClick={onClick}>
            <SettingOutlined />
          </div>
        ) : (
          <div>{option}</div>
        )}
      </div>
    </Row>
  );
};
