import UploadImage from '@/components/UploadImage';
import {APPLICATION_ACTION_TYPE} from '@/context/action';
import AppContext from '@/context/appContext';
import {RESPONSE_CODE} from '@/network/constants';
import {getInfoUser, updateCTV, updateUSER} from '@/network/services/accounts';
import {CATEGORY_TYPE_IMAGE, ROLES} from '@/utils/enum';
import {validateWhitespace} from '@/utils/helper';
import {DrawerForm} from '@ant-design/pro-components';
import {Col, Form, Input, Row, message} from 'antd';
import {
  Ref,
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import './styles.scss';
import {isRoleCTV, isUserHaveOneOfPermissions} from '@/utils';

export type CreateModalRef = {
  onOpen: () => void;
};

export type Props = {
  userInfo?: AppStorage['userInfor'];
  callbackFullInfo?: () => void;
};

const {TextArea} = Input;

const ModalUpdateInfo = (
  {userInfo, callbackFullInfo}: Props,
  ref: Ref<CreateModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const role = userInfo?.role === ROLES.COLLABORATOR ? 'CTV' : 'đại lý';

  const {dispatch, appState} = useContext(AppContext);

  const getInfo = async () => {
    const info = await getInfoUser();
    if (info) {
      dispatch({
        type: APPLICATION_ACTION_TYPE.UPDATE_USER_INFOR,
        payload: info,
      });
      if (info?.isFullInfo) {
        callbackFullInfo?.();
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const handleUpdate = async (values: any) => {
    const data = {
      ...values,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
      managerId: appState?.userInfor?.managerId,
      role: appState?.userInfor?.role,
    };
    const res = isUserHaveOneOfPermissions([ROLES.AGENT])
      ? await updateUSER(data, appState?.userInfor?.userId)
      : await updateCTV(data);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      getInfo();
      message.success('Cập nhật thành công');
      setOpen(false);
    } else {
      if (res.status === 500) {
        message.error('Cập nhật không thành công');
      } else {
        message.error(res.data);
      }
    }
  };

  return (
    <DrawerForm
      title={`Sửa thông tin ${role}`}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={open}
      drawerProps={{
        onClose: onClose,
      }}
      submitTimeout={2000}
      initialValues={userInfo}
      onFinish={handleUpdate}>
      <p style={{fontSize: 14, color: 'red', marginBottom: 10, marginTop: 5}}>
        *Bạn hãy bổ sung thông tin tài khoản ngân hàng để nhận thanh toán
      </p>
      <div
        className="infomationField"
        style={{
          padding: 10,
          borderRadius: 10,
          background: 'rgba(0,0,0,0.05)',
        }}>
        <p style={{fontSize: 14, fontWeight: '600', marginBottom: 10}}>
          Thông tin tài khoản
        </p>
        <Form.Item
          label="Tên đăng nhập:"
          name="userName"
          rules={[{required: true}]}
          className="mb-4">
          <Input className="w-full" disabled />
        </Form.Item>
        <Form.Item
          label={`Mã đại lý:`}
          name="agentCode"
          rules={[{required: true}]}
          className="mb-4">
          <Input className="w-[calc(100%)]" disabled />
        </Form.Item>
        {isRoleCTV() && (
          <Form.Item
            label="Người quản lý"
            name="managerName"
            rules={[{required: true}]}
            className="mb-4">
            <Input className="w-[calc(100%)]" disabled />
          </Form.Item>
        )}
      </div>
      <div
        className="infomationField"
        style={{
          padding: 10,
          borderRadius: 10,
          background: 'rgba(0,0,0,0.05)',
        }}>
        <p style={{fontSize: 14, fontWeight: '600', marginBottom: 10}}>
          Thông tin cá nhân
        </p>
        <Form.Item
          label="Họ và tên:"
          name="fullname"
          rules={[
            {required: true, message: 'Vui lòng nhập họ và tên !'},
            {
              validator: validateWhitespace,
            },
          ]}
          className="mb-4">
          <Input
            placeholder={'Nhập họ và tên...'}
            className="w-full"
            // disabled={true}
          />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          rules={[{required: true, message: 'Vui lòng nhập số điện thoại !'}]}
          className="mb-4">
          <Input
            placeholder="Nhập số điện thoại..."
            className="w-[calc(100%)]"
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{required: false, message: 'Vui lòng nhập email !'}]}
          className="mb-4">
          <Input placeholder="Nhập email..." className="w-[calc(100%)]" />
        </Form.Item>
        <Form.Item
          label="Địa chỉ chi tiết"
          name="address"
          rules={[{required: false, message: 'Vui lòng nhập người quản lý !'}]}
          className="mb-4">
          <TextArea
            placeholder="Nhập địa chỉ chi tiết..."
            className="w-[calc(100%)]"
            aria-multiline
            rows={3}
          />
        </Form.Item>
        <Form.Item
          label="Số CCCD"
          name="identityCode"
          rules={[{required: true, message: 'Vui lòng nhập số CCCD !'}]}
          className="mb-4">
          <Input placeholder="Nhập số CCCD..." className="w-[calc(100%)]" />
        </Form.Item>
        <Row>
          <Col style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
            <UploadImage
              label="Ảnh mặt trước"
              required
              readOnly
              name="imageIdentityFront"
              url={userInfo?.imageIdentityFront}
              category={CATEGORY_TYPE_IMAGE.IDENTITY}
            />
          </Col>
          <Col style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
            <UploadImage
              label="Ảnh mặt sau"
              required
              readOnly
              name="imageIdentityBack"
              url={userInfo?.imageIdentityBack}
              category={CATEGORY_TYPE_IMAGE.IDENTITY}
            />
          </Col>
        </Row>
      </div>
      <div
        className="infomationField"
        style={{
          padding: 10,
          borderRadius: 10,
          background: 'rgba(0,0,0,0.05)',
        }}>
        <p style={{fontSize: 14, fontWeight: '600', marginBottom: 10}}>
          Thông tin thanh toán
        </p>
        <Form.Item
          label="Tên ngân hàng:"
          name="bankName"
          rules={[
            {required: true, message: 'Vui lòng nhập ngân hàng !'},
            {
              validator: validateWhitespace,
            },
          ]}
          className="mb-4">
          <Input placeholder={'Nhập tên ngân hàng...'} className="w-full" />
        </Form.Item>
        <Form.Item
          label="Số tài khoản:"
          name="bankAccount"
          rules={[{required: true, message: 'Vui lòng nhập số tài khoản !'}]}
          className="mb-4">
          <Input
            placeholder="Nhập số tài khoản..."
            className="w-[calc(100%)]"
          />
        </Form.Item>
        <Form.Item
          label="Tên chủ tài khoản:"
          name="bankAccountName"
          rules={[
            {required: true, message: 'Vui lòng nhập tên chủ tài khoản !'},
          ]}
          className="mb-4">
          <Input
            placeholder="Nhập tên chủ tài khoản..."
            className="w-[calc(100%)]"
          />
        </Form.Item>
      </div>
    </DrawerForm>
  );
};

export const UpdateInfo = forwardRef(ModalUpdateInfo);
