import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, Input, Row, Select} from 'antd';
import './styles.scss';
import {PERMISSIONS} from '@/utils/constants';

type Props = {
  onChange: (e: {
    target: {
      value: string;
    };
  }) => void;
  onChangeStatus: (value: number) => void;
  onAddProduct: () => void;
  suppliers: SuppliersInterface[];
  onChangeSelectProvided: (_values: string, options: any) => void;
};

export const ProductsFilter = ({
  onChange,
  onChangeStatus,
  onChangeSelectProvided,
  onAddProduct,
  suppliers,
}: Props) => {
  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={8}>
          <Input.Search
            placeholder="Nhập tên gói..."
            allowClear
            onChange={onChange}
          />
        </Col>
        {!isUserHaveOneOfPermissions([
          ROLES.COLLABORATOR,
          ROLES.AGENT,
        ]) && (
          <Col xs={24} sm={12} md={5}>
            <Select
              allowClear
              className="w-full"
              placeholder="Trạng thái"
              options={[
                {label: 'Đang kinh doanh', value: 0},
                {label: 'Ngừng kinh doanh', value: 1},
              ]}
              onChange={onChangeStatus}
            />
          </Col>
        )}
        <Col xs={24} sm={12} md={5}>
          <Select
            allowClear
            className="w-full"
            placeholder="Nhà cung cấp"
            options={suppliers.map(item => ({
              value: item.code,
              label: item.name,
            }))}
            onChange={onChangeSelectProvided}
          />
        </Col>
        <Col xs={24} sm={12} md={6} className="flex justify-end">
          {isUserHaveOneOfPermissions(
            [ROLES.ADMIN, ROLES.AM_LEADER],
            PERMISSIONS.createProduct
          ) && (
            <Button
              key="button "
              icon={<PlusOutlined />}
              onClick={onAddProduct}
              type="primary">
              Thêm sản phẩm
            </Button>
          )}
        </Col>
      </Row>
    </ProCard>
  );
};
