import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, DatePicker, Input, Row, Select} from 'antd';
import './styles.scss';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {PERMISSIONS} from '@/utils/constants';

type Props = {
  onChangeName: (value: string) => void;
  onChangeCategory: (value: number) => void;
  onChangeDate: (value: any) => void;
  onAddPost: () => void;
  categories?: CategoryPostInterface[];
};

export const MOFilter = ({
  onChangeName,
  onAddPost,
  onChangeDate,
  onChangeCategory,
  categories,
}: Props) => {
  return (
    <ProCard
      className="ant-pro-table-search ant-pro-table-search-query-filter"
      bordered>
      <div className="flex flex-1" style={{flexWrap: 'wrap'}}>
        <Row
          gutter={[10, 12]}
          className="flex"
          style={{paddingLeft: 10, flex: 1, minWidth: 200}}>
          <Col style={{width: '100%', maxWidth: 250}}>
            <Input
              placeholder="Nhập tiêu đề bài viết"
              onChange={value => onChangeName(value?.target?.value)}
            />
          </Col>
          <Col style={{width: '100%', maxWidth: 250}}>
            <Select
              allowClear
              className="w-full"
              placeholder="Chọn chuyên mục"
              options={
                categories
                  ? categories?.map((item: CategoryPostInterface) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
              onChange={onChangeCategory}
            />
          </Col>

          <Col style={{width: '100%', maxWidth: 250}}>
            <DatePicker
              placeholder="Chọn ngày cập nhật"
              className="w-full"
              onChange={onChangeDate}
            />
          </Col>
        </Row>
        {isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.createCMS) && (
          <Button
            onClick={onAddPost}
            style={{
              marginRight: 10,
              marginLeft: 10,
              width: '100%',
              maxWidth: 250,
              background: '#1890ff',
              color: '#fff',
              border: '0px solid',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <PlusOutlined />
            Thêm bài viết mới
          </Button>
        )}
      </div>
    </ProCard>
  );
};
