import {TICKET_STATUS} from '@/utils/constants';
import {EyeOutlined} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Tag, Tooltip} from 'antd';

const getColumnsConfig: (
  openDetailModal: (id: number) => void,
  actionRef: any,
  isAdmin?: boolean,
  isReceived?: boolean
) => ProColumns<ListRequiredInterface>[] = (
  openDetailModal,
  actionRef,
  isAdmin,
  isReceived
) => {
  const columns: ProColumns<ListRequiredInterface>[] & any = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '5%',
      render: (_: any, __: any, indx: number) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Mã yêu cầu',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      hidden: !isAdmin,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
    },
    {
      title: 'Người tiếp nhận',
      dataIndex: 'assignedStaff',
      key: 'assignedStaff',
      align: 'center',
      hidden: !isReceived && isAdmin,
    },
    {
      title: 'Thời gian xử lý',
      dataIndex: 'processTime',
      key: 'processTime',
      align: 'center',
      hidden: !isReceived && isAdmin,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '7%',
      render: (_: any, record: {status: number}) => {
        const statusFilter = TICKET_STATUS.find(
          item => item?.value === record?.status
        );
        return (
          <Tag className="ml-2" color={statusFilter?.color}>
            {statusFilter?.label}
          </Tag>
        );
      },
      hidden: !isReceived && isAdmin,
    },
    {
      title: 'Thao tác',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '7%',
      render: (_: any, record: any) => (
        <Tooltip title={'Xem chi tiết yêu cầu'}>
          <span
            className={`text-[#1677ff] cursor-pointer`}
            onClick={openDetailModal.bind(null, record.id)}>
            <EyeOutlined className="text-[18px]" />
          </span>
        </Tooltip>
      ),
    },
  ].filter(item => !item.hidden);

  return columns;
};
export default getColumnsConfig;
