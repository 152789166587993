import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {useRef} from 'react';

type Props = {
  onChange?: (data: string) => void;
  placeholder?: string;
  className?: string;
  data?: string;
};

export default function CkTextEditor({
  onChange,
  placeholder,
  className,
  data,
}: Props) {
  const hasDataRef = useRef<boolean>(false);
  const customColorPalette = [
    {
      color: 'hsl(4, 90%, 58%)',
      label: 'Red',
    },
    {
      color: 'hsl(340, 82%, 52%)',
      label: 'Pink',
    },
    {
      color: 'hsl(291, 64%, 42%)',
      label: 'Purple',
    },
    {
      color: 'hsl(262, 52%, 47%)',
      label: 'Deep Purple',
    },
    {
      color: 'hsl(231, 48%, 48%)',
      label: 'Indigo',
    },
    {
      color: 'hsl(207, 90%, 54%)',
      label: 'Blue',
    },
  ];
  const editorConfiguration = {
    fontColor: {
      colors: [
        {
          color: 'hsl(0, 0%, 0%)',
          label: 'Black',
        },
        {
          color: 'hsl(0, 0%, 30%)',
          label: 'Dim grey',
        },
        {
          color: 'hsl(0, 0%, 60%)',
          label: 'Grey',
        },
        {
          color: 'hsl(0, 0%, 90%)',
          label: 'Light grey',
        },
        {
          color: 'hsl(0, 0%, 100%)',
          label: 'White',
          hasBorder: true,
        },
        // More colors.
        // ...
      ],
    },
    fontBackgroundColor: {
      colors: [
        {
          color: 'hsl(0, 75%, 60%)',
          label: 'Red',
        },
        {
          color: 'hsl(30, 75%, 60%)',
          label: 'Orange',
        },
        {
          color: 'hsl(60, 75%, 60%)',
          label: 'Yellow',
        },
        {
          color: 'hsl(90, 75%, 60%)',
          label: 'Light green',
        },
        {
          color: 'hsl(120, 75%, 60%)',
          label: 'Green',
        },
        // More colors.
        // ...
      ],
    },
    fontSize: {
      options: [
        'default',
        9,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
      ],
    },
    fontFamily: {
      supportAllValues: true,
    },
    colorPicker: {
      // Use 'hex' format for output instead of 'hsl'.
      format: 'hex',
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties',
      ],
      tableCellProperties: {
        borderColors: customColorPalette,
        backgroundColors: customColorPalette,
      },
    },
    toolbar: {
      items: [
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'fontBackgroundColor',
        'htmlEmbed',
        'alignment',
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ],
    },
    language: 'vi',
    image: {
      toolbar: [
        'imageTextAlternative',
        'toggleImageCaption',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
      ],
    },
  };
  return (
    <div className={className}>
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log(event);
          onChange?.(data);
          if (data?.length > 0) {
            hasDataRef.current = true;
          } else {
            hasDataRef.current = false;
          }
        }}
        data={data}
      />
      {placeholder && !hasDataRef.current && !data && (
        <div className="absolute top-11 left-3 text-[rgba(0,0,0,0.2)]">
          {placeholder}...
        </div>
      )}
    </div>
  );
}
