import {RESPONSE_CODE} from '@/network/constants';
import {createAccount, updateAccount} from '@/network/services/accounts';
import {
  ACCOUNT_TYPE,
  DEFAULT_PASSWORD,
  optionActive,
  ruleValidatePassword,
} from '@/utils/constants';
import {CaretRightOutlined} from '@ant-design/icons';
import {DrawerForm} from '@ant-design/pro-components';
import {Cascader, Form, Input, Select, message} from 'antd';
import {BaseOptionType} from 'antd/es/cascader';
import {DefaultOptionType} from 'antd/es/select';
import {Ref, forwardRef, useImperativeHandle, useState} from 'react';

export type Props = {
  onClose?(refresh?: boolean): void;
  managers: ManagerInterface[];
  lisOrganizations: OrganizitionsInterface[];
  groupsPermission: GroupPermissionItem[];
};

export type CreateModalRef = {
  open: (options?: {isEdit?: boolean; record?: AccountType}) => void;
};

function Create(props: Props, ref: Ref<CreateModalRef>) {
  const {lisOrganizations} = props;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [idUser, setIdUser] = useState<number>(0);
  const [regionCompanyId, setRegionCompanyId] = useState<number>();
  const [regionCompanyBranchId, setRegionCompanyBranchId] = useState<number>();
  const [role, setRole] = useState<string>('');
  const [accountType, setAccountType] = useState<string | undefined>(undefined);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: opts => {
          if (opts?.isEdit) {
            setIsEdit(true);
            setIdUser(opts.record?.userId!);
            setAccountType(opts.record?.accountType);
            form.setFieldValue('roleId', opts.record?.roleGroupId);
            form.setFieldsValue(opts.record);
            form.setFieldsValue({
              userName: opts?.record?.userName || opts?.record?.phoneNumber,
              organization:
                opts?.record?.regionCompanyId &&
                opts?.record?.regionCompanyBranchId
                  ? [
                      opts?.record?.regionCompanyId,
                      opts?.record?.regionCompanyBranchId,
                    ]
                  : opts?.record?.regionCompanyId
                    ? [opts?.record?.regionCompanyId]
                    : opts?.record?.regionCompanyBranchId
                      ? [opts?.record?.regionCompanyBranchId]
                      : [],
            });
            setRole(opts.record?.role || '');
          } else {
            setIsEdit(false);
            setIdUser(0);
            form.resetFields();
            setRole('');
          }
          setOpen(true);
        },
      };
    },
    []
  );

  const onChangeAccountType = (value: string) => {
    setAccountType(value);
  };

  const handleCreate = async (values: any) => {
    const data = {
      ...values,
      imageBankAccount: values.imageBankAccount?.[0]?.url,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
      regionCompanyBranchId: regionCompanyBranchId,
      regionCompanyId: regionCompanyId,
      accountType: accountType,
      role: role,
    };
    delete data?.organization;
    const res = await createAccount(data);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      onCancel(true);
      message.success('Thêm tài khoản thành công');
    } else {
      message.error(res?.data);
    }
  };

  const handleUpdate = async (values: any) => {
    const data = {
      ...values,
      imageBankAccount: values.imageBankAccount?.[0]?.url,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
      regionCompanyBranchId: regionCompanyBranchId,
      regionCompanyId: regionCompanyId,
      accountType: accountType,
      role: role,
    };
    delete data?.organization;
    const res = await updateAccount(idUser, data);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onCancel(true);
      message.success('Cập nhập thành công');
    } else {
      message.error(res?.data);
    }
  };

  const onCancel = (reload: boolean = false) => {
    setOpen(false);
    props.onClose && props.onClose(reload);
  };

  return (
    <DrawerForm
      title={isEdit ? 'Sửa tài khoản' : 'Tạo tài khoản'}
      size="middle"
      form={form}
      open={open}
      onOpenChange={visible => {
        if (!visible) {
          onCancel();
        }
      }}
      drawerProps={{
        destroyOnClose: true,
        onClose: () => {
          setOpen(false);
        },
      }}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      rowProps={{gutter: 10}}
      submitTimeout={2000}
      submitter={{
        searchConfig: {
          submitText: isEdit ? 'Sửa' : 'Thêm',
        },
      }}
      onFinish={isEdit ? handleUpdate : handleCreate}>
      <Form.Item
        label="Email"
        name="userName"
        rules={[{required: true}]}
        style={{marginBottom: 15}}>
        <Input placeholder={'Nhập email'} disabled={isEdit} />
      </Form.Item>
      <Form.Item
        label="Họ và tên"
        name="fullname"
        rules={[{required: true}]}
        style={{marginBottom: 15}}>
        <Input placeholder={'Nhập họ và tên'} />
      </Form.Item>
      {!isEdit && (
        <Form.Item
          label="Mật khẩu"
          name="password"
          initialValue={DEFAULT_PASSWORD}
          rules={[
            {
              required: true,
            },
            ruleValidatePassword,
          ]}
          style={{marginBottom: 15}}>
          <Input.Password placeholder={'Nhập mật khẩu'} disabled={isEdit} />
        </Form.Item>
      )}
      <Form.Item
        name="organization"
        label="Chọn tổ chức"
        required
        rules={[{required: true}]}>
        <Cascader
          placeholder="Chọn tổ chức"
          changeOnSelect
          allowClear
          disabled={isEdit}
          options={
            lisOrganizations?.map((item: any) => ({
              value: item?.id,
              label: item?.name,
              children:
                item?.children?.length > 0
                  ? item?.children?.map((item: any) => ({
                      value: item?.id,
                      label: item?.name,
                    }))
                  : undefined,
            })) as (DefaultOptionType | BaseOptionType)[] | undefined
          }
          displayRender={(labels, selectedOptions: any) => {
            return labels.map((label, i) => {
              const option = selectedOptions[i];
              if (i === labels.length - 1) {
                return <span key={option.value}>{label}</span>;
              }
              return (
                <span key={option.value}>
                  {label} <CaretRightOutlined />
                </span>
              );
            });
          }}
          onChange={(value: any) => {
            if (value?.length > 1) {
              setRole('REGION_COMPANY_BRANCH');
            } else {
              setRole('REGION_COMPANY');
            }
            setRegionCompanyId(value?.[0]);
            setRegionCompanyBranchId(value?.[1]);
          }}
        />
      </Form.Item>
      <Form.Item
        label="Trạng thái hoạt động"
        name="status"
        rules={[{required: true}]}
        style={{marginBottom: 15}}
        initialValue={0}>
        <Select
          options={optionActive}
          placeholder="Vui lòng chọn"
          allowClear={false}
        />
      </Form.Item>
      <Form.Item
        label="Kiểu tài khoản"
        name="accountType"
        rules={[{required: true}]}
        style={{marginBottom: 15}}>
        <Select
          options={ACCOUNT_TYPE}
          placeholder="Vui lòng chọn kiểu tài khoản"
          allowClear={false}
          value={accountType}
          disabled={isEdit}
          onChange={onChangeAccountType}
        />
      </Form.Item>
      {accountType === 'account_operator' && (
        <Form.Item
          label="Nhóm quyền"
          name="roleId"
          rules={[{required: true}]}
          style={{marginBottom: 15}}>
          <Select
            options={props.groupsPermission.map((item: any) => ({
              ...item,
              value: item.id,
              label: item.name,
            }))}
            placeholder="Vui lòng chọn nhóm quyền"
            allowClear={false}
          />
        </Form.Item>
      )}
    </DrawerForm>
  );
}

export const CreateModal = forwardRef(Create);
