import useCheckMobileScreen from '@/hooks/useCheckMobileScreen';
import {useOrganization} from '@/hooks/useOrganizations';
import {CaretRightOutlined} from '@ant-design/icons';
import {Collapse} from 'antd';
import {Columns} from './Columns';
import {Filters} from './Filters';
import {CreateRegionModal} from './ModalCreateRegion';
import {CreateBranchModal} from './ModalCreateBranch';
import {Header} from './Header';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {PERMISSIONS} from '@/utils/constants';

export const Organizations = () => {
  const {
    state,
    handleOpenModalBranch,
    handleOpenModalRegion,
    createBranchModal,
    createRegionModal,
    openTopup,
    createRegionCompany,
    createBranchCompany,
    detailBranchCompany,
    detailRegionCompany,
    editBranch,
    editCompany,
    handleFilterStatus,
  } = useOrganization();
  const isMobile = useCheckMobileScreen();
  return (
    <div>
      <Filters
        topupCreateOrganization={state?.topupCreateOrganization}
        openTopup={openTopup}
        handleOpenModalBranch={handleOpenModalBranch}
        handleOpenModalRegion={handleOpenModalRegion}
        handleFilterStatus={handleFilterStatus}
      />
      <div className="w-full overflow-auto h-screen pb-[150px]">
        <div
          className="md:overflow-x-hidden"
          style={{
            overflowX: !isMobile ? 'auto' : 'hidden',
            width: !isMobile ? '100%' : '1400px',
          }}>
          <Header />
          <div>
            {state?.lisOrganizations.map(
              (item: OrganizitionsInterface, index: number) => (
                <Collapse
                  key={index}
                  accordion
                  expandIcon={({isActive}) => (
                    <div className="">
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    </div>
                  )}
                  bordered={false}
                  style={{
                    background: 'none',
                    boxShadow: 'none',
                    borderRadius: 0,
                    borderBottom: '1px solid #DDDDDD',
                    borderLeft: '1px solid #DDDDDD',
                    borderRight: '1px solid #DDDDDD',
                    borderTop: index === 0 ? '1px solid #DDDDDD' : '',
                  }}>
                  <Collapse.Panel
                    header={
                      <Columns
                        region={item?.name}
                        totalAgent={item?.numOfAgents}
                        totalCollaborators={item?.numOfCollaborators}
                        update={item?.modifiedDate}
                        isShowOption={isUserHaveOneOfPermissions(
                          [ROLES.ADMIN],
                          PERMISSIONS.updateRC
                        )}
                        onClick={detailRegionCompany?.bind(null, item?.id)}
                      />
                    }
                    key="1">
                    {item?.children?.map((itemChildren, indexChildren) => (
                      <div key={indexChildren}>
                        <Columns
                          branch={itemChildren?.name}
                          totalAgent={itemChildren?.numOfAgents}
                          totalCollaborators={itemChildren?.numOfCollaborators.toString()}
                          update={itemChildren?.modifiedDate}
                          isShowOption={isUserHaveOneOfPermissions(
                            [ROLES.ADMIN],
                            PERMISSIONS.updateRC
                          )}
                          className={`${indexChildren !== 0 && 'mt-5'} ml-6`}
                          onClick={detailBranchCompany.bind(
                            null,
                            itemChildren?.id
                          )}
                        />
                      </div>
                    ))}
                  </Collapse.Panel>
                </Collapse>
              )
            )}
          </div>
        </div>
      </div>
      <CreateRegionModal
        ref={createRegionModal}
        isEdit={state?.isEdit}
        createRegionCompany={createRegionCompany}
        editCompany={editCompany}
      />
      <CreateBranchModal
        ref={createBranchModal}
        isEdit={state?.isEdit}
        createBranchCompany={createBranchCompany}
        listRegion={state?.lisOrganizations}
        editBranch={editBranch}
      />
    </div>
  );
};
