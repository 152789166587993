import {isRoleCTV, isUserHaveOneOfPermissions} from '@/utils';
import {PERMISSIONS, ROUTERS} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {
  AppstoreOutlined,
  CreditCardOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  // LayoutOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  // ProfileOutlined,
  // ProjectOutlined,
  SolutionOutlined,
  TableOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {ReactNode} from 'react';

export type MenuItemProps = {
  path?: string;
  name: string;
  icon: ReactNode;
  children?: {
    path: string;
    name: string;
  }[];
};

const routes = [
  {
    path: ROUTERS.Dashboard.path,
    name: 'Trang chủ',
    icon: isUserHaveOneOfPermissions([
      ROLES.ADMIN,
      ROLES.AM_LEADER,
      ROLES.AM,
    ]) ? (
      <FundProjectionScreenOutlined className="text-lg" />
    ) : (
      <HomeOutlined className="text-lg" />
    ),
  },
  {
    path: ROUTERS.Accounts.path,
    name: 'Quản lý tài khoản',
    icon: <SolutionOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.ADMIN, ROLES.AM_LEADER],
      PERMISSIONS.viewAccount
    ),
    children: [
      {
        path: ROUTERS.Accounts.path,
        name: 'Danh sách tài khoản',
        hidden: !isUserHaveOneOfPermissions(
          [ROLES.ADMIN],
          PERMISSIONS.viewUser
        ),
      },
      {
        path: ROUTERS.PermissionConfiguration.path,
        name: 'Cấu hình phân quyền',
        hidden: !isUserHaveOneOfPermissions(
          [ROLES.ADMIN],
          PERMISSIONS.viewPermissions
        ),
      },
    ].filter(item => !item?.hidden),
  },
  {
    path: ROUTERS.Collaborators.path,
    name: isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM])
      ? 'Quản lý đối tác'
      : 'Quản lý CTV',
    icon: <TeamOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM, ROLES.AGENT],
      PERMISSIONS.viewAgent
    ),
    children: [
      {
        path: ROUTERS.Organizations.path,
        name: 'Danh sách tổ chức',
        hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.viewRC),
      },
      {
        path: ROUTERS.Collaborators.path,
        name: isUserHaveOneOfPermissions([
          ROLES.ADMIN,
          ROLES.AM_LEADER,
          ROLES.AM,
        ])
          ? 'Danh sách đại lý'
          : 'Danh sách CTV',
        hidden: !isUserHaveOneOfPermissions(
          [ROLES.ADMIN],
          PERMISSIONS.viewAgent
        ),
      },
    ].filter(item => !item?.hidden),
  },
  {
    path: ROUTERS.Notification.path,
    name: 'Quản lý thông báo',
    icon: <NotificationOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.ADMIN],
      PERMISSIONS.viewNotification
    ),
  },
  {
    path: ROUTERS.Products.path,
    name: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? 'Danh sách sản phẩm'
      : 'Quản lý sản phẩm',
    icon: <AppstoreOutlined className="text-lg" />,
    hidden: false,
  },
  {
    path: ROUTERS.Campaigns.path,
    name: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? 'Danh sách chiến dịch'
      : 'Quản lý chiến dịch',
    icon: <TableOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.COLLABORATOR, ROLES.AM, ROLES.AGENT],
      PERMISSIONS.viewCampaign
    ),
  },
  // {
  //   path: ROUTERS.Posts.path,
  //   name: 'CMS',
  //   icon: <ProjectOutlined className="text-lg" />,
  //   hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.viewCMS),
  // },
  // {
  //   path: ROUTERS.Documents.path,
  //   name: 'Tài liệu bán hàng',
  //   icon: <LayoutOutlined className="text-lg" />,
  //   hidden: !isUserHaveOneOfPermissions(
  //     [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.COLLABORATOR, ROLES.AM, ROLES.AGENT],
  //     PERMISSIONS.viewDocument
  //   ),
  // },
  {
    path: ROUTERS.Orders.path,
    name: isRoleCTV() ? 'Danh sách đơn hàng' : 'Quản lý đơn hàng',
    icon: <OrderedListOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [
        ROLES.ADMIN,
        ROLES.AM_LEADER,
        ROLES.COLLABORATOR,
        ROLES.AM,
        ROLES.AGENT,
        ROLES.ACT,
      ],
      PERMISSIONS.viewOrder
    ),
  },
  {
    path: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? null
      : ROUTERS.Payments.path,
    name: 'Quản lý thanh toán',
    icon: <CreditCardOutlined className="text-lg" />,
    children: [
      {
        path: ROUTERS.Payments.path,
        name: 'Yêu cầu thanh toán',
        hidden: !isUserHaveOneOfPermissions(
          [ROLES.AGENT, ROLES.COLLABORATOR],
          PERMISSIONS.viewPayment
        ),
      },
      {
        path: ROUTERS.BalanceHistory.path,
        name: 'Biến động số dư',
        hidden: !isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]),
      },
    ].filter(item => !item?.hidden),
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.ADMIN, ROLES.COLLABORATOR, ROLES.AGENT, ROLES.ACT],
      PERMISSIONS.viewPayment
    ),
  },
  {
    path: ROUTERS.RequireSupport.path,
    name: 'Quản lý yêu cầu hỗ trợ',
    icon: <UserSwitchOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions(
      [ROLES.AGENT, ROLES.COLLABORATOR, ROLES.ADMIN],
      PERMISSIONS.viewTicket
    ),
  },
  // {
  //   path: ROUTERS.ReportCampaign.path,
  //   name: 'Thống kê báo cáo',
  //   icon: <ProfileOutlined className="text-lg" />,
  //   hidden: !isUserHaveOneOfPermissions(
  //     [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM, ROLES.AGENT],
  //     PERMISSIONS.viewReport
  //   ),
  // },
  // {
  //   path: ROUTERS.ImportedMOFile.path,
  //   name: 'Import / Export Files',
  //   icon: <FileOutlined className="text-lg" />,
  //   hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER]),
  // },
].filter(item => !item?.hidden);

export default {
  route: {
    path: '/',
    routes: routes,
  },
};
