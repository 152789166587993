import {Moment} from 'moment';
import {LOCAL_STORAGE_KEY, ROLES} from './enum';
import {DEFAULT_FORMAT_DATE} from './constants';
import moment from 'moment';

/**
 * This function convert date to timestamp
 * @param phone is phone number
 * @returns {string}
 */
export const replacePhoneNumber = (phone: string): string => {
  return phone.replace(/^0/g, '');
};

/**
 * This function check have permissions
 * @param permissions is phone number
 * @returns {string}
 */
export const isUserHaveOneOfPermissions = (
  permissions: string[],
  permission?: string
): boolean => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE) ?? '';

  try {
    const userPermissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO) ?? '{}')
        .permissions ?? [];

    return (
      permissions.includes(userRole) || userPermissions.includes(permission)
    );
  } catch (error) {
    console.error('Error parsing user permissions from local storage:', error);
    return false;
  }
};

export const isRoleOperatorPermission = () => {
  const userPermissions = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO) ?? '{}'
  );
  return userPermissions?.accountType === 'account_operator';
};

export const isRoleAgent = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.AGENT;
};

export const isRoleCTV = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.COLLABORATOR;
};

export const isRoleAgentCtv = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.AGENT || userRole === ROLES.COLLABORATOR;
};

export const convertDate = (date?: Moment) =>
  date ? moment(date.valueOf()).format(DEFAULT_FORMAT_DATE) : undefined;

export const onLogout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  window.location.href = '/';
};

export function updateOrAddObjects(
  array: ItemPermission[],
  newObjects: ItemPermission[]
) {
  newObjects.forEach(newObject => {
    const existingIndex = array.findIndex(obj => obj.id === newObject.id);

    if (existingIndex !== -1) {
      array.splice(existingIndex, 1, newObject);
    } else {
      array.push(newObject);
    }
  });

  return array;
}
