// import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {DrawerForm} from '@ant-design/pro-components';
import {Col, Row} from 'antd';
import moment from 'moment';
import React, {Ref, forwardRef, useImperativeHandle} from 'react';

export type CampaignDetailModalRef = {
  open: (data?: ListCampaignInterface) => void;
};

const CampaignDetail = (_: any, ref: Ref<CampaignDetailModalRef>) => {
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState<ListCampaignInterface>();

  const infoCampaign: any[] = [
    {
      id: 1,
      label: 'Tên chiến dịch:',
      content: details?.name,
      isBold: true,
    },
    {
      id: 2,
      label: 'Danh sách sản phẩm:',
      content: details?.products,
      isBold: false,
    },
    {
      id: 3,
      label: 'Ngày bắt đầu:',
      content: moment(details?.startTime).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      id: 4,
      label: 'Ngày kết thúc:',
      content: moment(details?.endTime).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      id: 5,
      label: 'Nhà cung cấp:',
      content: details?.supplier?.name,
    },
    // {
    //   id: 2,
    //   label: 'Tên khách hàng:',
    //   content: !isNullOrEmpty(details?.customerInfo?.customerName)
    //     ? details?.customerInfo?.customerName
    //     : null,
    //   isBold: false,
    // },
    // {
    //   id: 2,
    //   label: 'Số điện thoại:',
    //   content: !isNullOrEmpty(details?.customerInfo?.phone)
    //     ? details?.customerInfo?.phone
    //     : null,
    //   isBold: false,
    // },
  ];

  const onclose = () => {
    setOpen(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          console.log(data);

          setDetails(data);
          setOpen(true);
        },
      };
    },
    []
  );

  return (
    <DrawerForm
      title={'Chi tiết chiến dịch'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      initialValues={{
        agentCommissionFee: 30,
        collaboratorCommissionFee: 70,
      }}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitter={false}
      submitTimeout={2000}>
      {infoCampaign?.map((item, index) => (
        <Row key={item.id} gutter={24} className="mb-10">
          <Col span={8}>
            <p style={{fontWeight: 600}}>{item?.label}</p>
          </Col>
          {index === 1 ? (
            <>
              {item?.content?.map(
                (item: ProductItemInterface, index: number) => (
                  <Col key={index + 'id'} span={16}>
                    <p key={index}>- {item?.name}</p>
                  </Col>
                )
              )}
            </>
          ) : (
            <Col span={16}>
              <p style={{fontWeight: item?.isBold ? 'bold' : undefined}}>
                {item.content}
              </p>
            </Col>
          )}
        </Row>
      ))}
    </DrawerForm>
  );
};
export const CampaignDetailModal = forwardRef(CampaignDetail);
