import accountsServices, {
  listManager,
  resetPassword,
} from '@/network/services/accounts';
import {ActionType, ProTable} from '@ant-design/pro-components';
import {Modal, message} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {CreateModal, CreateModalRef} from './Create/Create';
import {UpdateModal, UpdateModalRef} from './Update/Update';
import getColumnsConfig from './Columns';
import {RESPONSE_CODE} from '@/network/constants';
import {ModalDetailInfo, ModalDetailRef} from './ModalDetailInfo';
import _, {isArray} from 'lodash';
import {useWindowSize} from '@/hooks/useWindowSize';
import AccountFilters from './AccountFilters';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST} from '@/utils/constants';
import {organizationsServices} from '@/network/services/organizitions';
import {permissionServices} from '@/network/services/permissions';

export const Accounts = () => {
  const actionRef = useRef<ActionType>();
  const createModalRef = useRef<CreateModalRef>(null);
  const UpdateRef = useRef<UpdateModalRef>(null);
  const [stateAccount, setStateAccount] = useState<{
    search: string;
    dates: string[];
    code: string | undefined;
    isHidden: boolean;
  }>({
    search: '',
    dates: [],
    code: '',
    isHidden: false,
  });

  const modalDetailRef = useRef<ModalDetailRef>(null);
  const [metaData, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [modal, contextHolder] = Modal.useModal();
  const [managers, setManagers] = useState<ManagerInterface[]>([]);
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [lisOrganizations, setListOrganizations] = useState<
    OrganizitionsInterface[]
  >([]);
  const [reload, setReload] = useState<boolean>(false);
  const [groupsPermission, setGroupsPermission] = useState<GroupPermissionItem[]>([]);
  const windowSize = useWindowSize();

  const onReloadTable = () => {
    setTimeout(() => {
      if (actionRef.current?.reloadAndRest) {
        setReload(!reload);
        actionRef.current?.reloadAndRest!();
      } else {
        setReload(!reload);
        actionRef.current?.reload();
      }
    }, 10);
  };

  const reset = (id: number) => {
    modal.confirm({
      title: 'Đặt lại mật khẩu về mặc định',
      content: 'Bạn có muốn đặt lại mật khẩu về mật khẩu ban đầu ?',
      okText: 'Xác nhận',
      width: 350,
      onOk: async () => {
        const res = await resetPassword(id);
        if (res?.status === RESPONSE_CODE.SUCCESS) {
          message.success('Đã đặt lại mật khẩu về mặc định');
        } else {
          message.error(res?.data ?? 'Có lỗi xảy ra');
        }
      },
    });
  };

  const columns = getColumnsConfig(
    reset,
    actionRef,
    createModalRef,
    modalDetailRef
  );

  const handleSearch = _.debounce((e: any) => {
    setStateAccount(prevState => ({
      ...prevState,
      search: e?.target?.value,
    }));
    setPage(0);
    onReloadTable();
  }, 500);

  const handleFilterDate = (_: any, dateString: string[]) => {
    setStateAccount(prevState => ({
      ...prevState,
      dates: dateString,
    }));
    setPage(0);
    onReloadTable();
  };

  const onSizeChange = (page: number, size: number) => {
    setPage(Math.max(page - 1, 0));
    setSize(size);
  };

  const fetchManagers = async () => {
    const response = await listManager();
    if (isArray(response)) {
      setManagers(response);
    }
  };

  const fetchData = async () => {
    const startDate = stateAccount?.dates[0]
      ? `${stateAccount?.dates[0]} 00:00:00`
      : undefined;
    const endDate = stateAccount?.dates[1]
      ? `${stateAccount?.dates[1]} 23:59:59`
      : undefined;
    const response = await accountsServices.accounts({
      query: stateAccount?.search,
      startDate,
      endDate,
      agentCode: stateAccount?.code,
      page: page,
      size: size,
    });
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data ?? {};
      setAccounts(data);
      setMetadata(pagination);
    }
  };

  const fetchDataOrganizations = async () => {
    const res = await organizationsServices.getStructure();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setListOrganizations(res?.data);
    }
  };

  const fetchGroupsPermission = async () => {
    const response = await permissionServices.getGroupsPermission();
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setGroupsPermission(response?.data?.data);
    }
  };
  useEffect(() => {
    fetchManagers();
    fetchData();
    fetchDataOrganizations();
    fetchGroupsPermission();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, size, stateAccount, reload]);

  return (
    <div className="flex flex-col">
      <ProTable<AccountType>
        className="custom-table"
        columns={columns}
        dataSource={accounts}
        scroll={{x: 1500, y: windowSize?.height - 300}}
        actionRef={actionRef}
        cardBordered
        rowKey="userId"
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <AccountFilters
            handleSearch={handleSearch}
            handleFilterDate={handleFilterDate}
            createModalRef={createModalRef}
          />
        )}
        options={false}
        pagination={{
          current: page + 1,
          total: metaData?.total,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          pageSizeOptions: PAGE_SIZE_LIST,
          showSizeChanger: true,
          size: 'small',
          onChange: onSizeChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
      />
      <CreateModal
        ref={createModalRef}
        onClose={(reload: boolean) => {
          if (reload) onReloadTable();
        }}
        managers={managers}
        lisOrganizations={lisOrganizations}
        groupsPermission={groupsPermission}
      />
      <UpdateModal
        ref={UpdateRef}
        onClose={(reload: boolean) => {
          if (reload) onReloadTable();
        }}
      />
      <ModalDetailInfo
        ref={modalDetailRef}
        managers={managers}
        isHidden={false}
        isHiddenPosition={true}
      />
      {contextHolder}
    </div>
  );
};
