import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {Tabs} from 'antd';
import {RequiredSupportTab} from './RequiredSupportTab';
import {PERMISSIONS} from '@/utils/constants';
export const RequireSupport = () => {
  if (isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])) {
    return <RequiredSupportTab isAdmin={false} />;
  }
  if (isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.viewTicket)) {
    return (
      <Tabs destroyInactiveTabPane>
        <Tabs.TabPane tab="Chưa tiếp nhận" key="item-1">
          <RequiredSupportTab isAdmin={true} isReceived={false} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Đã tiếp nhận" key="item-2">
          <RequiredSupportTab isAdmin={true} isReceived={true} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
  return null;
};
