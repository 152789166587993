import {Checkbox, Col, Row} from 'antd';
import {FC} from 'react';
import ModulePermission from './ModulePermission';
import {isNullOrEmpty} from '@/utils/stringUtils';

type Props = {
  data: ModulePermission[];
  onSelectPermission: (items: (ItemPermission | undefined)[]) => void;
};

const TableManagerPermissions: FC<Props> = ({data, onSelectPermission}) => {
  const onSelectedAll = () => {};

  if (isNullOrEmpty(data)) return null;

  const parentPermissions = data.filter(item => isNullOrEmpty(item.parentId));
  const childrenPermissions = data.filter(
    item => !isNullOrEmpty(item.parentId)
  );

  return (
    <div
      className="overflow-y-auto scrollbar w-full overflow-hidden px-4"
      style={{maxHeight: 500}}>
      <Row
        gutter={[24, 12]}
        className="bg-[#D9D9D9] h-8 sticky top-0 z-10"
        align="middle">
        <Col xs={24} sm={1}>
          <Checkbox onChange={onSelectedAll} disabled />
        </Col>
        <Col xs={24} sm={7}>
          <p className="font-bold" style={{fontSize: 14}}>
            Module
          </p>
        </Col>
        <Col xs={24} sm={4}>
          <p className="text-center font-bold" style={{fontSize: 14}}>Xem</p>
        </Col>
        <Col xs={24} sm={4}>
          <p className="text-center font-bold" style={{fontSize: 14}}>Tạo mới</p>
        </Col>
        <Col xs={24} sm={4}>
          <p className="text-center font-bold" style={{fontSize: 14}}>Chỉnh sửa</p>
        </Col>
        <Col xs={24} sm={4}>
          <p className="text-center font-bold" style={{fontSize: 14}}>Chi tiết</p>
        </Col>
      </Row>

      {parentPermissions?.map((item, index) => (
        <ModulePermission
          key={index + 'item-module'}
          module={item}
          onSelectPermission={onSelectPermission}
          subModules={childrenPermissions.filter(
            module => module.parentId === item.id
          )}
        />
      ))}
    </div>
  );
};

export default TableManagerPermissions;
