import {useEffect, useState} from 'react';
import revenueService from '@/network/services/report';
import moment from 'moment';
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE,
  DEFAULT_REPORT_PAGE_SIZE,
} from '@/utils/constants';
import {useWindowSize} from '@/hooks/useWindowSize';
import dayjs from 'dayjs';
import {RESPONSE_CODE} from '@/network/constants';
import {convertDate} from '@/utils';

type TotalState = {
  totalRevenue: number;
  totalOrder: number;
  totalOrderSuccess: number;
  totalOrderNotSuccess: number;
};

interface UseReportRevenueInterface {
  params: ReportParams;
  totals: TotalState | undefined;
  tableRevenue: AgentRevenueInterface[];
  metaDataRevenue: MetadataInterface | undefined;
  pageRevenue: number;
  txtTotalOrder: string;
  txtTotalOrderSuccess: string;
  txtTotalOrderError: string;
  tableOrder: AgentOrderInterface[];
  metaDataOrder: MetadataInterface | undefined;
  pageOrder: number;
  onSizeChangeRevenue: (page: number) => void;
  onChangeRangeDate: (values: any) => void;
  onSelectWeek: () => void;
  onSelectMonth: () => void;
  onSizeChangeOrder: (page: number) => void;
}

export const useReportRevenue = (): UseReportRevenueInterface => {
  const {width} = useWindowSize();
  const [totals, setTotals] = useState<TotalState | undefined>(undefined);
  const [params, setParams] = useState<ReportParams>({
    fromDate: dayjs(
      moment().startOf('month').startOf('day').format(DEFAULT_FORMAT_DATE),
      DEFAULT_FORMAT_DATE
    ),
    toDate: dayjs(
      moment().endOf('day').format(DEFAULT_FORMAT_DATE),
      DEFAULT_FORMAT_DATE
    ),
  });

  const [tableRevenue, setTableRevenue] = useState<AgentRevenueInterface[]>([]);
  const [metaDataRevenue, setMetadataRevenue] = useState<
    MetadataInterface | undefined
  >(undefined);
  const [pageRevenue, setPageRevenue] = useState<number>(0);

  const [tableOrder, setTableOrder] = useState<AgentOrderInterface[]>([]);
  const [metaDataOrder, setMetaDataOrder] = useState<
    MetadataInterface | undefined
  >(undefined);
  const [pageOrder, setPageOrder] = useState<number>(0);

  const fetchOrders = async (params: ReportTableParams) => {
    const response = await revenueService.orderAgents(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data;
      setTableOrder(data);
      setMetaDataOrder(pagination);
    }
  };

  const onSizeChangeOrder = (page: number) => {
    setPageOrder(Math.max(page - 1, 0));
  };

  const fetchRevenue = async (params: ReportTableParams) => {
    const response = await revenueService.agentsRevenue(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data;
      setTableRevenue(data);
      setMetadataRevenue(pagination);
    }
  };

  const onSizeChangeRevenue = (page: number) => {
    setPageRevenue(Math.max(page - 1, 0));
  };

  const fetchTotalRevenueAndOrder = async (params: ReportParams) => {
    const response = await Promise.all([
      revenueService.totalRevenue(params),
      revenueService.orderCount(params),
    ]);

    if (response?.[0]?.status === RESPONSE_CODE.SUCCESS) {
      setTotals({
        totalRevenue: response?.[0]?.data?.total,
        totalOrder: response?.[1]?.data?.totalOrder,
        totalOrderSuccess: response?.[1]?.data?.totalOrderSuccess,
        totalOrderNotSuccess: response?.[1]?.data?.totalOrderNotSuccess,
      });
    }
  };

  const onChangeDays = (time: 'week' | 'month') => {
    setParams({
      fromDate: dayjs(
        moment().startOf(time).startOf('day').format(DEFAULT_FORMAT_DATE),
        DEFAULT_FORMAT_DATE
      ),
      toDate: dayjs(
        moment().endOf('day').format(DEFAULT_FORMAT_DATE),
        DEFAULT_FORMAT_DATE
      ),
    });
  };

  const onSelectWeek = () => {
    onChangeDays('week');
    setPageRevenue(0);
    setPageOrder(0);
  };

  const onSelectMonth = () => {
    onChangeDays('month');
    setPageRevenue(0);
    setPageOrder(0);
  };

  const onChangeRangeDate = (values: any) => {
    if (values) {
      setParams({
        fromDate: values[0].startOf('day'),
        toDate: values[1].endOf('day'),
      });
    } else {
      setParams({
        fromDate: undefined,
        toDate: undefined,
      });
    }
    setPageRevenue(0);
    setPageOrder(0);
  };

  useEffect(() => {
    fetchOrders({
      page: pageOrder,
      size: DEFAULT_REPORT_PAGE_SIZE,
      fromDate: convertDate(params.fromDate),
      toDate: convertDate(params.toDate),
    });
  }, [params, pageOrder]);

  useEffect(() => {
    fetchRevenue({
      page: pageRevenue,
      size: DEFAULT_REPORT_PAGE_SIZE,
      fromDate: convertDate(params.fromDate),
      toDate: convertDate(params.toDate),
    });
  }, [params, pageRevenue]);

  useEffect(() => {
    fetchTotalRevenueAndOrder({
      fromDate: convertDate(params.fromDate),
      toDate: convertDate(params.toDate),
    });
  }, [params]);

  const isMobile = width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;
  const txtTotalOrder = isMobile ? 'Đơn hàng' : 'Tổng số đơn hàng';
  const txtTotalOrderSuccess = isMobile
    ? 'Thành công'
    : 'Số đơn hàng thành công';
  const txtTotalOrderError = isMobile
    ? 'Chưa thành công'
    : 'Số đơn hàng chưa thành công';

  return {
    params,
    totals,
    tableRevenue,
    metaDataRevenue,
    pageRevenue,
    txtTotalOrder,
    txtTotalOrderSuccess,
    txtTotalOrderError,
    tableOrder,
    metaDataOrder,
    pageOrder,
    onSizeChangeRevenue,
    onChangeRangeDate,
    onSelectWeek,
    onSelectMonth,
    onSizeChangeOrder,
  };
};
