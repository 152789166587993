import AppContext from '@/context/appContext';
import {RESPONSE_CODE} from '@/network/constants';
import {paymentServices} from '@/network/services/payments';
import {DEFAULT_FORMAT_DATE} from '@/utils/constants';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {ActionType} from '@ant-design/pro-components';
import {message} from 'antd';
import moment from 'moment';
import React, {useContext, useEffect} from 'react';

type FilterProps = {
  size: number;
  page: number;
  date?: string;
  fromDate?: string;
  toDate?: string;
  status?: number;
};

type statePaymentType = {
  payments: PaymentItemInterface[];
  metadata: MetadataInterface | undefined;
  filters: FilterProps;
  loading: boolean;
  loadingCancel: boolean;
  isRefresh: boolean;
  topupRequestPayment: boolean;
  topupRequestInfor: boolean;
};

type modalProps = {
  onOpen: () => void;
  onClose?: () => void;
};

const defaultFilters = {
  size: 10,
  page: 0,
  date: undefined,
  fromDate: undefined,
  toDate: undefined,
};

export const usePayment = (
  requestWithdrawSuccess: () => void,
  isUser: boolean,
  callBackUpdate?: () => void
) => {
  const [statePayment, setStatePayment] = React.useState<statePaymentType>({
    payments: [],
    metadata: undefined,
    filters: defaultFilters,
    loading: false,
    loadingCancel: false,
    isRefresh: false,
    topupRequestPayment: false,
    topupRequestInfor: false,
  });
  const updateInfo = React.useRef<modalProps>(null);
  const {appState} = useContext(AppContext);

  useEffect(() => {
    if (statePayment?.isRefresh) {
      getListPayment(isUser);
    }
  }, [statePayment?.isRefresh]);

  const actionRef = React.useRef<ActionType>();
  const onPageChange = (mPage: number, mSize: number) => {
    setStatePayment(prevState => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const requestWithdraw = async (amount: number) => {
    const res = await paymentServices.requestPayment({amount: amount});
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message.success('Thành công');
      getListPayment(isUser);
      requestWithdrawSuccess();
    } else {
      message.error((res?.data as any) ?? 'Có lỗi xảy ra');
    }
  };

  const getListPayment = async (isUserProps?: boolean) => {
    setStatePayment(prevState => ({
      ...prevState,
      loading: true,
    }));
    const params = {
      page: statePayment?.filters?.page,
      size: statePayment?.filters?.size,
      status: statePayment?.filters?.status,
      fromDate: !isNullOrEmpty(statePayment?.filters?.fromDate)
        ? moment(statePayment.filters.fromDate)
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toDate: !isNullOrEmpty(statePayment?.filters?.toDate)
        ? moment(statePayment.filters.toDate)
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      IsVerifiedAccont: null,
    };
    const res = await paymentServices.listingPaymentRequest(
      params,
      isUserProps
    );

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const {data, page, size, total, totalPages} = res?.data;
      setStatePayment(prevState => ({
        ...prevState,
        payments: data,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
      setStatePayment(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
    } else {
      setStatePayment(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error((res?.data as any) ?? 'Có lỗi xảy ra');
    }
  };

  const onFilterStatus = (value: number) => {
    setStatePayment((prevState: statePaymentType) => ({
      ...prevState,
      loading: true,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        status: value,
        page: 0,
      },
    }));
  };

  const onFilterDates = (values: any) => {
    setStatePayment((prevState: statePaymentType) => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0].format('YYYY-MM-DD'),
        toDate: values?.[1].format('YYYY-MM-DD'),
        page: 0,
      },
    }));
  };

  const exportPayments = async () => {
    const res = await paymentServices.getDataExport({
      status: statePayment?.filters?.status,
      fromDate: !isNullOrEmpty(statePayment?.filters?.fromDate)
        ? moment(statePayment.filters.fromDate)
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toDate: !isNullOrEmpty(statePayment?.filters?.toDate)
        ? moment(statePayment.filters.toDate)
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
    });
    return res;
  };

  const importPayments = async (fileObj: any) => {
    const res = await paymentServices.importData({
      file: fileObj,
    });
    return res;
  };

  const cancelPayment = async (id: number) => {
    setStatePayment(prevState => ({
      ...prevState,
      loadingCancel: true,
    }));
    const res = await paymentServices.cancelPayment(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message.success('Thành công');
      getListPayment(isUser);
      setStatePayment(prevState => ({
        ...prevState,
        loadingCancel: false,
      }));
      callBackUpdate?.();
    } else {
      message.error((res?.data as any) ?? 'Có lỗi xảy ra');
      setStatePayment(prevState => ({
        ...prevState,
        loadingCancel: false,
      }));
    }
  };

  const openTopup = (isOpen: boolean) => {
    if (!appState?.userInfor?.isFullInfo) {
      setStatePayment(prevState => ({
        ...prevState,
        topupRequestInfor: isOpen,
      }));
    } else {
      setStatePayment(prevState => ({
        ...prevState,
        topupRequestPayment: isOpen,
      }));
    }
  };

  const handleOpenModalUpdateInfor = () => {
    setStatePayment(prevState => ({
      ...prevState,
      topupRequestInfor: false,
    }));
    updateInfo?.current?.onOpen();
  };

  const closeTopupRequestPayment = () => {
    setStatePayment(prevState => ({
      ...prevState,
      topupRequestPayment: false,
    }));
  };

  const openTopupRequestPayment = () => {
    setStatePayment(prevState => ({
      ...prevState,
      topupRequestPayment: true,
    }));
  };
  return {
    statePayment,
    requestWithdraw,
    getListPayment,
    onPageChange,
    onFilterStatus,
    onFilterDates,
    exportPayments,
    importPayments,
    cancelPayment,
    actionRef,
    openTopup,
    openTopupRequestPayment,
    updateInfo,
    closeTopupRequestPayment,
    handleOpenModalUpdateInfor,
  };
};
