import {formatted, validateNumberPrice} from '@/utils/helper';
import {Form, Input, Typography} from 'antd';

type Props = {
  form?: any;
  isEdit?: boolean;
  isDisable?: boolean;
};

export const LayoutGeneral = ({form, isEdit, isDisable}: Props) => {
  return (
    <div className="mt-5">
      <Typography className="mb-1 font-semibold text-sm">
        Thông tin chung
      </Typography>
      <Form.Item
        label="Tên gói"
        name="name"
        className="mb-2"
        rules={[{required: true, message: 'Vui lòng nhập tên gói !'}]}>
        <Input placeholder="Nhập tên gói..." disabled={isDisable} />
      </Form.Item>
      <Form.Item
        label="Mã gói"
        name="code"
        className="mb-2"
        rules={[{required: true, message: 'Vui lòng nhập mã gói !'}]}>
        <Input placeholder="Nhập mã gói..." disabled={isEdit} />
      </Form.Item>
      <Form.Item
        label="Doanh thu"
        name="price"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập doanh thu !',
            validator: validateNumberPrice,
          },
        ]}>
        <Input
          placeholder="Nhập doanh thu..."
          onChange={(e: {target: {value: string}}) => {
            form.setFieldsValue({
              price: formatted(e.target?.value),
            });
          }}
          disabled={isDisable}
        />
      </Form.Item>
      <Form.Item
        label="Hoa hồng"
        name="maxCommissionShare"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập hoa hồng !',
            validator: validateNumberPrice,
          },
        ]}>
        <Input
          placeholder="Nhập hoa hồng..."
          onChange={(e: {target: {value: string}}) => {
            form.setFieldsValue({
              maxCommissionShare: formatted(e.target?.value),
            });
          }}
          disabled={isDisable}
        />
      </Form.Item>
    </div>
  );
};
