import EyeIcon from '@/components/EyeIcon';
import {isUserHaveOneOfPermissions} from '@/utils';
import {PERMISSIONS} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {
  EditOutlined,
  OrderedListOutlined,
  // SettingOutlined,
} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Space, Tooltip} from 'antd';
import moment from 'moment';

const getColumnsConfig: (
  handleEditCampaign: (record: ListCampaignInterface) => void,
  actionRef: any,
  hiddenCampaign: (id: number, isHidden: 0 | 1) => void,
  handleDetailCampaign: (record: ListCampaignInterface) => void,
  handleConfig: (id: number) => void
) => ProColumns<ListCampaignInterface>[] = (
  handleEditCampaign,
  actionRef,
  hiddenCampaign,
  handleDetailCampaign,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleConfig
) => {
  const columns: ProColumns<ListCampaignInterface>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tên chiến dịch',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      align: 'center',
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'startTime',
      key: 'startTime',
      align: 'center',
      render: (_, record) =>
        moment(record.startTime).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center',
      render: (_, record) =>
        moment(record.endTime).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      render: (_, record) =>
        moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Thao tác',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (_: any, record: any) => (
        <Space size={10}>
          {isUserHaveOneOfPermissions(
            [ROLES.ADMIN, ROLES.AM_LEADER],
            PERMISSIONS.updateCampaign
          ) && (
            <>
              <Tooltip title={'Chỉnh sửa chiến dịch'}>
                <EditOutlined
                  className="text-[#1677ff] cursor-pointer text-[17px] mr-2"
                  onClick={handleEditCampaign?.bind(null, record)}
                />
              </Tooltip>
              <EyeIcon
                title="Ẩn/Hiện chiến dịch"
                status={record.status}
                onClick={hiddenCampaign.bind(
                  null,
                  record.id,
                  record.status ? 0 : 1
                )}
              />
            </>
          )}
          <Tooltip title={'Xem chi tiết chiến dịch'}>
            <OrderedListOutlined
              className="text-[#1677ff] cursor-pointer text-[17px] ml-2"
              onClick={handleDetailCampaign?.bind(null, record)}
            />
          </Tooltip>
          {/* {isUserHaveOneOfPermissions([ROLES.AGENT]) && (
            <Tooltip title={'Cấu hình chiến dịch'}>
              <SettingOutlined
                className="text-[#1677ff] cursor-pointer text-[17px] ml-2"
                onClick={handleConfig?.bind(null, record?.id)}
              />
            </Tooltip>
          )} */}
        </Space>
      ),
    },
  ];
  return columns;
};

export default getColumnsConfig;
