import {validateNumber} from '@/utils/helper';
import {Form, Input, Typography} from 'antd';

type Props = {
  isDisable?: boolean;
};

export const LayoutDetails = ({isDisable}: Props) => {
  return (
    <div className="mt-5">
      <Typography className="mb-1 font-semibold text-sm">
        Chi tiết sản phẩm
      </Typography>
      <Form.Item
        label="Chu kì"
        name="cycle"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập số ngày !',
            validator: validateNumber,
          },
        ]}>
        <Input placeholder="Nhập số ngày..." disabled={isDisable} />
      </Form.Item>
      {/* <Form.Item label="Thoại" name="calling" className="mb-2">
        <Input.TextArea
          placeholder="Nhập thoại..."
          autoSize
          disabled={isDisable}
        />
      </Form.Item>
      <Form.Item label="SMS" name="sms" className="mb-2">
        <Input.TextArea
          autoSize
          placeholder="Nhập thông tin ưu đãi..."
          disabled={isDisable}
        />
      </Form.Item>
      <Form.Item label="Data" name="dataTraffic" className="mb-2">
        <Input.TextArea
          autoSize
          placeholder="Nhập thông tin..."
          disabled={isDisable}
        />
      </Form.Item> */}
      <Form.Item label="Khác" name="description" className="mb-2">
        <Input.TextArea
          autoSize
          placeholder="Nhập thông tin..."
          disabled={isDisable}
        />
      </Form.Item>
    </div>
  );
};
