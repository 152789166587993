import {RESPONSE_CODE} from '@/network/constants';
import {notificationServices} from '@/network/services/notifications';
import {useEffect, useState} from 'react';

type UseMyNotification = {
  data: NotificationItem[];
  loading: boolean;
  isLoadMore: boolean;
  onLoadMore: () => void;
};

export const useMyNotifications = (): UseMyNotification => {
  const [data, setData] = useState<NotificationItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [filters, setFilters] = useState<SchedulesFilter>({
    page: 0,
    size: 20,
  });

  const onLoadMore = () => {
    setFilters((prev: SchedulesFilter) => ({
      page: prev.page + 1,
      size: 20,
    }));
    setIsLoadMore(true);
  };

  const fetchNotifications = async (params: SchedulesFilter) => {
    const response = await notificationServices.notifications(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setData(response?.data?.data);
    }
    setLoading(false);
    setIsLoadMore(false);
  };

  useEffect(() => {
    fetchNotifications(filters);
  }, [filters]);

  return {
    data,
    loading,
    isLoadMore,
    onLoadMore,
  };
};
