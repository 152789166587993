import UploadImage from '@/components/UploadImage';
import {RESPONSE_CODE} from '@/network/constants';
import {
  changeAgentCode,
  createAccount,
  updateAccount,
  updateAccountCTV,
} from '@/network/services/accounts';
import {productService} from '@/network/services/products';
import {isUserHaveOneOfPermissions} from '@/utils';
import {DEFAULT_PASSWORD, ruleValidatePassword} from '@/utils/constants';
import {CATEGORY_TYPE_IMAGE, ROLES} from '@/utils/enum';
import {getListBank, validateCollaboratorCommissionFee} from '@/utils/helper';
import {formatPrice, paymentStatus} from '@/utils/stringUtils';
import {CaretRightOutlined} from '@ant-design/icons';
import {
  DrawerForm,
  ProFormCascader,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import {Col, Form, Input, Row, Space, Typography, message} from 'antd';
import {BaseOptionType} from 'antd/es/cascader';
import {DefaultOptionType} from 'antd/es/select';
import {Ref, forwardRef, useImperativeHandle, useRef, useState} from 'react';

type Props = {
  onReload: () => void;
  managers: ManagerInterface[];
  lisOrganizations: OrganizitionsInterface[];
};

type FormCreateInterface = {
  userName: string;
  fullname: string;
  managerId: number;
  status: number;
  phoneNumber: string;
  agentCode?: string;
  identityCode: string;
  bankAccount: string;
  bankName: string;
  imageBankAccount: any;
  imageIdentityFront: any;
  imageIdentityBack: any;
  bankAccountName: string;
  externalCode: string;
  supplierCommissionFees:
    | {
        supplierId: number | undefined;
        supplierName: string | undefined;
        commissionFee: string | undefined;
      }[]
    | undefined;
  paymentStatus: number;
  address: string;
  email: string;
  regionCompanyId: number;
  regionCompanyBranchId: number;
  organization: number[] | number;
};

export type CreatePartnerModalRef = {
  onOpenModal: (isEdit: boolean, data?: AccountType) => void;
};

const optionActive = [
  {label: 'Đang hoạt động', value: 0},
  {label: 'Ngừng hoạt động', value: 1},
];

const CreatePartner = (props: Props, ref: Ref<CreatePartnerModalRef>) => {
  const {onReload, lisOrganizations} = props;
  const [form] = Form.useForm<FormCreateInterface>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [images, setImages] = useState<{
    imageIdentityFront?: string;
    imageIdentityBack?: string;
    imageBankAccount?: string;
  }>({
    imageIdentityFront: undefined,
    imageIdentityBack: undefined,
    imageBankAccount: undefined,
  });
  const [regionCompanyId, setRegionCompanyId] = useState<number>();
  const [regionCompanyBranchId, setRegionCompanyBranchId] = useState<number>();
  const [userInfo, setUserInfo] = useState<AccountType | undefined>(undefined);
  const agentCodeFieldRef = useRef<any>(null);

  const onOpenModal = (isEdit: boolean = false, data?: AccountType) => {
    setIsOpen(true);
    setIsEdit(isEdit);
    if (isEdit) {
      setImages({
        imageIdentityFront: data?.imageIdentityFront,
        imageIdentityBack: data?.imageIdentityBack,
        imageBankAccount: data?.imageBankAccount,
      });
      setUserInfo(data);
      form.setFieldsValue({
        ...data,
        supplierCommissionFees: data?.userSupplierCommissionFees?.map(item => ({
          supplierId: item.supplierId,
          supplierName: item.supplierName,
          commissionFee: formatPrice(Number(item.commissionFee)),
        })),
        organization: [
          Number(data?.regionCompanyId),
          Number(data?.regionCompanyBranchId),
        ],
        paymentStatus: data?.isEnablePayment ? 0 : 1,
      });
    } else {
      getListSupplier();
    }
  };

  const getListSupplier = async () => {
    const res = await productService.listSupplier();
    const {data} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      form.setFieldsValue({
        supplierCommissionFees: data.map((item: any) => ({
          supplierId: item.id,
          supplierName: item.name,
          commissionFee: formatPrice(Number(item.agentCommissionFee)),
        })),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    onOpenModal,
  }));

  const onCancel = (reload: boolean = false) => {
    setIsOpen(false);
    if (reload) {
      onReload?.();
    }
  };

  const onOpenChange = (visible: boolean) => {
    if (!visible) {
      onCancel();
    }
  };

  const onChangeAgentCode = async () => {
    const agentCode = form.getFieldValue('agentCode');
    if (agentCode?.length > 0 && userInfo?.userId) {
      const res = await changeAgentCode(userInfo?.userId, {agentCode});
      if (res?.status === RESPONSE_CODE.SUCCESS) {
        onReload?.();
      } else {
        message.error(res?.data ?? 'Chỉnh sửa mã đại ly thất bại');
      }
    }
  };

  const onFinish = async (values: FormCreateInterface) => {
    const data: any = {
      ...values,
      imageBankAccount: values.imageBankAccount?.[0]?.url || '',
      imageIdentityFront: values.imageIdentityFront?.[0]?.url || '',
      imageIdentityBack: values.imageIdentityBack?.[0]?.url || '',
      role: 'AGENT',
      type: ['data_pack', 'course'],
      externalCode: values?.externalCode,
      agentSupplierCommissionFees: values?.supplierCommissionFees,
      regionCompanyBranchId: regionCompanyBranchId,
      regionCompanyId: regionCompanyId,
    };

    const CTVdata = {
      fullname: values?.fullname,
      identityCode: values?.identityCode,
      bankAccount: values?.bankAccount,
      bankName: values?.bankName,
      imageBankAccount: values.imageBankAccount?.[0]?.url || '',
      imageIdentityFront: values.imageIdentityFront?.[0]?.url || '',
      imageIdentityBack: values.imageIdentityBack?.[0]?.url || '',
      bankAccountName: values?.bankAccountName,
      phoneNumber: values?.phoneNumber,
      bankBranch: '',
      status: values?.status,
      externalCode: values?.externalCode,
      collaboratorSupplierCommissionFees: values?.supplierCommissionFees,
      paymentStatus: values?.paymentStatus,
    };

    let response;
    if (isEdit && userInfo?.userId) {
      if (!isRoleAgent) {
        response = await updateAccountCTV(userInfo?.userId, CTVdata);
      } else {
        response = await updateAccount(userInfo?.userId, data);
      }
    } else {
      response = await createAccount(data);
    }
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      onCancel(true);
      message.success(
        isEdit ? 'Cập nhật thông tin thành công ' : 'Thêm tài khoản thành công'
      );
    } else {
      message.error(response?.data);
    }
  };

  const isRoleAgent = !isEdit || userInfo?.role === ROLES.AGENT;
  const isRoleCTV = !isEdit || userInfo?.role === ROLES.COLLABORATOR;
  const isRoleAdmin = userInfo?.role === ROLES.ADMIN;
  const roleName = isRoleAgent ? 'Đại lý' : 'CTV';
  const editName = isEdit ? 'Sửa' : 'Tạo';
  // const isDisabled = () => {
  //   if (!isNullOrEmpty(form.getFieldValue(['externalCode']))) {
  //     return true;
  //   }
  // };
  const newLisOrganizations = lisOrganizations?.filter(
    item => item?.children?.length > 0
  );

  return (
    <DrawerForm
      title={editName + ' tài khoản ' + roleName}
      size="middle"
      form={form}
      open={isOpen}
      onOpenChange={onOpenChange}
      drawerProps={{
        destroyOnClose: true,
        onClose: () => {
          setIsOpen(false);
          setImages({});
        },
      }}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      rowProps={{gutter: 10}}
      submitTimeout={2000}
      submitter={{
        searchConfig: {
          submitText: isEdit ? 'Sửa' : 'Thêm',
        },
      }}
      onFinish={onFinish}>
      <div>
        <p className="text-[16px] font-bold mb-2">Thông tin tài khoản</p>
        <Row gutter={24}>
          <Col span={isRoleAgent && !isEdit ? 12 : 24}>
            <ProFormText
              placeholder={'Nhập tên đăng nhập'}
              name="userName"
              disabled={isEdit}
              rules={[{required: !isUserHaveOneOfPermissions([ROLES.AGENT])}]}
              required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
              label="Tên đăng nhập"
            />
          </Col>
          <Col span={12}>
            {!isEdit && (
              <ProFormText.Password
                placeholder={'Nhập mật khẩu'}
                rules={[
                  {
                    required: true,
                  },
                  ruleValidatePassword,
                ]}
                initialValue={DEFAULT_PASSWORD}
                label="Mật khẩu"
                name={'password'}
                required
                disabled={isEdit}
              />
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={isRoleAgent || isRoleCTV ? 12 : 24}>
            <ProFormText
              name="agentCode"
              placeholder={'Nhập mã đại lý'}
              disabled={isRoleAdmin}
              rules={[{required: !isUserHaveOneOfPermissions([ROLES.AGENT])}]}
              required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
              label="Mã đại lý"
              fieldProps={{
                ref: agentCodeFieldRef,
                onKeyPress: event => {
                  if (event.key === 'Enter') {
                    agentCodeFieldRef?.current?.blur();
                    // onChangeAgentCode();
                  }
                },
                onBlur: () => {
                  onChangeAgentCode();
                },
              }}
            />
          </Col>
          <Col span={12}>
            {/* {isRoleAgent && (
              <ProFormText
                name="smsCode"
                placeholder={'Mã ghi nhận SMS'}
                rules={[{required: !isUserHaveOneOfPermissions([ROLES.AGENT])}]}
                required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
                label="Mã ghi nhận SMS"
              />
            )} */}
            {(isRoleAgent || isRoleCTV) && (
              <ProFormSelect
                options={paymentStatus}
                name="paymentStatus"
                initialValue={1}
                allowClear={false}
                required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
                label="Quyền tạo YCTT"
              />
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={isRoleAgent ? 12 : 24}>
            <ProFormSelect
              options={optionActive}
              name="status"
              initialValue={0}
              allowClear={false}
              required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
              label="Trạng thái"
            />
          </Col>
          <Col span={12}>
            {isRoleAgent && (
              <Row gutter={24}>
                <Col span={24}>
                  <ProFormCascader
                    label="Chọn tổ chức"
                    name={'organization'}
                    disabled={isEdit}
                    // required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
                    fieldProps={{
                      options: newLisOrganizations?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name,
                        children:
                          item?.children?.length > 0
                            ? item?.children?.map((item: any) => ({
                                value: item?.id,
                                label: item?.name,
                              }))
                            : undefined,
                      })) as (DefaultOptionType | BaseOptionType)[] | undefined,
                      displayRender: (labels, selectedOptions: any) => {
                        return labels.map((label, i) => {
                          const option = selectedOptions[i];
                          if (i === labels.length - 1) {
                            return <span key={option?.value}>{label}</span>;
                          }
                          return (
                            <span key={option?.value}>
                              {label} <CaretRightOutlined />{' '}
                            </span>
                          );
                        });
                      },
                      onChange: (value: any) => {
                        setRegionCompanyId(value?.[0]);
                        setRegionCompanyBranchId(value?.[1]);
                      },
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
          {/* <Col span={12}>
            {isRoleAgent && (
              <ProFormSelect
                options={paymentStatus}
                name="paymentStatus"
                initialValue={1}
                allowClear={false}
                required={!isUserHaveOneOfPermissions([ROLES.AGENT])}
                label="Quyền tạo YCTT"
              />
            )}
          </Col> */}
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <div className="flex flex-col w-[calc(100%)]">
              <Form.List name={'supplierCommissionFees'}>
                {fields => (
                  <>
                    {fields.length > 0 && (
                      <Typography className="mb-2">
                        Tỉ lệ hoa hồng với mỗi nhà cung cấp:
                      </Typography>
                    )}
                    {fields.map(({key, name, ...restField}) => (
                      <div
                        key={'item' + key}
                        className="flex justify-between ml-2 mr-2">
                        <Form.Item>
                          <Typography className="text-red-600">*</Typography>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'supplierName']}
                          className="w-[calc(30%)]">
                          <Input placeholder="Hoa hồng..." disabled />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'commissionFee']}
                          rules={[
                            {
                              validator: validateCollaboratorCommissionFee,
                              required: true,
                            },
                          ]}
                          className="w-[calc(50%)] ml-2">
                          <Input placeholder="Hoa hồng..." />
                        </Form.Item>
                        <Form.Item className="flex">
                          <Typography className="ml-5">%</Typography>
                        </Form.Item>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <p className="text-[16px] font-bold mb-2">Thông tin cá nhân</p>
        <ProFormText
          placeholder={'Nhập họ và tên'}
          name="fullname"
          label="Họ và tên"
        />
        {!isRoleAgent && (
          <ProFormText.Password
            placeholder={'Nhập mật khẩu'}
            rules={[
              {
                required: true,
              },
              ruleValidatePassword,
            ]}
            initialValue={DEFAULT_PASSWORD}
            label="Mật khẩu"
            name={'password'}
            required
            disabled={isEdit}
          />
        )}

        <Row gutter={24}>
          <Col span={isRoleAgent ? 12 : 24}>
            <ProFormText
              name="phoneNumber"
              placeholder={'Nhập số điện thoại'}
              fieldProps={{maxLength: 10}}
              // required={isRoleAgent}
              label="Số điện thoại"
            />
          </Col>
          {isRoleAgent && (
            <Col span={12}>
              <ProFormText
                name="email"
                placeholder={'Vui lòng nhập email'}
                label="Email"
                rules={[
                  {required: true},
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Email không hợp lệ',
                  },
                ]}
              />
            </Col>
          )}
        </Row>
        {isRoleAgent && (
          <ProFormTextArea
            name="address"
            label="Địa chỉ chi tiết"
            placeholder={'Vui lòng nhập đia chỉ chi tiết'}
          />
        )}
        <ProFormText
          name="identityCode"
          label="Số CCCD"
          fieldProps={{
            maxLength: 12,
          }}
          placeholder={'Nhập số căn cước công dân'}
        />
        <Space>
          <UploadImage
            label="Ảnh mặt trước"
            url={images.imageIdentityFront}
            name="imageIdentityFront"
            category={CATEGORY_TYPE_IMAGE.IDENTITY}
          />
          <UploadImage
            label="Ảnh mặt sau"
            name="imageIdentityBack"
            url={images.imageIdentityBack}
            category={CATEGORY_TYPE_IMAGE.IDENTITY}
          />
        </Space>
      </div>
      <div>
        <p className="text-[16px] font-bold mb-2">Thông tin thanh toán</p>
        <ProFormSelect
          request={getListBank}
          name="bankName"
          showSearch
          label="Tên ngân hàng"
          required={isRoleAgent}
        />
        <ProFormText
          name="bankAccount"
          label="Số TK ngân hàng"
          placeholder={'Nhập số tài khoản ngân hàng'}
          required={isRoleAgent}
        />
        <ProFormText
          label="Tên chủ tài khoản:"
          name="bankAccountName"
          placeholder={'Nhập tên chủ tài khoản'}
          required={isRoleAgent}
        />
        {/* <ProFormText
          label="Mã MO:"
          name="externalCode"
          placeholder={'Nhập mã MO'}
          disabled={isDisabled()}
        /> */}
      </div>
    </DrawerForm>
  );
};

export const CreatePartnerModal = forwardRef(CreatePartner);
