import {validateCollaboratorCommissionFee} from '@/utils/helper';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {DrawerForm} from '@ant-design/pro-components';
import {Button, Form, Input, Select, Typography, message} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle, useRef} from 'react';

export type ConfigModalRef = {
  open: (data: ConfigShareValue) => void;
  close: () => void;
};

type Props = {
  collaborators: AccountType[];
  createConfig: (params: ConfigShareValue) => void;
};

const ModalConfig = (
  {collaborators, createConfig}: Props,
  ref: Ref<ConfigModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const curData = useRef<ConfigShareValue | undefined>(undefined);

  const onclose = () => {
    setOpen(false);
  };

  const handleFillData = (data: ConfigShareValue | undefined) => {
    curData.current = data;
    form.setFieldsValue({
      commissionFeeDefault: data?.commissionFeeDefault,
      configs: data?.configs?.map(item => ({
        userId: item.userId,
        commissionFee: item.commissionFee,
      })),
    });
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          handleFillData(data);
          setOpen(true);
        },
        close: () => {
          setOpen(false);
        },
      };
    },
    []
  );

  const onChangeSelectCollaborators = (_: any, option: any) => {
    console.log('onChangeSelectCollaborators', option);
  };

  const onSubmit = async (values: ConfigShareValue) => {
    const courseIdSet = new Set();
    let isDuplicate = false;
    values?.configs?.forEach((item: ConfigFeeCTV) => {
      if (courseIdSet.has(item.userId)) {
        isDuplicate = true;
        return;
      } else {
        courseIdSet.add(item.userId);
      }
    });
    const body = {
      ...values,
      campaignId: curData.current?.id,
      name: curData.current?.name,
    };
    if (isDuplicate) {
      message.error('Cộng tác viên bị trùng !');
    } else {
      createConfig(body);
    }
  };

  const collaboratorsOptions = collaborators?.map(item => ({
    value: item.userId,
    label: item.userName,
  }));

  return (
    <DrawerForm
      title={'Cấu hình hoa hồng cho cộng tác viên'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 450,
      }}
      open={open}
      form={form}
      onFinish={onSubmit}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
        closable: false,
      }}
      submitTimeout={2000}
      initialValues={{configs: [{}]}}>
      <div className="flex mb-5">
        <Form.Item>
          <Typography className="">Hoa hồng CTV hiện tại (%):</Typography>
        </Form.Item>
        <Form.Item
          name={'commissionFeeDefault'}
          rules={[
            {validator: validateCollaboratorCommissionFee, required: true},
          ]}
          className="w-[calc(30%)] ml-2">
          <Input placeholder="Hoa hồng" />
        </Form.Item>
        <Form.Item>
          <Typography className="ml-2">%</Typography>
        </Form.Item>
      </div>
      <Typography className="mb-2">
        Cấu hình hoa hồng cho cộng tác viên:
      </Typography>
      <Form.List name="configs">
        {(fields, {add, remove}) => (
          <>
            {fields.map(({key, name, ...restField}) => (
              <div key={'item' + key} className="flex">
                <Form.Item
                  {...restField}
                  name={[name, 'userId']}
                  className="w-[calc(70%)]">
                  <Select
                    allowClear
                    placeholder="Chọn CTV"
                    options={collaboratorsOptions}
                    onChange={onChangeSelectCollaborators}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'commissionFee']}
                  rules={[
                    {
                      validator: validateCollaboratorCommissionFee,
                      required: true,
                    },
                  ]}
                  className="w-[calc(30%)] ml-2">
                  <Input placeholder="Hoa hồng" />
                </Form.Item>
                <Form.Item>
                  <Typography className="ml-2">%</Typography>
                </Form.Item>
                <Form.Item>
                  <DeleteOutlined
                    className="ml-2"
                    type="delete"
                    onClick={() => remove(name)}
                  />
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="default"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Thêm cấu hình CTV
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </DrawerForm>
  );
};

export const ConfigModal = forwardRef(ModalConfig);
