import { isUserHaveOneOfPermissions } from '@/utils';
import { PERMISSIONS } from '@/utils/constants';
import { ROLES } from '@/utils/enum';
import {EyeOutlined} from '@ant-design/icons';
import {Tag, Tooltip} from 'antd';
import moment from 'moment';

const getColumnsConfig = (
  actionRef: any,
  onEditPermission: (id: number) => void
) => {
  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      width: '5%',
      render: (_: any, __: any, indx: number) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tên nhóm quyền',
      dataIndex: 'name',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      align: 'center',
      width: '20%',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      align: 'center',
      width: '15%',
      render: (_: any, record: { createdDate: moment.MomentInput; }) =>
        record.createdDate
          ? moment(record.createdDate).format('DD/MM/YY HH:mm:ss')
          : '-',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: '15%',
      render: (_: any, {status}: any) => {
        return (
          <Tag color={status ? '#7A7B7C' : '#2C67DA'}>
            {status ? 'Ngừng hoạt động' : 'Đang hoạt động'}
          </Tag>
        );
      },
    },
    {
      title: 'Thao tác',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_: any, {id}: any) => {
        return (
          <Tooltip title={'Xem toàn bộ thông tin'}>
            <EyeOutlined
              className="text-[#1677ff] cursor-pointer text-[17px]"
              onClick={onEditPermission.bind(null, id)}
            />
          </Tooltip>
        );
      },
      hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN], PERMISSIONS.updateRole)
    },
  ].filter((item) => !item.hidden);

  return columns;
};

export default getColumnsConfig;
