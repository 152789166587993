import {isNullOrEmpty} from '@/utils/stringUtils';
import {Col, Drawer, Image, Row, Typography} from 'antd';
import {forwardRef, useImperativeHandle, useState} from 'react';

export interface ModalDetailRef {
  setOpenModal: (isOpen: boolean, data?: AccountType) => void;
}

interface ModalDetailProps {
  managers: ManagerInterface[];
  isHidden?: boolean;
  isHiddenPosition?: boolean;
}

interface UserInfor {
  id?: number;
  label?: string;
  content?: string;
  hidden?: boolean;
  isImage?: boolean;
}

// eslint-disable-next-line react/display-name
export const ModalDetailInfo = forwardRef<ModalDetailRef, ModalDetailProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      setOpenModal,
    }));
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState<AccountType | undefined>(undefined);

    const setOpenModal = (isOpen: boolean, data?: any) => {
      setOpen(isOpen);
      if (data) {
        setDetail(data);
      }
    };

    // const manager = props.managers?.find(
    //   (element: ManagerInterface) => element.id === detail?.managerId
    // );
    const userName = detail?.fullname ?? detail?.phoneNumber;

    const useInfor: UserInfor[] = [
      // {
      //   id: 1,
      //   label: 'Số điện thoại:',
      //   content: detail?.phoneNumber,
      //   hidden: true,
      //   isImage: false,
      // },
      {
        id: 1,
        label: 'Tên đăng nhập:',
        content: detail?.userName,
        hidden: true,
        isImage: false,
      },
      {
        id: 2,
        label: 'Họ và tên:',
        content: detail?.fullname,
        hidden: true,
        isImage: false,
      },
      {
        id: 3,
        label: 'CT khu vực:',
        content: detail?.regionCompanyName,
        hidden: props.isHiddenPosition,
        isImage: false,
      },
      {
        id: 4,
        label: 'Chi nhánh:',
        content: detail?.regionCompanyBranchName,
        hidden: props.isHiddenPosition,
        isImage: false,
      },
      // {
      //   id: 5,
      //   label: 'Người quản lý:',
      //   content: manager?.name,
      //   hidden: props.isHiddenPosition,
      //   isImage: false,
      // },
      // {
      //   id: 5,
      //   label: 'Mã đại lý:',
      //   content: detail?.agentCode,
      //   hidden: props.isHidden,
      //   isImage: false,
      // },
      {
        id: 5,
        label: 'Số CCCD:',
        content: detail?.identityCode,
        hidden: props.isHidden,
        isImage: false,
      },
      {
        id: 6,
        label: 'Ảnh mặt trước:',
        content: detail?.imageIdentityFront,
        hidden: props.isHidden,
        isImage: props.isHidden,
      },
      {
        id: 7,
        label: 'Ảnh mặt sau:',
        content: detail?.imageIdentityBack,
        hidden: props.isHidden,
        isImage: props.isHidden,
      },
      {
        id: 8,
        label: 'Số tài khoản ngân hàng:',
        content: detail?.bankAccount,
        hidden: props.isHidden,
        isImage: false,
      },
      {
        id: 9,
        label: 'Tên ngân hàng:',
        content: detail?.bankName,
        hidden: props.isHidden,
        isImage: false,
      },
    ];
    return (
      <Drawer
        open={open}
        width={500}
        title={'Thông tin người dùng ' + userName}
        onClose={setOpenModal.bind(null, false)}
        closable={false}>
        {useInfor?.map((item, index) => (
          <>
            {item?.isImage ? (
              <div key={index}>
                <Typography className="font-bold mb-4">
                  {item?.label}
                </Typography>
                {!isNullOrEmpty(item?.content) ? (
                  <Image
                    width={300}
                    height={'auto'}
                    className="mb-4"
                    src={item?.content}
                  />
                ) : (
                  <div className="mb-4">Không có ảnh CCCD</div>
                )}
              </div>
            ) : (
              <>
                {item?.hidden && (
                  <Row key={index} gutter={24} className="mb-4">
                    <Col span={8}>
                      <Typography className="font-[600]">
                        {item?.label}
                      </Typography>
                    </Col>
                    <Col span={16}>
                      <Typography>
                        {item.content || 'Chưa có thông tin'}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        ))}
      </Drawer>
    );
  }
);
