import {uploadCmsImg, uploadImg} from '@/network/services/upload';
import {checkImageBeforeUpload} from '@/utils/helper';
import {PlusOutlined} from '@ant-design/icons';
import {ProFormUploadButton} from '@ant-design/pro-components';
import {Modal, UploadFile} from 'antd';
import {RcFile, UploadProps} from 'antd/lib/upload';
import {useEffect, useState} from 'react';

type Props = {
  name: string;
  label: string;
  category?: string;
  required?: boolean;
  url?: string;
  readOnly?: boolean;
  isCms?: boolean;
  className?: string;
  saveUrl?: (url?: string) => void;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const UploadImage: React.FC<Props> = ({
  name,
  label,
  category,
  required,
  url,
  readOnly,
  isCms,
  className,
  saveUrl,
}: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (url) {
      setFileList([
        {
          uid: '1',
          name: 'image.png',
          status: 'done',
          url,
        },
      ]);
    }
  }, [url]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(url ?? (file.preview as string) ?? fileList?.[0]?.url);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleChange: UploadProps['onChange'] = ({file}) => {
    if (file.status === 'removed') {
      setFileList([]);
    } else {
      setFileList([{...file}]);
      saveUrl?.(file?.url ?? '');
    }
  };

  const handlePostImage = async (options: any) => {
    const {onSuccess, onError, file} = options;
    try {
      const res = isCms
        ? await uploadCmsImg({file})
        : await uploadImg({file, category});
      if (res && (res.linkUrl || res.url)) {
        file.url = res.linkUrl ?? res.url;
      } else {
        file.status = 'error';
      }
      onSuccess('Ok');
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({err: error});
    }
  };

  return (
    <div className={className}>
      <ProFormUploadButton
        name={name}
        label={label}
        max={1}
        rules={[
          {
            required: required,
            message: 'Vui lòng tải hình ảnh lên',
          },
        ]}
        required={required}
        accept=".jpg, .jpeg, .png, .webp"
        fieldProps={{
          listType: 'picture-card',
          fileList: fileList,
          onPreview: handlePreview,
          onChange: handleChange,
          beforeUpload: file => checkImageBeforeUpload(file),
          customRequest(options) {
            handlePostImage(options);
          },
          onRemove: () => {
            !readOnly;
            saveUrl?.(undefined);
          },
        }}
        icon={<PlusOutlined />}
        title={'Tải lên'}></ProFormUploadButton>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadImage;
