import {ProTable} from '@ant-design/pro-components';
import {Filter} from './Filter';
import {useWindowSize} from '@/hooks/useWindowSize';
import getColumnsConfig from './Columns';
import {useRequireSupport} from '@/hooks/useRequireSupport';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {CreateModal} from './CreateModal';
import {DetailsModal} from './DetailModal';

type Props = {
  isAdmin?: boolean;
  isReceived?: boolean | undefined;
};

export const RequiredSupportTab = ({
  isAdmin = false,
  isReceived = false,
}: Props) => {
  const {
    state,
    createRef,
    detailsRef,
    actionRef,
    onChangeStatus,
    onChangeInput,
    onChangeDate,
    onCreateRequireSupport,
    onPageChange,
    openDetailModal,
    createRequest,
    onFileListChange,
    onCloseModal,
    commentTicket,
    closeTicket,
    receiveTicket,
    onRemoveFile,
  } = useRequireSupport(isAdmin, isReceived);
  const windowSize = useWindowSize();
  const columns = getColumnsConfig(
    openDetailModal,
    actionRef,
    isAdmin,
    isReceived
  );
  return (
    <div className="flex flex-col">
      <ProTable<any>
        scroll={{x: 1500, y: windowSize?.height - 350}}
        className={'custom-table'}
        rowKey="id"
        dataSource={state?.listTicket}
        columns={columns}
        actionRef={actionRef}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        editable={{
          type: 'multiple',
        }}
        cardBordered
        options={false}
        loading={state?.loading}
        searchFormRender={() => (
          <Filter
            isReceived={isReceived}
            onChangeStatus={onChangeStatus}
            onChangeInput={onChangeInput}
            onChangeDate={onChangeDate}
            onCreateRequireSupport={onCreateRequireSupport}
          />
        )}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: state?.metadata?.page + 1,
          defaultPageSize: state?.metadata?.size,
          total: state?.metadata?.total,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
      />
      <CreateModal
        ref={createRef}
        createRequest={createRequest}
        onFileListChange={onFileListChange}
        onCloseModal={onCloseModal}
        onRemoveFile={onRemoveFile}
      />
      <DetailsModal
        isAdmin={isAdmin}
        isReceived={isReceived}
        ref={detailsRef}
        commentTicket={commentTicket}
        closeTicket={closeTicket}
        receiveTicket={receiveTicket}
      />
    </div>
  );
};
