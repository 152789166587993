import {RESPONSE_CODE} from '@/network/constants';
import {productService} from '@/network/services/products';
import {Form, message} from 'antd';
import {isUndefined} from 'lodash';
import React from 'react';

export const useRegisterPackage = () => {
  const [state, setState] = React.useState<{
    isOpen: boolean;
    listProduct: ProductItemInterface[];
    linkProduct?: {
      id?: number;
      affiliateLink?: string;
    };
    loading: boolean;
    isRefresh?: boolean;
    isShow: boolean;
  }>({
    isOpen: false,
    listProduct: [],
    loading: false,
    isRefresh: false,
    isShow: false,
  });
  const [form] = Form.useForm();

  const handleCreateRequest = async () => {
    const res = await productService?.createAffiliatelink(
      Number(state?.linkProduct?.id)
    );
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        linkProduct: {
          affiliateLink: res?.data?.referLink,
        },
        isRefresh: true,
      }));
    } else {
      message.error(res?.data);
    }
  };

  const getListProduct = async () => {
    const params = {
      page: 0,
      size: 1000,
      type: 'data_pack',
    };
    const res = await productService.getListProduct(params);
    const {data} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setState(prev => ({
        ...prev,
        listProduct: data,
        isRefresh: false,
      }));
    }
  };

  const onChangeSelectPackage = (value: number, options: any) => {
    if (!isUndefined(value)) {
      setState(prev => ({
        ...prev,
        linkProduct: {
          id: options?.value,
          affiliateLink: options?.affiliateLink,
        },
        isShow: true,
      }));
    } else {
      setState(prev => ({
        ...prev,
        isShow: false,
      }));
    }
  };

  const onOpenRegister = () => {
    setState(prev => ({
      ...prev,
      isOpen: !state?.isOpen,
      isShow: false,
    }));
    form.resetFields();
  };

  React.useEffect(() => {
    getListProduct();
  }, [state?.isRefresh]);

  return {
    state,
    handleCreateRequest,
    onChangeSelectPackage,
    onOpenRegister,
    form,
  };
};
