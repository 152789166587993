import UploadImage from '@/components/UploadImage';
import {RESPONSE_CODE} from '@/network/constants';
import {getInfoUser, updateCTV} from '@/network/services/accounts';
import {isRoleAgent} from '@/utils';
import {CATEGORY_TYPE_IMAGE} from '@/utils/enum';
import {getListBank} from '@/utils/helper';
import {UserOutlined} from '@ant-design/icons';
import {
  ProCard,
  ProForm,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import {PageContainer} from '@ant-design/pro-layout';
import {Avatar, Grid, Space, Typography, message} from 'antd';
import {useEffect, useState} from 'react';

const {useBreakpoint} = Grid;

export const Profile = () => {
  const [img, setImg] = useState<{
    imageIdentityFront?: string;
    imageIdentityBack?: string;
    imageBankAccount?: string;
  }>({});
  const [name, setName] = useState<string>('User');
  const [agentCode, setAgentCode] = useState<string>('');
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const screens = useBreakpoint();

  useEffect(() => {
    if (isRoleAgent()) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, []);

  const handleUpdate = async (values: any) => {
    const data = {
      ...values,
      // imageBankAccount: values.imageBankAccount?.[0]?.url,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
      fullname: name,
    };
    const res = await updateCTV(data);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      message.success('Cập nhật thành công');
    } else {
      if (res.status === 500) {
        message.error('Cập nhật không thành công');
      } else {
        message.error(res.data);
      }
    }
  };

  return (
    <PageContainer title="Trang cá nhân">
      <ProCard split={screens.md ? 'vertical' : 'horizontal'} bordered>
        <ProCard colSpan={{lg: '30%'}}>
          <ProCard layout="center" split="horizontal" gutter={[30, 30]}>
            <Avatar
              size={{xs: 90, sm: 100, md: 120, lg: 130, xl: 140, xxl: 150}}
              icon={<UserOutlined />}
              src="https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"
            />
            <Typography.Title
              editable={{
                onChange(value) {
                  setName(value);
                },
              }}
              level={3}>
              {name}
            </Typography.Title>
            {isRoleAgent() && (
              <div>
                Link giới thiệu:
                <Typography.Paragraph
                  style={{color: '#2C67DA'}}
                  copyable>{`${window.location.origin}/register?agentCode=${agentCode}`}</Typography.Paragraph>
              </div>
            )}
          </ProCard>
        </ProCard>
        <ProCard title="Thông tin cá nhân" headerBordered>
          <ProForm
            onFinish={handleUpdate}
            submitter={{
              searchConfig: {
                submitText: 'Cập nhật',
              },
              resetButtonProps: false,
              render(_, dom) {
                if (readOnly) {
                  return null;
                }
                return dom;
              },
            }}
            readonly={readOnly}
            rowProps={{gutter: 10}}
            request={async () => {
              const info = await getInfoUser();
              if (info) {
                setImg({
                  imageBankAccount: info?.imageBankAccount,
                  imageIdentityBack: info?.imageIdentityBack,
                  imageIdentityFront: info?.imageIdentityFront,
                });
                setName(info?.fullname);
                setAgentCode(info?.agentCode);
              } else {
                message.error('Hiện tại đang không lấy được thông tin');
              }
              return info;
            }}
            grid>
            <ProFormText
              width={screens.md ? 'md' : 'sm'}
              name="identityCode"
              label="Căn cước công dân"
              fieldProps={{
                maxLength: 12,
              }}
              required
              rules={[
                {required: true},
                {
                  pattern: /^\d+$/,
                  message: 'CCCD không hợp lệ',
                },
              ]}
              placeholder=""
            />
            <Space direction={screens.sm ? 'horizontal' : 'vertical'}>
              <UploadImage
                label="Ảnh mặt trước"
                required
                readOnly={isRoleAgent()}
                name="imageIdentityFront"
                url={img.imageIdentityFront}
                category={CATEGORY_TYPE_IMAGE.IDENTITY}
              />
              <UploadImage
                label="Ảnh mặt sau"
                required
                readOnly={isRoleAgent()}
                name="imageIdentityBack"
                url={img.imageIdentityBack}
                category={CATEGORY_TYPE_IMAGE.IDENTITY}
              />
            </Space>
            <ProFormText
              width={screens.md ? 'md' : 'sm'}
              name="bankAccount"
              label="Số TK ngân hàng"
              rules={[
                {required: true},
                {
                  pattern: /^\d+$/,
                  message: 'Chỉ nhập số tài khoản',
                },
              ]}
              fieldProps={{
                maxLength: 20,
              }}
              placeholder={'Nhập số tài khoản ngân hàng'}
              required
            />
            <ProFormSelect
              width={screens.md ? 'md' : 'sm'}
              request={getListBank}
              name="bankName"
              required
              showSearch
              rules={[{required: true}]}
              label="Tên ngân hàng"
            />
            {/* <UploadImage
              label="Ảnh QR ngân hàng"
              name="imageBankAccount"
              readOnly
              url={img.imageBankAccount}
              category={CATEGORY_TYPE_IMAGE.BANK}
            /> */}
          </ProForm>
        </ProCard>
      </ProCard>
    </PageContainer>
  );
};
