import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Dropdown} from 'antd';
import type {MenuProps} from 'antd';
import {FC, useEffect, useState} from 'react';

const ItemDropdown = ({
  item,
  onSelectPermission,
}: {
  item: ItemPermission;
  onSelectPermission: (item: ItemPermission[]) => void;
}) => {
  const [data, setData] = useState(item);

  const onClickItem = () => {
    setData(prev => ({...prev, active: !prev.active}));
  };

  useEffect(() => {
    onSelectPermission([data]);
  }, [data]);

  return (
    <div className="flex">
      <div className="w-64">{data?.permission}</div>
      <Checkbox checked={data.active} onClick={onClickItem} />
    </div>
  );
};

type Props = {
  data: ItemPermission[];
  onSelectPermission: (item: ItemPermission[]) => void;
};

const DropdownPermission: FC<Props> = ({data = [], onSelectPermission}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const items: MenuProps['items'] = data?.map(item => {
    return {
      key: item.id,
      label: (
        <ItemDropdown item={item} onSelectPermission={onSelectPermission} />
      ),
    };
  });

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <div className="flex justify-center">
      <Dropdown
        menu={{items}}
        placement="bottomLeft"
        open={visible}
        trigger={['click']}
        onOpenChange={toggleMenu}>
        <Button icon={<PlusCircleOutlined />} />
      </Dropdown>
    </div>
  );
};

export default DropdownPermission;
