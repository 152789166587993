import {FC} from 'react';
import {useNotifications} from '@/hooks/useNotifications';
import {useWindowSize} from '@/hooks/useWindowSize';
import {ProTable} from '@ant-design/pro-components';
import getColumnsConfig from './TableColumn';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {NotificationFilters} from './NotificationFilters';
import {CreateModal} from './ModalCreate';

type Props = {};

const Notifications: FC<Props> = () => {
  const {
    data,
    loading,
    metadata,
    actionRef,
    modalCreateRef,
    filters,
    onPageChange,
    onOpenModal,
    onChangeDates,
    onSelectObject,
    onSelectStatus,
  } = useNotifications();
  const windowSize = useWindowSize();

  return (
    <div className="flex flex-col">
      <ProTable<any>
        className={'custom-table'}
        scroll={{x: 1500, y: windowSize?.height - 350}}
        rowKey="id"
        dataSource={data}
        columns={getColumnsConfig(filters.page, filters.size)}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        searchFormRender={() => (
          <NotificationFilters
            onOpenModal={onOpenModal}
            onChangeDates={onChangeDates}
            onSelectObject={onSelectObject}
            onSelectStatus={onSelectStatus}
          />
        )}
        loading={loading}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: 1,
          total: metadata?.total,
          defaultPageSize: metadata?.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <CreateModal ref={modalCreateRef} />
    </div>
  );
};

export default Notifications;
