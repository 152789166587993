export const DEFAULT_TIME_OUT = 60 * 1000;

export enum RESPONSE_CODE {
  SUCCESS = 200,
  CREATED_SUCCESS = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,

  CODE_DEFAULT = -200,
  TIME_OUT = 45000,
  SERVER_ERROR = 502,
  CODE_TIME_OUT = 408,
  FORCE_UPDATE_APP = 423,
  CODE_TIMEOUT = 'ECONNABORTED',
}

export enum ERROR_API_CODE {
  NEED_UPDATE_CODE = 401401,
}
