import UploadImage from '@/components/UploadImage';
import {RESPONSE_CODE} from '@/network/constants';
import {documentServices} from '@/network/services/documents';
import {CATEGORY_TYPE_IMAGE} from '@/utils/enum';
import {DrawerForm} from '@ant-design/pro-components';
import {Form, Input, message} from 'antd';
import {Ref, forwardRef, useImperativeHandle, useState} from 'react';

type Props = {
  onReload: () => void;
};

export type CreateDocumentModalRef = {
  open: (isEdit?: boolean, record?: DocumentItemInterface) => void;
};

const CreateModal = (props: Props, ref: Ref<CreateDocumentModalRef>) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<number | undefined>(undefined);

  const [image, setImage] = useState<string | undefined>(undefined);

  const openModal = (
    isEdit: boolean = false,
    record?: DocumentItemInterface
  ) => {
    setOpen(true);
    setIsEdit(isEdit);
    if (isEdit) {
      setDocumentId(record?.id);
      setImage(record?.image);
      const {name, code, content, description, referenceLink, video, image} =
        record ?? {};
      form.setFieldsValue({
        name,
        code,
        content,
        description,
        referenceLink,
        video,
        image,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  const onCloseModal = (reload: boolean = false) => {
    setOpen(false);
    if (reload) {
      props.onReload();
    }
  };

  const onFinish = async (values: DocumentBodyInterface) => {
    const data: DocumentBodyInterface = {
      ...values,
      image: values.image?.[0]?.url,
    };
    let response;
    if (isEdit && documentId) {
      response = await documentServices.update(documentId, data);
    } else {
      response = await documentServices.create(data);
    }
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      onCloseModal(true);
      message.success(
        isEdit ? 'Cập nhật thông tin thành công ' : 'Thêm tài liệu thành công'
      );
    } else {
      message.error(response?.data as unknown as any);
    }
  };

  return (
    <DrawerForm
      title={isEdit ? 'Sửa tài liệu' : 'Tạo tài liệu'}
      size="middle"
      form={form}
      open={open}
      onOpenChange={visible => {
        if (!visible) {
          onCloseModal(false);
        }
      }}
      drawerProps={{
        destroyOnClose: true,
        onClose: onCloseModal.bind(null, false),
      }}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      submitTimeout={2000}
      submitter={{
        searchConfig: {
          submitText: isEdit ? 'Sửa' : 'Thêm',
        },
      }}
      onFinish={onFinish}>
      <Form.Item
        label="Nhập tên tài liệu:"
        name="name"
        rules={[{required: true, message: 'Vui lòng nhập tên tài liệu !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập tên tài liệu..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Nhập mã sản phẩm:"
        name="code"
        rules={[{required: true, message: 'Vui lòng nhập mã sản phẩm !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập mã sản phẩm..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Nhập nội dung:"
        name="content"
        rules={[{required: true, message: 'Vui lòng nhập nội dung !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập nội dung..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Nhập mô tả:"
        name="description"
        rules={[{required: true, message: 'Vui lòng nhập mô tả !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập mô tả..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Nhập link tài liệu:"
        name="referenceLink"
        rules={[{required: true, message: 'Vui lòng nhập link tài liệu !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập link tài liệu..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Nhập link video:"
        name="video"
        rules={[{required: true, message: 'Vui lòng nhập link video !'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập link video..."
          className="w-[calc(100%)]"
          allowClear
        />
      </Form.Item>

      <UploadImage
        label="Ảnh sản phẩm"
        url={image}
        name="image"
        category={CATEGORY_TYPE_IMAGE.DOCUMENT}
      />
    </DrawerForm>
  );
};

export const CreateDocumentModal = forwardRef(CreateModal);
