import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL_PAYMENT} from '../apiUrl';
import {getRequestUrl} from '../utils';

const requestPayment = (params: {
  amount: number;
}): Promise<AxiosResponse<RequestPaymentsResponseInterface>> => {
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: API_URL_PAYMENT.REQUEST_WITHDRAW,
  });
  return NetWork.post(url, params);
};

const listingPaymentRequest = (
  params?: {
    status?: number | null;
    createdDate?: string | null;
    IsVerifiedAccount?: boolean | null;
    fromDate?: string | null;
    toDate?: string | null;
  },
  isUser?: boolean
): Promise<AxiosResponse<PaymentResponseInterface>> => {
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: isUser
      ? API_URL_PAYMENT.FILTER_WITHDRAW_BY_USER
      : API_URL_PAYMENT.FILTER_WITHDRAW,
  });
  return NetWork.get(url, params);
};

const listingBalanceHistory = (params?: {
  toDate?: string | null;
  fromDate?: string | null;
}): Promise<AxiosResponse<BalanceHistoryResponseInterface>> => {
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: API_URL_PAYMENT.BALANCE_HISTORY,
  });
  return NetWork.get(url, params);
};

const getDataExport = (params: {
  status: number | undefined;
  toDate: string | undefined;
  fromDate: string | undefined;
}): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: API_URL_PAYMENT.EXPORT_PAYMENT,
  });
  return NetWork.get(url, params, {
    responseType: 'blob',
  });
};

const importData = (file: any): Promise<AxiosResponse<any>> => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: API_URL_PAYMENT.IMPORT_PAYMENT,
  });
  return NetWork.post(url, bodyFormData, {
    headers: {
      'Content-Disposition': 'attachment',
      'Content-Type': 'application/vnd.ms-excel',
    },
  });
};

const cancelPayment = (id: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(API_URL_PAYMENT.WALLET, {
    parentId: API_URL_PAYMENT.CANCEL_PAYMENT,
    partial: id,
  });
  return NetWork.patch(url);
};

export const paymentServices = {
  requestPayment,
  listingPaymentRequest,
  listingBalanceHistory,
  getDataExport,
  importData,
  cancelPayment,
};
