import {DEFAULT_PAGE_SIZE} from '@/utils/constants';
import {formatPrice} from '@/utils/stringUtils';
import {Space, Tooltip} from 'antd';

export default (
  page: number,
  isCollaborator: boolean,
  isMobile: boolean,
  handleReportCampaignDetail: (record: ReportCampaignItem) => void
): any =>
  [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (_data: any, _report: any, index: number) =>
        page * DEFAULT_PAGE_SIZE + index + 1,
    },
    {
      title: isCollaborator ? 'Tên CTV' : 'Tên chiến dịch',
      dataIndex: isCollaborator ? 'collaboratorName' : 'campaignName',
      key: 'campaignName',
      align: 'center',
    },
    {
      title: isCollaborator ? 'Số điện thoại' : 'Sản phẩm',
      dataIndex: isCollaborator ? 'phoneNumber' : 'productName',
      key: 'productName',
      align: 'center',
    },
    {
      title: 'Tổng đơn hàng',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      align: 'center',
    },
    {
      title: 'Doanh thu',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
    },
    {
      title: 'Hoa hồng/sản phẩm',
      dataIndex: 'totalAgentRevenue',
      key: 'totalAgentRevenue',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
    },
    {
      title: 'Hoa hồng thực nhận',
      dataIndex: 'totalCollaboratorRevenue',
      key: 'totalCollaboratorRevenue',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
      hidden: !isCollaborator,
    },
    {
      title: 'Thao tác',
      dataIndex: 'option',
      align: 'center',
      render: (_: any, record: any) => (
        <Space>
          <Tooltip
            title={'Xem toàn bộ thông tin'}
            trigger={isMobile ? 'contextMenu' : 'hover'}>
            <p
              onClick={handleReportCampaignDetail?.bind(null, record)}
              className="text-[#1677ff] cursor-pointer text-[14px]">
              Xem chi tiết
            </p>
          </Tooltip>
        </Space>
      ),
      hidden: !isCollaborator,
    },
  ].filter(item => !item?.hidden);
