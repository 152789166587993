import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {
  Login,
  Page404,
  Dashboard,
  Register,
  Accounts,
  Products,
  Campaigns,
  ReportCampaign,
  Profile,
  Orders,
  ReportCampaignAgent,
  Collaborators,
  Documents,
} from '@/containers';
import {PERMISSIONS, ROUTERS} from './utils/constants';
import {Layout} from './components/ProLayout';
import Auth from './components/Auth';
import ChangePassword from './containers/ChangePassword';
import {Search} from './containers/Search';
import {Payments} from './containers/Payments';
import {BalanceHistory} from './containers/BalanceHistory';
import {ImportedMOFile} from './containers/ImportedMOFile';
import {Posts} from './containers/Posts';
import Notifications from './containers/Notifications';
import {Organizations} from './containers/Organizations';
import {RequireSupport} from './containers/RequireSupport';
import PermissionConfiguration from './containers/PermissionConfiguration';
import {isUserHaveOneOfPermissions} from './utils';
import {ROLES} from './utils/enum';

const MyRoutes = (isSignIn: boolean) => {
  return (
    <BrowserRouter>
      <Routes>
        {!isSignIn ? (
          <Route>
            <Route path="/" element={<Login />} />
          </Route>
        ) : (
          <Route path={'/'} element={<Layout />}>
            <Route path={ROUTERS.Dashboard.path} element={<Dashboard />} />

            <Route path={ROUTERS.Products.path} element={<Products />} />

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN, ROLES.AGENT, ROLES.COLLABORATOR],
                    PERMISSIONS.viewPayment
                  )}
                />
              }>
              <Route path={ROUTERS.Payments.path} element={<Payments />} />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions([
                    ROLES.AGENT,
                    ROLES.COLLABORATOR,
                  ])}
                />
              }>
              <Route
                path={ROUTERS.BalanceHistory.path}
                element={<BalanceHistory />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [
                      ROLES.ADMIN,
                      ROLES.AM_LEADER,
                      ROLES.COLLABORATOR,
                      ROLES.AM,
                      ROLES.AGENT,
                    ],
                    PERMISSIONS.viewCampaign
                  )}
                />
              }>
              <Route path={ROUTERS.Campaigns.path} element={<Campaigns />} />
            </Route>

            <Route path={ROUTERS.Profile.path} element={<Profile />} />

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM, ROLES.AGENT],
                    PERMISSIONS.viewReport
                  )}
                />
              }>
              <Route
                path={ROUTERS.ReportCampaign.path}
                element={<ReportCampaign />}
              />
            </Route>

            <Route
              path={ROUTERS.ReportCampaignAgent.path}
              element={<ReportCampaignAgent />}
            />

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [
                      ROLES.ADMIN,
                      ROLES.AM_LEADER,
                      ROLES.COLLABORATOR,
                      ROLES.AM,
                      ROLES.AGENT,
                      ROLES.ACT,
                    ],
                    PERMISSIONS.viewOrder
                  )}
                />
              }>
              <Route path={ROUTERS.Orders.path} element={<Orders />} />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [
                      ROLES.ADMIN,
                      ROLES.AM_LEADER,
                      ROLES.COLLABORATOR,
                      ROLES.AM,
                      ROLES.AGENT,
                    ],
                    PERMISSIONS.viewDocument
                  )}
                />
              }>
              <Route path={ROUTERS.Documents.path} element={<Documents />} />
            </Route>

            <Route path={ROUTERS.Search.path} element={<Search />} />

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN],
                    PERMISSIONS.viewNotification
                  )}
                />
              }>
              <Route
                path={ROUTERS.Notification.path}
                element={<Notifications />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions([
                    ROLES.ADMIN,
                    ROLES.AM_LEADER,
                  ])}
                />
              }>
              <Route
                path={ROUTERS.ImportedMOFile.path}
                element={<ImportedMOFile />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN],
                    PERMISSIONS.viewCMS
                  )}
                />
              }>
              <Route path={ROUTERS.Posts.path} element={<Posts />} />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN],
                    PERMISSIONS.viewRC
                  )}
                />
              }>
              <Route
                path={ROUTERS.Organizations.path}
                element={<Organizations />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM, ROLES.AGENT],
                    PERMISSIONS.viewAgent
                  )}
                />
              }>
              <Route
                path={ROUTERS.Collaborators.path}
                element={<Collaborators />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN],
                    PERMISSIONS.viewUser
                  )}
                />
              }>
              <Route path={ROUTERS.Accounts.path} element={<Accounts />} />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.ADMIN],
                    PERMISSIONS.viewPermissions
                  )}
                />
              }>
              <Route
                path={ROUTERS.PermissionConfiguration.path}
                element={<PermissionConfiguration />}
              />
            </Route>

            <Route
              element={
                <Auth
                  isHavePermission={isUserHaveOneOfPermissions(
                    [ROLES.AGENT, ROLES.COLLABORATOR, ROLES.ADMIN],
                    PERMISSIONS.viewTicket
                  )}
                />
              }>
              <Route
                path={ROUTERS.RequireSupport.path}
                element={<RequireSupport />}
              />
            </Route>
          </Route>
        )}
        <Route path={ROUTERS.Register.path} element={<Register />} />
        <Route
          path={ROUTERS.ChangePassword.path}
          element={<ChangePassword />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
