import {useWindowSize} from '@/hooks/useWindowSize';
import {RESPONSE_CODE} from '@/network/constants';
import accountsServices, {
  listAgency,
  listManager,
  resetPassword,
} from '@/network/services/accounts';
import {organizationsServices} from '@/network/services/organizitions';
import {
  DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
} from '@/utils/constants';
import {ProTable} from '@ant-design/pro-components';
import {isArray} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {ModalDetailInfo, ModalDetailRef} from '../Accounts/ModalDetailInfo';
import CollaboratorsFilter from './CollaboratorsFilter';
import {
  CreateCollaboratorModal,
  CreateCollaboratorModalRef,
} from './CreateCollaboratorModal';
import {CreatePartnerModal, CreatePartnerModalRef} from './CreatePartnerModal';
import columns from './TableCollaboratorsColumns';
import {Modal, message} from 'antd';

export const Collaborators: React.FC = () => {
  const [table, setTable] = useState<AccountType[]>([]);
  const [metaData, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [agents, setAgents] = useState<AgentItem[]>([]);
  const [search, setSearchValue] = useState<string>('');
  const [agentCode, setAgentCode] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [managers, setManagers] = useState<ManagerInterface[]>([]);
  const [lisOrganizations, setListOrganizations] = React.useState<
    OrganizitionsInterface[]
  >([]);
  const modalDetailRef = useRef<ModalDetailRef>(null);
  const modalCreateRef = useRef<CreatePartnerModalRef>(null);
  const modalCreateCollabRef = useRef<CreateCollaboratorModalRef>(null);
  // const [reload, setReload] = useState<boolean>(false);
  // const [modalConfirm, contextHolder] = Modal.useModal();
  const [modal, contextHolder] = Modal.useModal();
  const windowSize = useWindowSize();

  const onChangeText = (e: any) => {
    setSearchValue(e.target.value ?? '');
    setPage(0);
  };

  const onSelectAgent = (value: string) => {
    setAgentCode(value);
    setPage(0);
  };

  const onCreateAccount = () => {
    modalCreateRef.current?.onOpenModal(false);
  };

  const onCreateAccountCollaborator = () => {
    modalCreateCollabRef.current?.onOpenModal();
  };

  const onSizeChange = (page: number, size: number) => {
    setPage(Math.max(page - 1, 0));
    setSize(size);
  };

  const fetchCollaborators = async (params: CollaboratorParams) => {
    const response = await accountsServices.collaborators(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data = [], ...pagination} = response?.data;
      setTable(data);
      setMetadata(pagination);
    }
  };

  const fetchData = async () => {
    const response = await Promise.all([listManager(), listAgency()]);
    if (isArray(response?.[0])) {
      setManagers(response?.[0]);
    }
    if (isArray(response?.[1])) {
      setAgents(response?.[1]);
    }
  };

  const onReload = () => {
    fetchCollaborators({
      page: page,
      size: size,
      query: search,
      agentCode: agentCode,
    });
  };

  // const onChangePermissionPayment = (id: number, isHide: true | false) => {
  //   const text = isHide ? 'Ẩn' : 'Hiện';
  //   modalConfirm.confirm({
  //     title: text + ' quyền thanh toán',
  //     content: 'Bạn có muốn ' + text + ' quyền thanh toán?',
  //     okText: 'Xác nhận',
  //     width: 350,
  //     onOk: async () => {
  //       await accountsServices.paymentPermission(id, isHide);
  //       message.success('Đã ' + text + ' quyền thanh toán');
  //       setReload(!reload);
  //     },
  //   });
  // };

  const hanldleShowDetail = async (id: number) => {
    const res = await accountsServices.collaboratorsDetail(id);
    const data: any = res?.data;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      modalCreateRef.current?.onOpenModal(true, data as AccountType);
    }
  };

  const fetchOrganizations = async () => {
    const res = await organizationsServices.getStructure();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setListOrganizations(res?.data);
    }
  };

  const reset = (id: number) => {
    modal.confirm({
      title: 'Đặt lại mật khẩu về mặc định',
      content: 'Bạn có muốn đặt lại mật khẩu về mật khẩu ban đầu ?',
      okText: 'Xác nhận',
      width: 350,
      onOk: async () => {
        const res = await resetPassword(id);
        if (res?.status === RESPONSE_CODE.SUCCESS) {
          message.success('Đã đặt lại mật khẩu về mặc định');
        } else {
          message.error(res?.data ?? 'Có lỗi xảy ra');
        }
      },
    });
  };

  useEffect(() => {
    fetchCollaborators({
      page: page,
      size: size,
      query: search,
      agentCode: agentCode,
    });
  }, [page, search, agentCode]);

  useEffect(() => {
    fetchData();
    fetchOrganizations();
  }, []);

  const isMobile = windowSize?.width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;

  return (
    <div className="flex flex-col">
      <ProTable<any>
        className="custom-table"
        scroll={{x: 1200, y: windowSize?.height - 300}}
        rowKey="userId"
        dataSource={table}
        columns={columns(
          page,
          modalDetailRef,
          isMobile,
          // onChangePermissionPayment,
          reset,
          hanldleShowDetail
        )}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <CollaboratorsFilter
            search={search}
            agents={agents}
            onChangeText={onChangeText}
            onSelectAgent={onSelectAgent}
            onCreateAccount={onCreateAccount}
            onCreateAccountCollaborator={onCreateAccountCollaborator}
          />
        )}
        options={false}
        pagination={{
          current: page + 1,
          total: metaData?.total,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          pageSizeOptions: PAGE_SIZE_LIST,
          showSizeChanger: true,
          size: 'small',
          onChange: onSizeChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <CreatePartnerModal
        ref={modalCreateRef}
        onReload={onReload}
        managers={managers}
        lisOrganizations={lisOrganizations}
      />
      <CreateCollaboratorModal ref={modalCreateCollabRef} onReload={onReload} />
      <ModalDetailInfo
        ref={modalDetailRef}
        managers={managers}
        isHidden={true}
        isHiddenPosition={false}
      />
      {contextHolder}
    </div>
  );
};
