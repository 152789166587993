import {Navigate, Outlet, useLocation} from 'react-router-dom';

type Props = {
  isHavePermission: boolean;
};

const Auth = ({isHavePermission}: Props) => {
  const location = useLocation();

  if (isHavePermission) {
    return <Outlet />;
  }
  return <Navigate to="/unauthorized" state={{from: location}} replace />;
};

export default Auth;
